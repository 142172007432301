import { IMAGE_SIZE_PREFIX, AVATAR_SIZE_PREFIX } from "const";
import { Stomp } from "stompjs/lib/stomp";
import createClient from "./httpClient";

export const HOST_NAME =
  process.env.NEXT_PUBLIC_API_HOST_NAME || (typeof window !== "undefined" && window.location.host);
export const BASE_URL = `https://${HOST_NAME}/api/v1`;
const BASE_URL_V2 = `https://${HOST_NAME}/api/v2`;
export const client = createClient({ baseUrl: BASE_URL });

export function getStompConnection() {
  const ws = new WebSocket(`wss://${HOST_NAME}/api/v1/socket`);
  const stompClient = Stomp.over(ws);
  if (process.env.NODE_ENV === "production") {
    stompClient.debug = null;
  }

  return new Promise((resolve, reject) => {
    stompClient.connect({}, () => resolve(stompClient), reject);
  });
}

export function getNotifications(params) {
  return client.get("/notification", params);
}

export function markNotificationsAsRead(ids) {
  return client.put("/notification/read", ids);
}

export function markAllNotificationsAsRead() {
  return client.put("/notification/read_all");
}

export function unsubscribeEmail(key) {
  return client.put("/notification/unsubscribe", { key });
}

export function getArtworkFilePath(filename, size = IMAGE_SIZE_PREFIX.SMALL) {
  return `${BASE_URL}/files/artworks/${size}${filename}`;
}

export function getArtworkPath(filename) {
  return `${BASE_URL_V2}/files/artworks/${filename}?cdn=true`;
}

export function getAvatarFilePath(filename, size = AVATAR_SIZE_PREFIX.SMALL) {
  return `${BASE_URL}/files/avatars/${size}${filename}`;
}

export function getProfileCoverFilePath(filename) {
  return `${BASE_URL}/files/avatars/${filename}`;
}

export function getArtistById(artistId) {
  return client.post("/artist/get?add=stats,likes", { artistId });
}

export function getArtistProfiles() {
  return client.get("/profile/artists?PSize=12");
}

export function getArtistProfile() {
  return client.get("/profile");
}

export function getArtistByUsername() {
  // username is checked in custom header X-Auth-Username, which is sent for any request if user is logged in
  return client.post("/artist/get_by_username");
}

export function getArtistStats(artistId) {
  return client.post("/artist/stats", { artistId });
}

export function getArtistArtworks(
  artistId,
  { page = 0, perPage = 10, add = "stats,likes", sortField = "statistic.likes,id", sortDirection = ["DESC", "DESC"] }
) {
  const params = {
    PNumber: page,
    PSize: perPage,
    add,
    sortBy: sortField,
    orderBy: sortDirection,
  };

  return client.get(`/kultura/artwork/artists/${artistId}`, params);
}

export function getCollectionArtworks(
  collectionId,
  { page = 0, perPage = 0, sortBy = "amount_of_purchases,id", orderBy = ["DESC", "DESC"] }
) {
  return client.get(`/kultura/artwork/collection/${collectionId}`, {
    PNumber: page,
    PSize: perPage,
    add: ["artist", "stats", "likes"],
    sortBy,
    orderBy,
  });
}

export function getBoardArtworks(boardId, params) {
  return client.get(`/kultura/board/${boardId}/artwork`, { ...params, add: "artist,stats,likes" });
}

export async function getLikedArtworks({ page = 0, perPage = 10 }) {
  const { items, total } = await client.get(
    `/kultura/artwork/likes?PSize=${perPage}&PNumber=${page}&add=artist,stats,likes`
  );

  return { artworks: items, total };
}

export function getFeaturedArtworks() {
  const params = {
    add: ["artist", "likes"],
    PSize: 3,
    sortBy: "id",
    orderBy: "DESC",
  };

  return client.get("/kultura/artwork/featured", params);
}

// TODO(ars): refactor to a general `getArtworks` function
export function getRecentArtworks() {
  return client.get("/kultura/artwork/?add=artist,stats,likes&PSize=20&orderBy=DESC&sortBy=uploadedAt");
}

export function getArtworks({
  page = 0,
  perPage = 20,
  add = "artist,stats,likes",
  sortField = "uploadedAt",
  sortDirection = "DESC",
  searchQuery = null,
  id = null,
}) {
  const params = {
    PNumber: page,
    PSize: perPage,
    sortBy: sortField,
    orderBy: sortDirection,
    searchQuery,
    add,
    ...(id ? { id } : null),
  };

  return client.get(`/kultura/artwork`, params);
}

export async function searchLikedArtworks(query, { page = 0, perPage = 10 }) {
  const { items, total } = await client.get(
    `/kultura/artwork/likes?PSize=${perPage}&PNumber=${page}&q=${query}&add=artist,stats,likes`
  );

  return { artworks: items, total };
}

export function getArtworkById(id) {
  return client.get(`/kultura/artwork/${id}`, { add: ["artist", "stats", "likes"] });
}

export async function getArtworksInfo(ids) {
  const idsStr = (Array.isArray(ids) ? ids : [ids]).join(",");
  const { items } = await client.get(`/kultura/artwork?id=${idsStr}&add=artist,stats,likes`);
  return items;
}

export function getArtworkStats(id) {
  return client.get(`/kultura/artwork/${id}`, { add: ["stats", "likes"] });
}

export function findSimilarArtworks(artworkId, { page = 1, perPage = 10 }) {
  return client.get(`/kultura/artwork/${artworkId}/similar`, {
    pNumber: page + 1,
    pSize: perPage,
    add: ["stats", "artist", "likes"],
  });
}

export function getCollectionById(collectionId) {
  return client.get(`/kultura/collection/${collectionId}`, { add: ["stats", "likes"] });
}

export function getCollections() {
  return client.get("/kultura/collection?add=stats,likes&PNumber=0&PSize=5");
}

export function getCollectionStats(collectionId) {
  return client.post("/kultura/collection/stats", { collectionId });
}

export function getArtworkComments(artworkId, { page, perPage }) {
  const params = {
    PNumber: page,
    PSize: perPage,
    artworkId,
    orderBy: "DESC",
    add: ["likes"],
  };

  return client.get("/comments", params);
}

export function addArtworkComment(artworkId, body) {
  return client.post("/comments", { artworkId, body });
}

export async function getRandomArtworks(amount) {
  const { artworks } = await client.get("/kultura/artwork/random", {
    amount,
    add: ["artist", "stats", "likes"],
  });
  return artworks;
}

function storeUsername(user) {
  client.setHeader("X-Auth-Username", user.username);
}

export async function getUserInfo() {
  const user = await client.get("/kultura/user/current");

  if (user) {
    storeUsername(user);
  }

  return user;
}

export async function authenticate(username, password) {
  const user = await client.post("/kultura/login", { username, password });
  storeUsername(user);
  return user;
}

export function thirdPartyAuth(provider) {
  return `${BASE_URL}/oauth2/authorization/${provider}`;
}

export async function logoutUser() {
  await client.post("/kultura/logout");
  client.removeHeader("X-Auth-Username");
}

export async function createAccount(payload) {
  const user = await client.post("/kultura/user", payload);
  storeUsername(user);
  return user;
}

export function mergeTempUserData({ boards, likes, dislikes, views }) {
  const payload = {
    boards: boards.map(({ name, description, previewArtworkId, artworks }) => ({
      name,
      description,
      previewArtworkId,
      artworks,
    })),
    likes,
    dislikes,
    views,
  };

  return client.post("/account/merge", payload);
}

export function requestPasswordRecovery(email) {
  return client.post("/account/recovery/start", { email });
}

export async function getPasswordResetToken(uuid) {
  const { token } = await client.post("/account/recovery/token", { uuid });
  return token;
}

export async function setNewPassword(token, password) {
  return client.post("/account/recovery/set_password", { token, password });
}

export async function confirmEmail(verificationToken) {
  return client.post("/email/confirmation/finish", { verificationToken });
}

export function startEmailConfirmation() {
  return client.get("/email/confirmation/start");
}

export async function searchArtworks(
  query,
  { page = 0, perPage = 10, sortField = "relevance", sortDirection = "DESC", add = null } = {}
) {
  const params = {
    types: ["artwork"],
    sortBy: ["id", sortField],
    orderBy: ["DESC", sortDirection],
    pnumber: page,
    psize: perPage,
    // page: {
    //   pnumber: page,
    //   psize: perPage,
    //   sortedFields: [
    //     {
    //       fieldName: sortField,
    //       direction: sortDirection,
    //     },
    //     {
    //       fieldName: "id",
    //       direction: "DESC",
    //     },
    //   ],
    // },
    q: query,
    ...(add && { artwork: add }),
  };

  const { items, total } = await client.post(`/search`, params);

  return { artworks: items, total };
}

//deprecated
export async function searchArtists(
  query,
  { page = 0, perPage = 10, sortField = "relevance", sortDirection = "DESC", add = null } = {}
) {
  const url = add ? `/artist/search?add=${add.join(",")}` : "/artwork/search";
  const { searchResult, total } = await client.post(url, {
    page: {
      pnumber: page,
      psize: perPage,
      sortedFields: [
        {
          fieldName: sortField,
          direction: sortDirection,
        },
        {
          fieldName: "id",
          direction: "DESC",
        },
      ],
    },
    searchQuery: query,
  });

  return { artists: searchResult, total };
}

export async function searchCollections(
  query,
  { page = 0, perPage = 10, sortField = "relevance", sortDirection = "DESC", add = null } = {}
) {
  const url = add ? `/artwork/collection/search?add=${add.join(",")}` : "/kultura/artwork/search";
  const { items, total } = await client.post(url, {
    page: {
      pnumber: page,
      psize: perPage,
      sortedFields: [
        {
          fieldName: sortField,
          direction: sortDirection,
        },
        {
          fieldName: "id",
          direction: "DESC",
        },
      ],
    },
    q: query,
  });

  return { collections: items, total };
}

export function getBoards({
  page = 0,
  perPage = 10,
  add = "stats,likes",
  forCurrentUser,
  sortField,
  sortDirection,
  searchQuery,
  artworkId,
  id = null,
}) {
  const params = {
    PNumber: page,
    PSize: perPage,
    sortBy: sortField,
    orderBy: sortDirection,
    forCurrentUser,
    searchQuery,
    artworkId,
    add,
    ...(id ? { id } : null),
  };

  return client.get(`/kultura/board`, params);
}

export function getTopBoards() {
  return client.get("/kultura/board/top_visited?add=stats,likes&PSize=6");
}

export function getBoardById(boardId) {
  return client.get(`/kultura/board/${boardId}?add=stats,likes`);
}

export function createBoard(name, description = "") {
  return client.post("/kultura/board", { name, description });
}

export function updateBoard(id, data) {
  return client.put(`/kultura/board/${id}`, data);
}

export function publishBoard(id) {
  return client.put(`/kultura/board/${id}/publish`);
}

export function unpublishBoard(id) {
  return client.put(`/kultura/board/${id}/unpublish`);
}

export async function deleteBoard(id) {
  await client.delete(`/kultura/board/${id}`);

  return true;
}

export function getBoardComments(boardId, { page, perPage }) {
  const params = {
    boardId,
    PNumber: page,
    PSize: perPage,
    orderBy: "DESC",
    add: ["likes"],
  };

  return client.get("/comments", params);
}

export function addBoardComment(boardId, body) {
  return client.post("/comments", { boardId, body });
}

// Note(ars): Seems like we're not using this anymore @18.06.23
// export function shareBoard(boardId) {
//   return client.put(`/kultura/board/${boardId}/share`);
// }

export async function getDaisySuggestions(pSize = 9) {
  const { items } = await client.get(`/kultura/artwork/predictions`, { add: ["likes", "stats", "artist"], pSize });
  return items;
}

export async function tempGetDaisySuggestions(payload, amount = 9) {
  const { items } = await client.post("/kultura/artwork/recommendations?add=stats,likes,artist", {
    ...payload,
    amount,
  });
  return items;
}

export function addArtworkToBoard(boardId, artworkId, source = null, sourceId = null) {
  return client.put(`/kultura/board/${boardId}/artwork`, { artworkId, source, sourceId });
}

export function updateBoardArtworksPosition(boardId, payload) {
  return client.put(`/kultura/board/${boardId}/artwork_reorder`, payload);
}

export function updateBoardNote(boardId, artworkId, note) {
  return client.put(`/kultura/board/${boardId}/add_artwork_note`, { [artworkId]: note });
}

export function updateArtworkOnBoard(boardId, artworkId, { description, position }) {
  return client.put(`/kultura/board/${boardId}/artwork/${artworkId}`, { descriptionOnBoard: description, position });
}

export function deleteArtworkFromBoard(boardId, artworkId) {
  return client.delete(`/kultura/board/${boardId}/artwork/${artworkId}`);
}

export function getUserBoardsWithArtworksPresent(artworkIds) {
  const ids = Array.isArray(artworkIds) ? artworkIds : [artworkIds];
  return client.get(`/kultura/board/artworks_is_present?artworks=${ids.join(",")}`);
}

export async function getUserGalleries(username) {
  const { ownedGalleries } = await client.post("/account/get", { username });
  return ownedGalleries;
}

export async function getUserUploadCodes() {
  const { ownedKeys } = await client.post("/stripe/owned_keys");
  return ownedKeys;
}

export async function getArtworkMediumSuggestions(medium) {
  const mediums = await client.get("/kultura/artwork/mediums", { q: medium });
  return mediums;
}

export async function uploadArtwork(file, artworkData) {
  const formData = new FormData();

  formData.append("file", file);
  formData.append(
    "metadata",
    new Blob([JSON.stringify(artworkData)], {
      type: "application/json",
    }),
    "metadata.json"
  );

  return client.post("/kultura/artwork", formData);
}

export function getPaymentConfiguration() {
  return client.post("/stripe/configuration");
}

export async function startPaymentSession(email, priceId) {
  const { sessionId } = await client.post("/stripe/checkout/sessions", { email, priceId });
  return sessionId;
}

export async function getPaidKeys(sessionId) {
  const { keys } = await client.post("/stripe/paid_keys", { sessionId });
  return keys;
}

export function getGroupedComments(type, { amount = 2, commentsAmount = 3 } = {}) {
  return client.get(
    `/comments/group?type=${type}&amount=${amount}&commentsAmount=${commentsAmount}&add=likes,relation`
  );
}

export function commentReply(commentId, body) {
  return client.post("/comments", { commentId, body });
}

export function getCommentReplies(commentId, { page = 0, perPage = 15 }) {
  const params = {
    commentId,
    PNumber: page,
    PSize: perPage,
    add: "likes",
  };

  return client.get("/comments", params);
}

export function getArtworkRandomBoards(amount, artworkId) {
  return client.get(`/kultura/board/top_random?amount=${amount}&artworkId=${artworkId}&add=stats,likes,relation`);
}

export function getUserLikes(params) {
  return client.get(`/user/likes`, params);
}

export function getBlogpostComments(blogpostId, { page, perPage }) {
  const params = {
    postId: blogpostId,
    PNumber: page,
    PSize: perPage,
    orderBy: "DESC",
    add: "likes",
  };

  return client.get("/comments", params);
}

export function addBlogpostComment(postId, body) {
  return client.post("/comments", { postId, body });
}

export function searchPost(q, { page = 0, perPage = 3 }) {
  const params = {
    sortBy: ["published_at"],
    orderBy: ["DESC"],
    q,
    types: ["post"],
    // add: { post: ["stats", "likes"] },
    psize: perPage,
    pnumber: page,
  };

  return client.post("/search", params);
}

export function getLiveComments({ page = 0, perPage = 10 }) {
  const params = {
    PNumber: page,
    PSize: perPage,
    orderBy: "DESC",
  };

  return client.get("/comments/live", params);
}

export function editArtwork(id, payload) {
  return client.put(`/kultura/artwork/${id}`, payload);
}

export function updateUserSettings(settings) {
  return client.put(`/kultura/user/settings`, settings);
}

export function deleteArtwork(artworkId) {
  return client.delete(`/kultura/artwork/${artworkId}`);
}

export function deleteComment(commentId) {
  return client.delete(`/comments/${commentId}`);
}
