import { types, flow, getRoot } from "mobx-state-tree";
import { Profile } from "../ProfilesStore";
import { RecentArtistArtworksList, PopularArtistArtworksList } from "../ArtworksStore";

export const ProfilePage = types
  .model("ProfilePage", {
    profile: types.safeReference(Profile),
    recentArtworks: types.safeReference(RecentArtistArtworksList),
    popularArtworks: types.safeReference(PopularArtistArtworksList),
  })
  .views(self => ({
    get isCurrentUserProfile() {
      return getRoot(self).profiles.currentUserProfile?.id === self.profile?.id;
    },
  }))
  .actions(self => ({
    mount: flow(function* mount(profileId) {
      const { profiles, artworks } = getRoot(self);

      const profile = yield profiles.loadProfile(profileId);

      const popularArtworks = artworks.getArtistArtworks(profileId, "popular");
      const recentArtworks = artworks.getArtistArtworks(profileId, "recent");

      const loadQueue = [
        profile.loadStats(),
        profile.loadFollowInfo(),
        profile.loadComments(),
        profile.loadPosts(),
        popularArtworks.init(),
        recentArtworks.init(),
      ];

      if (getRoot(self).profiles.currentUserProfile.id === profile.id && getRoot(self).user.isLoggedIn) {
        yield getRoot(self).boards.loadUserBoards();
        // loadQueue.push(getRoot(self).boards.loadUserBoards());
      } else {
        yield profile.loadBoards();
        // loadQueue.push(profile.loadBoards());
      }

      yield Promise.all(loadQueue);

      self.profile = profile;
      self.popularArtworks = popularArtworks;
      self.recentArtworks = recentArtworks;

      yield Promise.all([]);
    }),
    unmount() {
      self.profile = undefined;
      self.popularArtworks = undefined;
      self.recentArtworks = undefined;
    },
  }));

export function create() {
  return ProfilePage.create();
}
