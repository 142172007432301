/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from "prop-types";
import NextLink from "next/link";
import AsyncLink from "./AsyncLink";

export default function Link({ href, as, beforeNav, ...props }) {
  return (
    <NextLink href={href} as={as}>
      {beforeNav ? <AsyncLink href={href} beforeNav={beforeNav} {...props} /> : <a {...props} />}
    </NextLink>
  );
}

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  beforeNav: PropTypes.func,
};

Link.defaultProps = {
  beforeNav: null,
  as: null,
};
