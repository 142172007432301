import { types, flow, getParent, getRoot, getType } from "mobx-state-tree";
import { VOTE_TYPES } from "const";
import {
  addArtworkLike,
  removeArtworkLike,
  addBoardLike,
  removeBoardLike,
  addArtworkCollectionLike,
  removeArtworkCollectionLike,
  addProfileLike,
  removeProfileLike,
  dislikeArtwork,
  dislikeProfile,
  addCommentLike,
  removeCommentLike,
  addCommentLike as addBlogpostCommentLike,
  removeCommentLike as removeBlogpostCommentLike,
  addCommentLike as addArtworkCommentLike,
  removeCommentLike as removeArtworkCommentLike,
  addCommentLike as addBoardCommentLike,
  removeCommentLike as removeBoardCommentLike,
  addBlogpostLike,
  removeBlogpostLike,
  dislikeBlogpost,
} from "api/likes";
import ae from "lib/analytics";

// maintain similar names for api methods according to store modules
const likesHandler = {
  addArtworkLike,
  removeArtworkLike,
  addBoardLike,
  removeBoardLike,
  addArtworkCollectionLike,
  removeArtworkCollectionLike,
  addProfileLike,
  removeProfileLike,
  dislikeArtwork,
  dislikeProfile,
  addBlogpostCommentLike,
  removeBlogpostCommentLike,
  addCommentLike,
  removeCommentLike,
  addArtworkCommentLike,
  removeArtworkCommentLike,
  addBoardCommentLike,
  removeBoardCommentLike,
  addBlogpostLike,
  removeBlogpostLike,
  dislikeBlogpost,
};

export function createLikesStats({ onLike = null, onRemoveLike = null } = {}) {
  return types
    .model("LikesStats", {
      currentUserValue: types.maybeNull(types.string),
      amountOfLikes: types.maybeNull(types.number),
    })
    .views(self => ({
      get type() {
        return getType(getParent(self)).name;
      },
    }))
    .actions(self => ({
      addLike: flow(function* addLike() {
        if (getRoot(self).user.isLoggedIn) {
          yield likesHandler[`add${self.type}Like`](getParent(self).id);
          getParent(self).stats?.incrementLikes();
          self.amountOfLikes += 1;
        }

        ae.votes.like({
          type: self.type,
          id: getParent(self).id,
        });

        self.currentUserValue = VOTE_TYPES.LIKE;

        getRoot(self).user.userData.addLike(getParent(self).id);
        if (onLike) onLike(getParent(self), self);
      }),
      removeVote: flow(function* removeVote() {
        const isLoggedIn = getRoot(self).user.isLoggedIn;

        const itemId = getParent(self).id;

        if (isLoggedIn) {
          yield likesHandler[`remove${self.type}Like`](itemId);
          if (self.currentUserValue === VOTE_TYPES.LIKE) {
            getRoot(self).user.userData.removeLike();
            // check for positive number in case when first user liked -> disliked item which has not yet updated on backend
            if (getParent(self).stats?.amountOfLikes > 0) {
              getParent(self).stats.decrementLikes();
              self.amountOfLikes -= 1;
            }
          }
        } else if (self.currentUserValue === VOTE_TYPES.LIKE) {
          getRoot(self).user.userData.removeLike(itemId);
        } else {
          getRoot(self).user.userData.removeDislike(itemId);
        }

        if (self.currentUserValue === VOTE_TYPES.LIKE) {
          ae.votes.removeLike({
            type: self.type,
            id: itemId,
          });
          if (onRemoveLike) onRemoveLike(getParent(self), self);
        } else {
          ae.votes.removeDislike({
            type: self.type,
            id: itemId,
          });
        }
        self.currentUserValue = null;
      }),
      dislike: flow(function* dislike() {
        const itemId = getParent(self).id;

        if (getRoot(self).user.isLoggedIn) {
          yield likesHandler[`dislike${self.type}`](itemId);
          if (self.currentUserValue === VOTE_TYPES.LIKE) {
            getRoot(self).user.userData.removeLike(itemId);
          }
        } else {
          getRoot(self).user.userData.addDislike(itemId);
        }

        if (self.currentUserValue === VOTE_TYPES.LIKE) {
          if (getParent(self).stats?.amountOfLikes > 0) getParent(self).stats.decrementLikes();
          ae.votes.removeLike({
            type: self.type,
            id: itemId,
          });
          if (onRemoveLike) onRemoveLike(getParent(self), self);
        }

        ae.votes.dislike({
          type: self.type,
          id: itemId,
        });
        self.currentUserValue = VOTE_TYPES.DISLIKE;
      }),
      updateCurrentValue(newVal) {
        self.currentUserValue = newVal;
      },
    }));
}
