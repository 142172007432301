import { nanoid } from "nanoid";
import { types, flow } from "mobx-state-tree";

export function createPageableList(Model, { loadMore, perPage, initPage = 0, safeReference = true }) {
  return types
    .model(`Pageable${Model.name}List`, {
      id: types.optional(types.identifier, nanoid),
      isLoaded: false,
      isLoading: false,
      list: types.array(safeReference ? types.safeReference(Model) : Model),
      totalItems: 0,
      page: initPage,
    })
    .views(self => ({
      get totalPages() {
        return Math.ceil(self.totalItems / perPage);
      },
      get haveMorePages() {
        return self.page < self.totalPages;
      },
      get isFullyLoaded() {
        return self.isLoaded && self.list.length === self.totalItems;
      },
    }))
    .actions(self => ({
      init: flow(function* init(options) {
        if (self.isLoading) return;

        const initFn = options?.initFn ?? loadMore;
        const paging = options?.paging ?? {};

        const pageOptions = { ...{ page: 0, perPage }, ...paging };

        if (self.isLoaded) return;
        try {
          self.isLoading = true;

          const { items, total } = yield initFn(self, pageOptions);

          self.list = items;
          self.page += 1;
          self.totalItems = total;
          self.isLoaded = true;
        } finally {
          self.isLoading = false;
        }
      }),
      loadMoreItems: flow(function* loadMoreItems(options = self) {
        if (!self.haveMorePages) return null;
        self.isLoading = true;
        try {
          const { items } = yield loadMore(options, { page: self.page, perPage });

          self.list.push(...items);
          self.page += 1;

          return items;
        } finally {
          self.isLoading = false;
        }
      }),
      addItem(item, { force = false, placement = "start" } = {}) {
        if (!self.isLoaded) return;

        if (force || self.list.length === self.totalItems) {
          if (typeof placement === "number") {
            self.list.splice(placement, 0, item);
          } else if (placement === "start") {
            self.list.unshift(item);
          } else {
            self.list.push(item);
          }
          self.page = Math.ceil(self.list.length / perPage);
        }

        self.totalItems += 1;
      },
      removeItem(item) {
        const index = self.list.findIndex(storedItem => storedItem === item);
        if (index !== -1) {
          self.list.splice(index, 1);
          self.page = Math.ceil(self.list.length / perPage);
        }

        self.totalItems -= 1;
      },
    }));
}
