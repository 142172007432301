import { pick } from "./index";

const artistNames = [
  "Da Vinci",
  "Michelangelo",
  "Rembrandt",
  "Vermeer",
  "Picasso",
  "Monet",
  "Gogh",
  "Munch",
  "Dali",
  "Warhol",
  "Matisse",
  "O’Keeffe",
  "Pollock",
  "Klimt",
  "Hopper",
  "Delacroix",
  "Cézanne",
];

const adjectives = [
  "Calm",
  "Colorful",
  "Famous",
  "Fragile",
  "Real",
  "Stormy",
  "Wet",
  "Adorable",
  "Attractive",
  "Bald",
  "Bold",
  "Beautiful",
  "Bored",
  "Brave",
  "Comfortable",
  "Cute",
  "Delightful",
  "Difficult",
  "Drab",
  "Dull",
  "Easy",
  "Elegant",
  "Funny",
  "Old",
  "Pleased",
  "Ugly",
];

const animals = [
  "Aardvark",
  "Abyssinian",
  "Penguin",
  "Snake",
  "Affenpinscher",
  "Hound",
  "Bullfrog",
  "Elephant",
  "Civet",
  "Frog",
  "Parrot",
  "Penguin",
  "Toad",
  "Dog",
  "Lizard",
  "Airedoodle",
  "Akbash",
  "Akita",
  "Shepherd",
  "Alabai",
  "Husky",
  "Malamute",
  "Pollock",
  "Tuna",
  "Albatross",
  "Tortoise",
  "Alligator",
  "Goat",
  "Alusky",
  "Amargasaurus",
  "Dolphin",
  "Beetle",
  "Bulldog",
  "Cockroach",
  "Coonhound",
  "Eskimo",
  "Dog",
  "Foxhound",
  "Terrier",
  "Hound",
  "Pit Bull",
  "Anaconda",
  "Anchovies",
  "Angelfish",
  "Ferret",
  "Lizard",
  "Ant",
  "Worm",
  "Anteater",
  "Antelope",
  "Ape",
  "Chihuahua",
  "Fox",
  "Hare",
  "Wolf",
  "Scorpion",
  "Rattlesnake",
  "Armadillo",
  "Bear",
  "Asp",
  "Salmon",
];

export default function getRandomName() {
  const parts = [pick(artistNames), pick(adjectives), pick(animals)];
  parts.sort(() => Math.random() - Math.random());
  return parts.join(" ");
}
