import { createContext, useContext } from "react";
import { types, resolvePath } from "mobx-state-tree";

// eslint-disable-next-line import/no-cycle
import { UIStore, create as createUIStore } from "./UIStore";
import { ProfilesStore, create as createProfilesStore } from "./ProfilesStore";
import { ArtworksStore, create as createArtworksStore } from "./ArtworksStore";
import { CollectionsStore, create as createCollectionsStore } from "./CollectionsStore";
import { PagesStore, create as createPagesStore } from "./PagesStore";
import { UserStore, create as createUserStore } from "./UserStore";
import { BoardsStore, create as createBoardsStore } from "./BoardsStore";
import { TimeoutsStore, create as createTimeoutsStore } from "./TimeoutsStore";
import { NotificationsStore, create as createNotificationsStore } from "./NotificationsStore";
import { BlogpostsStore, create as createBlogpostsStore } from "./BlogpostsStore";
import { LiveCommentsStore, create as createLiveCommentsStore } from "./CommentsStore";

export const StoreContext = createContext(null);
StoreContext.displayName = "StoreContext";

const RootStore = types
  .model("RootStore", {
    user: UserStore,
    ui: UIStore,
    profiles: ProfilesStore,
    artworks: ArtworksStore,
    collections: CollectionsStore,
    boards: BoardsStore,
    pages: PagesStore,
    timeouts: TimeoutsStore,
    notifications: NotificationsStore,
    blogposts: BlogpostsStore,
    liveComments: LiveCommentsStore,
  })
  .actions(self => ({
    reset() {
      self.user.reset();
      self.boards.resetUserBoards();
      self.pages.daisyPage.resetSuggestions();
      self.profiles.resetCurrentUserProfile();
      self.notifications.reset();
      self.blogposts.resetUserBlogposts();
    },
  }));

export function createRootStore() {
  return RootStore.create({
    user: createUserStore(),
    ui: createUIStore(),
    profiles: createProfilesStore(),
    artworks: createArtworksStore(),
    collections: createCollectionsStore(),
    boards: createBoardsStore(),
    pages: createPagesStore(),
    timeouts: createTimeoutsStore(),
    notifications: createNotificationsStore(),
    blogposts: createBlogpostsStore(),
    liveComments: createLiveCommentsStore(),
  });
}

export function useStore(path = "") {
  return resolvePath(useContext(StoreContext), path);
}
