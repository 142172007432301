/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from "prop-types";
import { useState, forwardRef } from "react";
import { useField } from "formik";
import cn from "classnames";

const InputField = forwardRef(function InputField(
  { as: El, id, name, disabled, className, getStyles, inputStyles, onFocus, onBlur, formik, errorStyles, ...props },
  ref
) {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);

  if (!formik) {
    const { container, input } = getStyles({ disabled, focused });
    return <El ref={ref} className={cn(container, input, className)} disabled={disabled} {...props} />;
  }

  const [{ onChange, ...field }, { error }] = useField(name);

  const hasError = touched && error;

  const { input, message, container } = getStyles({ disabled, hasError, focused });

  return (
    <label htmlFor={id || name} className={cn("relative block", className, container)}>
      <El
        id={id || name}
        name={name}
        ref={ref}
        className={cn("w-full", input, inputStyles)}
        disabled={disabled}
        {...field}
        onChange={e => {
          setTouched(true);
          onChange(e);
        }}
        onFocus={e => {
          setFocused(true);
          onFocus(e);
        }}
        onBlur={e => {
          setFocused(false);
          onBlur(e);
        }}
        {...props}
      />
      {!focused && typeof error === "string" && (
        <span
          className={cn(
            "pointer-events-none absolute inset-0 mx-4 my-4 my-auto flex select-none items-center",
            errorStyles,
            message
          )}
        >
          {error}
        </span>
      )}
    </label>
  );
});

InputField.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  getStyles: PropTypes.func,
  inputStyles: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  formik: PropTypes.bool,
  errorStyles: PropTypes.string,
};

InputField.defaultProps = {
  as: "input",
  name: null,
  id: null,
  disabled: false,
  className: null,
  getStyles: ({ disabled }) => ({
    input: {
      "font-light rounded-5 px-18 py-10 border transition-colors duration-150 ease-out focus:bg-white-100 focus:border-new-blue-100": true,
      "bg-new-gray-200 border-gray-600": disabled,
      "placeholder-gray-100 border-gray-600 bg-white-100": !disabled,
    },
    message: "bg-white-200 text-14",
  }),
  inputStyles: null,
  onFocus: () => {},
  onBlur: () => {},
  formik: true,
  errorStyles: null,
};

export default InputField;
