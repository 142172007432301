import PropTypes from "prop-types";
import cn from "classnames";

export default function Card({ as: El, className, ...props }) {
  return <El className={cn("rounded-5 border border-white-400 bg-white-200", className)} {...props} />;
}

Card.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  className: PropTypes.string,
};

Card.defaultProps = {
  as: "div",
  className: null,
};
