import { types, flow, getRoot } from "mobx-state-tree";
import { ArtworkCollection } from "../CollectionsStore";
import { CollectionArtworksList } from "../ArtworksStore";

export const CollectionPage = types
  .model("CollectionPage", {
    collection: types.safeReference(ArtworkCollection),
    artworks: types.safeReference(CollectionArtworksList),
  })
  .actions(self => ({
    mount: flow(function* mount(collectionId) {
      const { collections, artworks } = getRoot(self);
      const collection = yield collections.loadCollection(collectionId);
      self.collection = collection;
      yield collection.loadStats();

      const collectionArtworks = artworks.getCollectionArtworks(collectionId);
      self.artworks = collectionArtworks;
      yield collectionArtworks.init();
    }),
    unmount() {
      self.collection = undefined;
      self.artist = undefined;
      self.artworks = undefined;
    },
  }));

export function create() {
  return CollectionPage.create();
}
