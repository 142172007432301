import { setup, addEvent, setMetadata, sendEvents } from "@stikipixels/analytics-browser";
import throttle from "lodash/throttle";

import artworks from "./artworks";
import boards from "./boards";
import user from "./user";
import search from "./search";
import votes from "./votes";
import daisy from "./daisy";
import tags from "./tags";
import sensitive from "./sensitive";
import promo from "./promo";
import whisperer from "./whisperer";

setup({
  endpoint: process.env.NEXT_PUBLIC_ANALYTICS_URL ?? "",
  metadata: {
    platform: "kultura",
    userId: null,
    groupId: null,
  },
});

export function setUser(userData) {
  const { userId, groupId } = userData
    ? { userId: userData?.id ?? null, groupId: userData?.groupId ?? null }
    : { userId: null, groupId: null };

  sendEvents();
  setMetadata({ userId, groupId });
}

// Analytic Events
export default {
  artworks,
  boards,
  user,
  search,
  votes,
  daisy,
  tags,
  sensitive,
  promo,
  whisperer,
  navigation: throttle((to, from = window.location.pathname) => {
    if (from === to) {
      return;
    }

    addEvent({ type: "navigation", payload: { from, to } });
  }, 200),
  share(shareType, entityId, entityType) {
    addEvent({ type: "share", payload: { path: window.location.pathname, shareType, entityId, entityType } });
  },
  comment(entityId, entityType, commentId) {
    addEvent({ type: "comment", payload: { path: window.location.pathname, entityId, entityType, commentId } });
  },
};
