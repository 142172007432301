import { addEvent } from "@stikipixels/analytics-browser";

function send(eventType, { type, id }) {
  addEvent({ type: `votes.${eventType}`, payload: { type: type.toLowerCase(), id, path: window.location.pathname } });
}

export default {
  like(payload) {
    send("like", payload);
  },
  removeLike(payload) {
    send("remove-like", payload);
  },
  dislike(payload) {
    send("dislike", payload);
  },
  removeDislike(payload) {
    send("remove-dislike", payload);
  },
};
