import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export function formatDate(date, format = "D/M/YYYY") {
  return dayjs(date).format(format);
}

export function getRelativeTime(dateStr) {
  const date = dayjs.utc(dateStr);
  return dayjs().diff(date, "s") > 0 ? date.fromNow() : "just now";
}
