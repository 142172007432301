import { ERROR_MESSAGES } from "const";

function withQueryParams(url, params) {
  const entries = Object.entries(params).filter(([_key, value]) => value !== null && value !== undefined);
  return `${url}?${new URLSearchParams(entries).toString()}`;
}

export default function createClient({ baseUrl = "", headers: defaultHeaders }) {
  const headers = { ...defaultHeaders };

  async function http(path, { body, ...options }) {
    const reqOptions = {
      ...options,
      headers: { ...headers },
      credentials: "include",
    };

    if (typeof window !== "undefined" && body instanceof FormData) {
      reqOptions.body = body;
    } else if (typeof body === "object") {
      reqOptions.headers["Content-Type"] = "application/json";
      reqOptions.body = JSON.stringify(body);
    } else {
      reqOptions.body = body;
    }

    const resp = await fetch(`${baseUrl}${path}`, reqOptions);

    if (!resp.ok) {
      if (!resp.body) throw new Error(ERROR_MESSAGES.DEFAULT);
      // Note(ars): add content type check, with text/plain we get a cryptic error here
      const { code } = await resp.json();
      const error = new Error(ERROR_MESSAGES[code] || ERROR_MESSAGES.DEFAULT);
      error.code = code;

      throw error;
    }

    const contentType = resp.headers.get("content-type");
    if (contentType && contentType.includes("application/json")) {
      return resp.json();
    }

    return null;
  }

  http.get = (path, queryParams) => http(queryParams ? withQueryParams(path, queryParams) : path, { method: "GET" });

  http.post = (path, payload = null, queryParams = null) =>
    http(queryParams ? withQueryParams(path, queryParams) : path, {
      method: "POST",
      body: payload,
    });

  http.put = (path, payload = null, queryParams = null) =>
    http(queryParams ? withQueryParams(path, queryParams) : path, {
      method: "PUT",
      body: payload,
    });

  http.delete = (path, payload) => http(path, { method: "DELETE", body: payload });

  http.setHeader = (name, value) => {
    headers[name] = value;
  };
  http.removeHeader = name => {
    delete headers[name];
  };

  return http;
}
