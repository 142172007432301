import PropTypes from "prop-types";
import { useRef, useEffect, memo } from "react";

function decode(hash, width, height) {
  return import("blurhash-wasm").then(module => module.decode(hash, width, height));
}

function Blurhash({ hash, width, height, className, style }) {
  const canvasRef = useRef();

  useEffect(() => {
    async function load() {
      const canvas = canvasRef.current;
      const pixels = await decode(hash, width, height);
      const ctx = canvas.getContext("2d");
      const imageData = ctx.createImageData(width, height);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
    }

    load();
  }, [hash, width, height]);

  return <canvas ref={canvasRef} width={width} height={height} className={className} style={style} />;
}

Blurhash.propTypes = {
  hash: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.shape(),
};

Blurhash.defaultProps = {
  width: 32,
  height: 32,
  className: null,
  style: null,
};

export default memo(Blurhash);
