/* eslint-disable import/no-cycle */
import { types, flow, getRoot, detach, getParent } from "mobx-state-tree";
import {
  getArtworkById,
  getArtworksInfo,
  getRandomArtworks,
  getArtistArtworks,
  getCollectionArtworks,
  getBoardArtworks,
  getArtworkStats,
  getArtworkComments,
  getDaisySuggestions,
  tempGetDaisySuggestions,
  getUserBoardsWithArtworksPresent,
  addArtworkComment,
  getLikedArtworks,
  getFeaturedArtworks,
  getArtworks,
  searchLikedArtworks,
  findSimilarArtworks,
  updateBoardArtworksPosition,
  commentReply,
  editArtwork,
  deleteArtwork,
  deleteComment,
} from "api";
import {
  getArtworkTagSuggestions,
  suggestArtworkTagVote,
  callArtworkTagAction,
  findSimilarArtworksByTag,
} from "api/tags";
import { searchAll } from "api/search";
import mergeWith from "lodash/mergeWith";
import { values } from "mobx";
import ae from "lib/analytics";

import { ArtworkCollection } from "./CollectionsStore";
import { Profile } from "./ProfilesStore";
// import { Board } from "./BoardsStore";
import { Comment } from "./CommentsStore";
import { createLikesStats } from "./LikesStore";

import { createPageableList } from "./utils";
import { ENTITY_TYPES, TAG_ACTIONS, VOTE_TYPES } from "../const";

const PageableComments = createPageableList(
  types.late(() => Comment),
  {
    loadMore(self, pageOptions) {
      return getRoot(self).boards.loadCommentReplies(getParent(self).id, pageOptions);
    },
    perPage: 15,
    safeReference: false,
  }
);

const ArtworkComment = types
  .compose(
    "ArtworkComment",
    Comment,
    types.model({
      replies: types.optional(PageableComments, () => PageableComments.create()),
    })
  )
  .actions(self => ({
    addItem: flow(function* addItem(commentId, commentBody) {
      const comment = yield commentReply(commentId, commentBody);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.replies.list.push(comment);
      self.nestedCommentsCount += 1;
    }),
  }));

const PageableArtworkComments = createPageableList(ArtworkComment, {
  async loadMore(self, pageOptions) {
    const { comments, total } = await getArtworkComments(getParent(self).id, pageOptions);
    return { items: comments, total };
  },
  perPage: 10,
  safeReference: false,
});

const ArtworkStats = types
  .model("ArtworkStats", {
    amountOfBoards: 0,
    amountOfBuyers: 0,
    amountOfComments: 0,
    amountOfPurchases: 0,
    amountOfWishlists: 0,
    amountOfZooms: 0,
    amountOfLikes: 0,
  })
  .views(self => ({
    get short() {
      const { amountOfLikes, amountOfComments, amountOfBoards } = self;
      return { amountOfLikes, amountOfComments, amountOfBoards };
    },
  }))
  .actions(self => ({
    incrementLikes() {
      self.amountOfLikes += 1;
    },
    decrementLikes() {
      self.amountOfLikes -= 1;
    },
  }));

const BoardPreview = types.model("BoardPreview", {
  id: types.identifierNumber,
  name: types.string,
});

export const Tag = types.model("Tag", {
  tag: types.string,
  weight: types.maybeNull(types.number),
  action: types.maybeNull(types.string),
});

export const Artwork = types
  .model("Artwork", {
    id: types.identifierNumber,
    title: "",
    date: "",
    medium: "",
    description: "",
    uploadedAt: types.string,
    filename: types.string,
    isDetached: false,
    width: types.maybeNull(types.number),
    height: types.maybeNull(types.number),
    blurHash: types.maybeNull(types.string),
    mainColor: types.maybeNull(types.string),
    ratio: types.maybeNull(types.number),
    stats: types.maybeNull(ArtworkStats),
    comments: types.optional(PageableArtworkComments, () => PageableArtworkComments.create()),
    totalComments: types.maybeNull(types.number),
    previewBoard: types.maybeNull(BoardPreview),
    userBoards: types.maybeNull(types.array(types.union(types.number, types.string))),
    // Todo(ars): needs to go away
    artistId: types.maybeNull(types.number),
    collectionId: types.maybeNull(types.number),
    // ----------
    artist: types.safeReference(types.late(() => Profile)),
    collection: types.safeReference(ArtworkCollection),
    likes: types.maybeNull(
      createLikesStats({
        onLike(artwork, self) {
          getRoot(self).user.likedArtworks.addArtwork(artwork);
        },
        onRemoveLike(artwork, self) {
          getRoot(self).user.likedArtworks.deleteArtwork(artwork, true);
        },
      })
    ),
    tags: types.maybeNull(types.array(Tag)),
    sensitiveContent: false,
    whisperer: false,
    visibilityLevel: "FULL",
  })
  .views(self => ({
    isInBoard(board) {
      return !!(self.userBoards && self.userBoards.find(userBoardId => userBoardId === board.id));
    },
  }))
  .actions(self => ({
    addArtistRef(artist) {
      self.artist = artist;
    },
    addCollectionRef(collection) {
      self.collection = collection;
    },
    addUserBoard(board) {
      self.userBoards.push(board.id);
    },
    deleteUserBoard(board) {
      if (self.userBoards) {
        self.userBoards = self.userBoards.filter(userBoardId => userBoardId !== board.id);
      }
      if (self.previewBoard?.id === board.id) {
        self.previewBoard = null;
      }
    },
    beforeDetach() {
      self.isDetached = true;
    },
    loadArtist: flow(function* loadArtist() {
      if (self.artist || !self.artistId) return;

      const { profiles } = getRoot(self);
      yield profiles.loadProfile(self.artistId);

      // Todo(ars): find a better way to cancel/delete not (yet) loaded artist
      if (self.isDetached) {
        profiles.delete(self.artistId);
      } else {
        self.artist = self.artistId;
      }
    }),
    loadStats: flow(function* LoadStats() {
      if (self.stats) return;

      const { stats, likes } = yield getArtworkStats(self.id);
      self.likes = likes;
      self.stats = stats;
    }),
    loadCollection: flow(function* loadCollection() {
      if (self.collection || !self.collectionId) return;
      self.collection = yield getRoot(self).collections.loadCollection(self.collectionId);
    }),
    loadUserBoards() {
      if (self.userBoards) return;

      if (getRoot(self).user.isLoggedIn) {
        getUserBoardsWithArtworksPresent(self.id).then(self.setUserBoards);
      } else {
        self.userBoards = getRoot(self).user.userData.getBoardsWithArtwork(self.id);
      }
    },
    setUserBoards(boards) {
      self.userBoards = boards[self.id] || [];
    },
    addComment: flow(function* addComment(commentBody) {
      const comment = yield addArtworkComment(self.id, commentBody);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.comments.list.unshift(comment);
      self.totalComments += 1;

      ae.comment(self.id, ENTITY_TYPES.ARTWORK, comment.id);
    }),
    setTotalComments(val) {
      self.totalComments = val;
    },
    initComments: flow(function* initComments() {
      yield self.comments.init();
      self.setTotalComments(self.comments.totalItems);
    }),
    loadSuggestedTags: flow(function* loadTags() {
      if (self.suggestedTags) return;
      const artworkTags = yield getArtworkTagSuggestions(self.id);
      self.suggestedTags = artworkTags;
    }),
    addNewTag: flow(function* addNewTag(tagName, source, executor) {
      const tags = yield callArtworkTagAction(self.id, tagName, TAG_ACTIONS.ADD_TAG);
      ae.tags.artwork.addTag({ artworkId: self.id, tag: tagName, source, executor });
      self.tags = tags;
    }),
    removeTag: flow(function* removeTag(tagName, source, executor) {
      const tags = yield callArtworkTagAction(self.id, tagName, TAG_ACTIONS.DELETE_TAG);
      ae.tags.artwork.deleteTag({ artworkId: self.id, tag: tagName, source, executor });
      self.tags = tags;
    }),
    upvoteTag: flow(function* upvoteTag(tagName, source, executor) {
      const tags = yield callArtworkTagAction(self.id, tagName, TAG_ACTIONS.UPVOTE);
      ae.tags.artwork.upvote({ artworkId: self.id, tag: tagName, source, executor });

      self.tags = tags;
    }),
    downvoteTag: flow(function* downvoteTag(tagName, source, executor) {
      const tags = yield callArtworkTagAction(self.id, tagName, TAG_ACTIONS.DOWNVOTE);
      ae.tags.artwork.downvote({ artworkId: self.id, tag: tagName, source, executor });
      self.tags = tags;
    }),
    removeTagVote: flow(function* removeTagVote(tagName, source, executor) {
      const tags = yield callArtworkTagAction(self.id, tagName, TAG_ACTIONS.REMOVE_VOTE);
      ae.tags.artwork.removevote({ artworkId: self.id, tag: tagName, source, executor });
      self.tags = tags;
    }),
    suggestTagVote: flow(function* suggestTagVote() {
      const suggestedTag = yield suggestArtworkTagVote(self.id);
      return suggestedTag;
    }),
    updateArtwork: flow(function* updateArtwork({
      title,
      date,
      medium,
      height,
      width,
      description,
      sensitiveContent,
      visibilityLevel,
    }) {
      const resp = yield editArtwork(self.id, {
        title,
        date,
        medium: medium.value,
        height,
        width,
        description,
        sensitiveContent,
        visibilityLevel,
      });

      self.title = resp.title;
      self.date = resp.date;
      self.medium = resp.medium;
      self.height = resp.height;
      self.width = resp.width;
      self.description = resp.description;
      self.sensitiveContent = resp.sensitiveContent;
      self.visibilityLevel = resp.visibilityLevel;
    }),
    removeComment: flow(function* removeComment(commentId, parentCommentId = null) {
      yield deleteComment(commentId);
      self.totalComments -= 1;

      if (parentCommentId) {
        const parentComment = self.comments.list.find(commentItem => commentItem.id === parentCommentId);
        const comment = parentComment.replies.list.find(replyItem => replyItem.id === commentId);
        return parentComment.replies.removeItem(comment);
      }

      const comment = self.comments.list.find(commentItem => commentItem.id === commentId);
      return self.comments.removeItem(comment);
    }),
    setIsNativeAspectRatio(val) {
      self.isNativeAspectRatio = val;
    },
  }));

const ARTWORKS_PER_PAGE = 20;

export const RecentArtistArtworksList = createPageableList(Artwork, {
  loadMore: (self, { page, perPage }) =>
    getRoot(self).artworks.loadArtistArtworks(self.id, {
      page,
      perPage,
      sortField: "uploadedAt,id",
    }),
  perPage: 9,
});

export const PopularArtistArtworksList = createPageableList(Artwork, {
  loadMore: (self, { page, perPage }) =>
    getRoot(self).artworks.loadArtistArtworks(self.id, {
      page,
      perPage,
      sortField: "statistic.likes,id",
    }),
  perPage: 9,
});

export const CollectionArtworksList = createPageableList(Artwork, {
  loadMore: (self, pageOptions) => getRoot(self).artworks.loadCollectionArtworks(self.id, pageOptions),
  perPage: ARTWORKS_PER_PAGE,
});

export const BoardArtworksList = createPageableList(Artwork, {
  loadMore: async (self, { page, perPage }) => {
    const board = getRoot(self).boards.list.get(self.id);

    if (board.isTemp) {
      return getRoot(self).artworks.loadTempBoardArtworks(self.id, { page, perPage });
    }

    return getRoot(self).artworks.loadBoardArtworks(self.id, {
      PNumber: page,
      PSize: perPage,
    });
  },
  perPage: ARTWORKS_PER_PAGE,
}).actions(self => ({
  updateList: flow(function* updateList(artworksList) {
    const changes = artworksList.reduce((changeSet, artwork, index) => {
      if (self.list[index].id !== artwork.id) {
        // eslint-disable-next-line no-param-reassign
        changeSet[artwork.id] = index;
      }

      return changeSet;
    }, {});

    const board = getRoot(self).boards.list.get(self.id);
    if (board.isTemp) {
      getRoot(self).user.userData.updateBoardArtworks(self.id, changes);
    } else {
      yield updateBoardArtworksPosition(getRoot(self).pages.boardPage.board.id, changes);
    }

    self.list = artworksList;
  }),
}));

export const ArtworksStore = types
  .model("ArtworksStore", {
    list: types.map(Artwork),
    collectionArtworks: types.map(CollectionArtworksList),
    boardArtworks: types.map(BoardArtworksList),
    recentArtistArtworks: types.map(RecentArtistArtworksList),
    popularArtistArtworks: types.map(PopularArtistArtworksList),
  })
  .actions(self => ({
    getArtistArtworks(artistId, type = "popular") {
      if (type === "recent") {
        return (
          self.recentArtistArtworks.get(artistId) ||
          self.recentArtistArtworks.put(RecentArtistArtworksList.create({ id: String(artistId) }))
        );
      }

      return (
        self.popularArtistArtworks.get(artistId) ||
        self.popularArtistArtworks.put(PopularArtistArtworksList.create({ id: String(artistId) }))
      );
    },
    getCollectionArtworks(collectionId) {
      return (
        self.collectionArtworks.get(collectionId) ||
        self.collectionArtworks.put(CollectionArtworksList.create({ id: String(collectionId) }))
      );
    },
    getBoardArtworks(boardId) {
      return (
        self.boardArtworks.get(boardId) || self.boardArtworks.put(BoardArtworksList.create({ id: String(boardId) }))
      );
    },
    loadRandom: flow(function* loadRandom(amount) {
      const artworks = yield getRandomArtworks(amount);
      return self.mapArtworks(artworks);
    }),
    loadArtwork: flow(function* loadArtwork(artworkId, { force = false } = {}) {
      if (!force && self.list.has(artworkId)) return self.list.get(artworkId);

      const artworkData = yield getArtworkById(artworkId);
      return self.mapArtwork(artworkData);
    }),
    loadArtistArtworks: flow(function* loadArtistArtworks(artistId, pagingOptions) {
      const { items, total } = yield getArtistArtworks(artistId, pagingOptions);
      return { items: self.mapArtworks(items), total };
    }),
    loadCollectionArtworks: flow(function* loadCollectionArtworks(collectionId, pagingOptions) {
      const { items, total } = yield getCollectionArtworks(collectionId, pagingOptions);
      return { items: self.mapArtworks(items), total };
    }),
    loadBoardArtworks: flow(function* loadBoardArtworks(boardId, pagingOptions) {
      const { items, total } = yield getBoardArtworks(boardId, pagingOptions);
      return { items: self.mapArtworks(items), total };
    }),
    loadTempBoardArtworks: flow(function* loadTempBoardArtworks(boardId, { page, perPage }) {
      const board = getRoot(self).user.userData.boards.find(tempBoard => tempBoard.id === boardId);

      const offset = page * perPage;
      const { items } = yield getArtworks({ page, perPage, id: board.artworks.slice(offset, offset + perPage) });
      return { items: self.mapArtworks(items), total: board.artworks.length };
    }),
    loadLikedArtworks: flow(function* loadLikedArtworks(pageOptions) {
      if (getRoot(self).user.isLoggedIn) {
        const { artworks, total } = yield getLikedArtworks(pageOptions);
        return { artworks: self.mapArtworks(artworks), total };
      }

      return { artworks: [], total: 0 };
    }),
    loadTempLikedArtworks: flow(function* loadTempLikedArtworks({ page, perPage }) {
      const { likes, totalLikes } = getRoot(self).user.userData;
      if (likes.length) {
        const offset = page * perPage;
        const artworks = yield getArtworksInfo(likes.slice(offset, offset + perPage));
        return { artworks: self.mapArtworks(artworks), total: totalLikes };
      }

      return { artworks: [], total: 0 };
    }),
    searchLikedArtworks: flow(function* searchLikes(query, pageOptions) {
      const { artworks, total } = yield searchLikedArtworks(query, pageOptions);
      return { artworks: self.mapArtworks(artworks), total };
    }),
    loadDaisySuggestions: flow(function* loadDaisySuggestions(amount) {
      const artworks = yield getDaisySuggestions(amount);
      return self.mapArtworks(artworks);
    }),
    tempLoadDaisySuggestions: flow(function* tempLoadDaisySuggestions(amount) {
      const { likes, dislikes, views } = getRoot(self).user.userData;
      const payload = {};
      if (likes.length) payload.likes = likes;
      if (dislikes.length) payload.dislikes = dislikes;
      if (views.length) payload.views = views;

      const artworks = yield tempGetDaisySuggestions(payload, amount);
      return self.mapArtworks(artworks);
    }),
    loadSimilarArtworks: flow(function* loadSimilarArtworks({ id }, pageOptions) {
      const { items: artworksData, total } = yield findSimilarArtworks(id, pageOptions);
      const artworks = self.mapArtworks(artworksData);

      return { items: artworks, total };
    }),
    loadFeaturedArtworks: flow(function* loadFeaturedArtworks() {
      const { items } = yield getFeaturedArtworks();
      return self.mapArtworks(items);
    }),
    loadRecentArtworks: flow(function* loadRecentArtworks(pagingOptions) {
      const { items, total } = yield getArtworks({
        sortField: "uploadedAt",
        sortDirection: "DESC",
        perPage: 20,
        ...pagingOptions,
      });
      return { items: self.mapArtworks(items), total };
    }),
    loadPopularArtworks: flow(function* loadPopularArtworks(pagingOptions) {
      const { items, total } = yield getArtworks({
        ...pagingOptions,
        sortField: "statistic.likes",
        sortDirection: "DESC",
        perPage: 20,
      });
      return { items: self.mapArtworks(items), total };
    }),
    mapArtwork({ artist, collection, comments, ...artworkData }) {
      if (!getRoot(self).user.isLoggedIn) {
        const userData = getRoot(self).user.userData;
        if (userData.likes.includes(artworkData.id)) {
          if (artworkData.likes) {
            // eslint-disable-next-line no-param-reassign
            artworkData.likes.currentUserValue = VOTE_TYPES.LIKE;
          } else {
            // eslint-disable-next-line no-param-reassign
            artworkData.likes = { currentUserValue: VOTE_TYPES.LIKE };
          }
        } else if (userData.dislikes.includes(artworkData.id)) {
          if (artworkData.likes) {
            // eslint-disable-next-line no-param-reassign
            artworkData.likes.currentUserValue = VOTE_TYPES.DISLIKE;
          } else {
            // eslint-disable-next-line no-param-reassign
            artworkData.likes = { currentUserValue: VOTE_TYPES.DISLIKE };
          }
        }
      }

      if (comments) {
        if (Array.isArray(comments)) {
          // eslint-disable-next-line no-param-reassign
          artworkData.comments = { list: comments };
        } else {
          // eslint-disable-next-line no-param-reassign
          artworkData.comments = comments;
        }
      }

      let artwork;
      if (self.list.has(artworkData.id)) {
        artwork = self.list.get(artworkData.id);

        const mergedArtwork = mergeWith(artwork, artworkData, (v1, v2) => v2 || v1);
        self.list.set(artwork.id, mergedArtwork);
      } else {
        artwork = self.list.put(artworkData);
      }

      if (artist) artwork.addArtistRef(getRoot(self).profiles.addFromSnapshot(artist));
      if (collection) artwork.addCollectionRef(getRoot(self).collections.addFromSnapshot(collection));

      return artwork;
    },
    mapArtworks(artworksData) {
      return artworksData.map(self.mapArtwork);
    },
    removeArtworks(artworks) {
      artworks.forEach(artwork => self.list.delete(artwork.id));
    },
    removeArtwork: flow(function* removeArtwork(artwork) {
      yield deleteArtwork(artwork.id);
      detach(artwork);
    }),
    search: flow(function* search(query, pageOptions) {
      const { searchResult, total } = yield searchAll(query, { ...pageOptions, types: ["artwork"] });
      const artworkIds = self.mapArtworks(searchResult.artwork.items);

      return { artworks: artworkIds, total };
    }),
    resetUserLikes() {
      values(self.list).forEach(artwork => {
        if (artwork?.likes?.currentUserValue) {
          artwork.likes.updateCurrentValue(null);
        }
      });
    },
    loadSimilarArtworksByTag: flow(function* loadSimilarArtworksByTag(artworkId, tagName, pageOptions) {
      const { items: artworksData, total } = yield findSimilarArtworksByTag(artworkId, tagName, pageOptions);
      const artworks = self.mapArtworks(artworksData);

      return { items: artworks, total };
    }),
  }));

export function create() {
  return ArtworksStore.create();
}
