import PropTypes from "prop-types";
import cn from "classnames";
import { cloneElement } from "react";
import styles from "./BoardCover.module.css";

export default function BoardCover({ children, ...props }) {
  const coverItem = cloneElement(children, {
    className: cn(styles.coverItem, children.props.className),
  });

  return (
    <div {...props}>
      <div className={styles.cover}>{coverItem}</div>
    </div>
  );
}

BoardCover.propTypes = {
  children: PropTypes.node.isRequired,
};
