import { observer } from "mobx-react-lite";
import { useStore } from "store";
import dynamic from "next/dynamic";
import PageNav from "./PageNav";

function HeaderFallback() {
  return <header className="min-h-[5.8rem] bg-white-100" />;
}

const MobileHeader = dynamic(() => import("./MobileHeader"), { loading: HeaderFallback });
const DesktopHeader = dynamic(() => import("./DesktopHeader"), { loading: HeaderFallback });

function Header() {
  const { isMobile } = useStore("/ui");

  return (
    <>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
      <PageNav />
    </>
  );
}

export default observer(Header);
