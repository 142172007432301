import { Link, Divider } from "components/basic";
import dayjs from "dayjs";
import { URL } from "const";

export default function Footer() {
  return (
    <footer className="order-12 bg-black-300 text-11 leading-16 text-white-100">
      <div className="container flex flex-col px-15 py-10 md:flex-row md:items-center 2xl:p-0">
        <div className="flex flex-shrink-0 items-center justify-between md:order-last md:ml-auto md:flex-col md:items-end md:pl-20 xl:flex-row xl:items-center">
          <Link
            href="https://kulturaexmachina.com/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-shrink-0 xl:order-last"
          >
            <img width="106" height="33" src="/img/kxm-logo-white.svg" alt="kultura ex machina logo" />
          </Link>
          <Link href={URL.PRIVACY} className="flex-shrink-0 text-12 font-medium hover:underline">
            Privacy policy
          </Link>
          <Divider height="h-20" width="w-1" color="bg-white-100" className="mx-20 hidden xl:block" />
        </div>
        <div className="mt-10 text-justify md:mt-0 2xl:flex">
          <p className="font-semibold md:font-medium xl:mr-5 2xl:text-12">
            &copy; KULTURA Ex Machina Ltd {dayjs().year()}.
          </p>
          <p className="md:font-light 2xl:text-11">
            Unless otherwise indicated, all rights in each work protected by copyright are vested in the individual
            artist who created such work and are used by KULTURA/OWW with the permission of said artists.
          </p>
        </div>
      </div>
    </footer>
  );
}
