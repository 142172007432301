import PropTypes from "prop-types";
import cn from "classnames";

export default function Tag({ as: El, className, children, ...props }) {
  return (
    <El
      className={cn("rounded-5 bg-purple-300 bg-opacity-60 text-14 font-light leading-18 text-white-100", className)}
      {...props}
    >
      {children}
    </El>
  );
}

Tag.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Tag.defaultProps = {
  as: "div",
  className: null,
};
