import PropTypes from "prop-types";
import Head from "next/head";

const DEFAULT_DESCRIPTION =
  "Discover art you would never usually find using our ground-breaking Artificial Intelligence called DAISY. Upload art into Occupy White Walls and track your stats on KULTURA. Jump into OWW and build your dream gallery for free. OWW is built for artists, gamers, and architects.";

export default function SEOTags({ title, description, renderCanonical, image }) {
  return (
    <Head>
      <title key="title">{title}</title>
      <meta key="og-title" property="og:title" content={title} />
      <meta key="description" name="description" content={description || DEFAULT_DESCRIPTION} />
      <meta key="og-description" property="og:description" content={description || DEFAULT_DESCRIPTION} />
      <meta key="og-type" property="og:type" content="website" />
      <meta
        key="og-image"
        property="og:image"
        content={image ? `${image}?${Date.now()}` : `${window.location.origin}/favicon.png`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      {renderCanonical && (
        <>
          <meta key="og-url" property="og:url" content={window.location.origin + window.location.pathname} />
          <link key="canonical" rel="canonical" href={window.location.origin + window.location.pathname} />
        </>
      )}
    </Head>
  );
}

SEOTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  renderCanonical: PropTypes.bool,
};

SEOTags.defaultProps = {
  title: "KULTURA by DAISY",
  description: null,
  image: null,
  renderCanonical: true,
};
