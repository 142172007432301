import ae from "lib/analytics";
import { types, flow, getRoot } from "mobx-state-tree";
import { Profile } from "store/ProfilesStore";
import { Board } from "../BoardsStore";
import { BoardArtworksList } from "../ArtworksStore";

export const BoardPage = types
  .model("BoardPage", {
    board: types.safeReference(Board),
    artworks: types.safeReference(BoardArtworksList),
    showArtworkSlider: false,
    isEditing: false,
    boardOwner: types.safeReference(Profile),
  })
  .actions(self => ({
    mount: flow(function* mount(boardId) {
      const { boards, artworks } = getRoot(self);
      const board = yield boards.loadBoard(boardId);
      const boardArtworks = artworks.getBoardArtworks(boardId);

      const stuffToLoad = [getRoot(self).profiles.loadProfile(board.owner.id), boardArtworks.init(), board.loadStats()];

      if (!board.isTemp) {
        stuffToLoad.push(board.comments.init());
      }

      const [owner] = yield Promise.all(stuffToLoad);
      yield owner.loadIsFollowed();

      self.boardOwner = owner;

      self.board = board;
      self.artworks = boardArtworks;
    }),
    unmount() {
      self.board = undefined;
      self.artworks = undefined;
      self.showArtworkSlider = false;
      self.isEditing = false;
    },
    toggleArtworkSlider() {
      if (self.showArtworkSlider) {
        ae.boards.closeSlider(self.board.id);
      } else {
        ae.boards.openSlider(self.board.id);
      }

      self.showArtworkSlider = !self.showArtworkSlider;
    },
    toggleEditing() {
      self.isEditing = !self.isEditing;
    },
  }));

export function create() {
  return BoardPage.create();
}
