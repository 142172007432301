import { types, flow, getRoot } from "mobx-state-tree";
import { getGroupedComments } from "api";
import { Board } from "store/BoardsStore";
import { Artwork } from "store/ArtworksStore";
import dayjs from "dayjs";

const ActivityItemType = types.enumeration("ActivityItemType", ["artwork", "board"]);

const ActivityItem = types.model("ActivityItem", {
  type: ActivityItemType,
  data: types.union(types.safeReference(Artwork), types.safeReference(Board)),
});

export const RecentActivity = types
  .model("RecentActivity", {
    items: types.array(ActivityItem),
    isLoaded: false,
  })
  .views(self => ({
    only(type) {
      return self.items.filter(item => item.type === type);
    },
  }))
  .actions(self => ({
    init: flow(function* init() {
      if (self.isLoaded) return;

      const { artworks, boards } = yield getGroupedComments("board,artwork", { amount: 8 });

      self.items = [
        ...getRoot(self)
          .artworks.mapArtworks(artworks)
          .map(artwork => ({ type: "artwork", data: artwork })),
        ...getRoot(self)
          .boards.mapBoards(boards)
          .map(board => ({ type: "board", data: board })),
      ].sort((item1, item2) => dayjs(item2.data.comments.list[0].addedAt) - dayjs(item1.data.comments.list[0].addedAt));

      self.isLoaded = true;
    }),
  }));

export function create() {
  return RecentActivity.create();
}
