import { pick } from "./index";

const avatars = [
  "aphrodite_01.png",
  "aphrodite_02.png",
  "japanese_guy_01.png",
  "japanese_guy_02.png",
  "mona_lisa_01.png",
  "mona_lisa_02.png",
  "nefertiti_01.png",
  "nefertiti_02.png",
  "stoneface_01.png",
  "stoneface_02.png",
  "van_gogh_01.png",
  "van_gogh_02.png",
];

export default function getRandomAvatar() {
  return pick(avatars);
}
