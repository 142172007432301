import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./Toggle.module.css";

export default function Toggle({ id, name, checked, onChange, className, disabled, children, ...props }) {
  const inputId = id || name;

  return (
    <>
      <input
        id={inputId}
        name={name}
        type="checkbox"
        className={cn("visually-hidden", styles.input)}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={inputId} className={cn("flex select-none items-center", className)} {...props}>
        {children}
        <span
          className={cn(
            "translate flex w-[6rem] cursor-pointer rounded-10 border border-transparent p-10 px-5 py-3 duration-150 ease-out",
            styles.toggle,
            children && "ml-10"
          )}
        >
          <span
            className={cn(
              "translate block h-10 w-24 rounded-10 bg-white-100 transition duration-150 ease-out",
              checked && "translate-x-full"
            )}
          />
        </span>
      </label>
    </>
  );
}

Toggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Toggle.defaultProps = {
  id: null,
  className: null,
  disabled: false,
  children: null,
};
