import slugify from "slugify";

export const URL = {
  ROOT: "/",
  GET_ART_CODES: "/get-art-codes",
  UPLOAD_ARTWORK: "/artworks/upload",
  PROFILE: "/profiles/[id]/[[...slug]]",
  USER_PROFILE: "/profile",
  NEW_PROFILE: "/profiles/new",
  ARTWORK: "/artworks/[id]/[[...slug]]",
  COLLECTION: "/collections/[id]/[[...slug]]",
  BOARD: "/boards/[id]/[[...slug]]",
  DISCOVER: "/discover",
  SEARCH: "/search",
  PRIVACY: "/help/privacy/privacy-policy",
  TERMS: "/help/site-faq/upload-terms-and-conditions",
  ARTIST_FAQ: "/help/artist-faq",
  FOR_ARTISTS: "/for-artists",
  CODES_PAYMENT_SUCCESS: "/payment/stripe/success",
  HELP: "/help/[[...slug]]",
  BOARD_INDEX: "/boards",
  LOGIN: "/login",
  SIGN_UP: "/signup",
  PASSWORD_RECOVERY: "/recover",
  BLOGPOST: "/blogs/[blogId]/blogposts/[id]/[[...slug]]",
  PROMO_SIGNUP: "/promo/signup",
  ARTWORK_INDEX: "/artworks",
  POST_EDITOR: "/blogs/edit-post",
  SITE_OVERVIEW: "/site-overview",
};

const getSlug = str => slugify(str, { lower: true, strict: true });

// Todo(ars): I think, these guys want a more generic function
export const getProfileUrl = (id, name = "") => {
  const slug = getSlug(name);
  return `/profiles/${id}${slug ? `/${slug}` : ""}`;
};
export const getArtworkUrl = (id, title = "", params = "") => {
  const slug = getSlug(title);
  return `/artworks/${id}${slug ? `/${slug}${params}` : ""}`;
};
export const getCollectionUrl = (id, name = "") => {
  const slug = getSlug(name);
  return `/collections/${id}${slug ? `/${slug}` : ""}`;
};
export const getBoardUrl = (id, name = "", params = "") => {
  const slug = getSlug(name);
  return `/boards/${id}${slug ? `/${slug}` : ""}${params}`;
};
export const getBlogpostUrl = (blogId, blogpostId, name = "") => {
  const slug = getSlug(name);
  return `/blogs/${blogId}/blogposts/${blogpostId}/${slug ? `${slug}` : ""}`;
};

export const IMAGE_SIZE_PREFIX = {
  THUMBNAIL: "u1v2w0_",
  SMALL: "d1e2f0_",
  MEDIUM: "a1b2c0_",
  LARGE: "x1y2z0_",
};

export const IMAGE_TYPE = {
  ARTIST: "artist",
  ARTWORK: "artwork",
  COLLECTION: "collection",
  BOARD: "board",
};

export const ACCESS_RIGHTS = {
  USER: "USER", // Default authority
  ANONYMOUS: "ANONYMOUS",
  MODERATOR: "MODERATOR", // Comment moderation, etc...
  ADMIN: "ADMIN", // Administrative rights
  MASTER: "MASTER", // Full access to artwork stuff and image upload for any artist
  ECONOMY: "ECONOMY", // Minister of Economy ¯\_(ツ)_/¯
  UPLOADER: "UPLOADER", // Artwork uploading and artist creation
  DEVELOPER: "DEVELOPER",
};

export const SEARCH_TAB = {
  ALL: "all",
  ARTIST: "artists",
  ARTWORK: "artworks",
  COLLECTION: "collections",
  BOARD: "boards",
  BLOGPOST: "blogposts",
};

export const ERROR_CODES = {
  USERNAME_NOT_AVAILABLE: 201,
  EMAIL_NOT_AVAILABLE: 202,

  BOARDS_LIMIT_REACHED: 311,
  BOARD_NOT_FOUND: 440,
  BOARD_ARTWORKS_LIMIT_REACHED: 441,
  BOARD_IS_EMPTY: 442,

  PASSWORD_RECOVERY_SESSION_NOT_FOUND: 220,
  PASSWORD_RECOVERY_INVALID_TOKEN: 221,

  IMAGE_UPLOAD_USED_KEY: 550,
  IMAGE_UPLOAD_KEY_NOT_FOUND: 551,
  IMAGE_UPLOAD_INVALID_KEY: 552,
  IMAGE_UPLOAD_UNREADABLE: 560,
  IMAGE_UPLOAD_INVALID_FORMAT: 561,
  IMAGE_UPLOAD_INVALID_SOURCE: 576,
  IMAGE_UPLOAD_INVALID_SIZE: 577,

  UNAUTHORIZED: 203,
  USER_NOT_OWNER: 310,
  USER_NOT_FOUND: 210,

  INVALID_EMAIL_VERIFICATION_TOKEN: 211,

  ARTWORK_NOT_FOUND: 410,
  ARTWORK_EXISTS: 411,

  PROFILE_NOT_FOUND: 430,
  PROFILE_EXISTS: 431,

  COMMENT_NOT_FOUND: 460,
  COMMENT_ACCESS_DENIED: 461,

  PAYMENT_SESSION_NOT_FOUND: 510,

  DAISY_ERROR_CODE: 700,

  GENERIC_ERROR: 999,
};

export const ERROR_MESSAGES = {
  [ERROR_CODES.USERNAME_NOT_AVAILABLE]: "Username is not available",
  [ERROR_CODES.EMAIL_NOT_AVAILABLE]: "Email is not available",
  [ERROR_CODES.USER_NOT_FOUND]: "Unknown email address",

  [ERROR_CODES.INVALID_EMAIL_VERIFICATION_TOKEN]: "Email verification token is invalid",

  [ERROR_CODES.PASSWORD_RECOVERY_SESSION_NOT_FOUND]: "Password recovery session not found",
  [ERROR_CODES.PASSWORD_RECOVERY_INVALID_TOKEN]: "Invalid password recovery token",

  [ERROR_CODES.IMAGE_UPLOAD_USED_KEY]: "Upload code is already used",
  [ERROR_CODES.IMAGE_UPLOAD_KEY_NOT_FOUND]: "Upload code not found",
  [ERROR_CODES.IMAGE_UPLOAD_INVALID_KEY]: "Upload code is invalid",
  [ERROR_CODES.IMAGE_UPLOAD_UNREADABLE]: "Can't read uploaded image",
  [ERROR_CODES.IMAGE_UPLOAD_INVALID_FORMAT]: "Uploaded image has invalid format",
  [ERROR_CODES.IMAGE_UPLOAD_INVALID_SOURCE]: "Invalid source in image upload",
  [ERROR_CODES.IMAGE_UPLOAD_INVALID_SIZE]: "Invalid image size",

  [ERROR_CODES.UNAUTHORIZED]: "Unauthorized action",
  [ERROR_CODES.USER_NOT_OWNER]: "User is not an owner",
  [ERROR_CODES.USER_NOT_FOUND]: "User not found",

  [ERROR_CODES.ARTWORK_NOT_FOUND]: "Artwork not found",
  [ERROR_CODES.ARTWORK_EXISTS]: "Artwork already exists",

  [ERROR_CODES.PROFILE_NOT_FOUND]: "Profile not found",
  [ERROR_CODES.PROFILE_EXISTS]: "Profile already exists",

  [ERROR_CODES.BOARDS_LIMIT_REACHED]: "Can't publish any more boards",
  [ERROR_CODES.BOARD_NOT_FOUND]: "Board not found",
  [ERROR_CODES.BOARD_ARTWORKS_LIMIT_REACHED]: "Board artworks limit reached",
  [ERROR_CODES.BOARD_IS_EMPTY]: "Board is empty",

  [ERROR_CODES.COMMENT_NOT_FOUND]: "Comment not found",
  [ERROR_CODES.COMMENT_ACCESS_DENIED]: "Can't access the comment",

  [ERROR_CODES.PAYMENT_SESSION_NOT_FOUND]: "Payment session is not found",

  [ERROR_CODES.DAISY_ERROR_CODE]: "DAISY experienced an error suggesting artworks",

  [ERROR_CODES.GENERIC_ERROR]: "Error contacting the server",
  DEFAULT: "Error contacting the server",
};

export const AUTH_MODAL_STATE = {
  LOGIN: 0,
  SIGNUP: 1,
  RECOVER: 2,
  NEW_PASSWORD: 3,
  EMAIL_CONFIRM: 4,
  REGISTER_TO_CONTINUE: 5,
  INVALID_CONFIRM_LINK: 6,
  UNSUBSCRIBE_CONFIRM: 7,
};

export const MIN_ARTWORKS_TO_PUBLISH = 5;
export const MAX_ARTWORKS_PER_BOARD = 50;

export const PROFILE_DASHBOARD_TABS = {
  UPLOADED_ARTWORKS: 0,
  LIKED_ARTWORKS: 1,
};

export const AUTOPLAY_SPEED = {
  NONE: 0,
  DEFAULT: 3000,
  FASTER: 1500,
};

export const START_PRELOAD_INDEX = 10;
export const ESC_BUTTON_KEY_CODE = 27;

export const TEMP_USER_BOARDS_LIMIT = 1;
export const TEMP_USER_BOARD_ARTWORKS_LIMIT = 10;
export const TEMP_USER_LIKES_LIMIT = 50;
export const TEMP_USER_DAISY_VIEWS_LIMIT = 500;

export const NOTIFICATION_TYPES = {
  ARTWORK_COMMENT: "COMMENTS_MY_ARTWORK",
  BOARD_COMMENT: "COMMENTS_MY_BOARD",
  ARTWORK_ADDED_TO_BOARD: "MY_ARTWORK_ADD_ON_BOARD",
  BOARD_ARTWORK_ADDED_TO_BOARD: "MY_BOARD_ARTWORK_ADD_ON_BOARD",
  ARTWORK_BOUGHT: "MY_ARTWORK_BUY_IN_GAME",
  NEW_FOLLOW: "SOMEONE_FOLLOW_ME",
  FOLLOWED_UPLOADED_ARTWORK: "PUBLISHER_ADD_ARTWORK",
  FOLLOWED_FOLLOW: "PUBLISHER_FOLLOWED",
  FOLLOWED_CREATED_BOARD: "PUBLISHER_ADD_BOARD",
  FOLLOWED_CREATED_POST: "PUBLISHER_ADD_POST",
  COMMENT_REPLY: "COMMENTS_MY_COMMENT",
  ARTWORK_LIKE: "LIKE_MY_ARTWORK",
  BOARD_LIKE: "LIKE_MY_BOARD",
  POST_COMMENT: "COMMENTS_MY_POST",
  POST_LIKE: "LIKE_MY_POST",
  ANONYMOUS_NOTIFICATION: "ANONYMOUS_NOTIFICATION",
};

export const RANDOM_ARTWORK_BOARDS_AMOUNT = 4;

export const AVATAR_SIZE_PREFIX = {
  EXTRA_SMALL: "xs_",
  SMALL: "sm_",
  MEDIUM: "md_",
  LARGE: "lg_",
};

export const VOTE_TYPES = {
  LIKE: "LIKE",
  DISLIKE: "DISLIKE",
};

export const EDITOR_STATUS = {
  SAVING: 1,
  SAVED: 2,
};

export const EDITOR_STATUS_MESSAGES = {
  [EDITOR_STATUS.SAVING]: "Saving",
  [EDITOR_STATUS.SAVED]: "Saved",
};

export const BLOGPOST_STATUS = {
  DRAFT: "DRAFT",
  MODERATION: "MODERATION",
  REJECTED: "REJECTED",
  PUBLISHED: "PUBLISHED",
};

export const STATS_TITLES = {
  amountOfLikes: { title: "Likes", icon: "heart", className: "-mt-2 h-16 w-16", description: "Likes" },
  amountOfComments: { title: "Comments", icon: "chat-bubble", className: "h-13 w-13", description: "Comments" },
  amountOfArtworks: {
    title: "Artworks",
    icon: "brush",
    className: "rounded-5 h-16 w-17",
    description: "Artworks in Board",
  },
  amountOfBoards: { title: "Boards", icon: "board", className: "h-14 w-14", description: "Featured on Boards" },
  amountOfViews: { title: "Views", icon: "eye", className: "h-20 w-20", description: "Profile Views" },
  amountOfBuyers: { title: "Acquired", icon: "pin", className: "h-16 w-16", description: "Acquired" },
};

export const BLOGPOST_CARD_TYPES = {
  EDIT: "edit",
  VIEW_FULL: "view_full",
  VIEW_COMPACT: "view_compact",
};

export const BOARD_ELEMENT_TYPES = {
  BOARD: "board",
  COLLECTION: "collection",
};

export const FEATURED_POSTS_TYPES = {
  RECENT_ARTWORKS: "recent_artworks",
  RECENT_BOARDS: "recent_boards",
  DAISY: "daisy",
};

export const COMMENT_VALIDATION_MAX_LENGTH = 500;

export const TAG_ACTIONS = {
  UPVOTE: "UPVOTE",
  DOWNVOTE: "DOWNVOTE",
  REMOVE_VOTE: "REMOVE_VOTE",
  ADD_TAG: "ADD_TAG",
  DELETE_TAG: "DELETE_TAG",
};

export const TAG_VOTE_SOURCES = {
  DAISY_SUGGESTION: "ARTWORK_DAISY_SUGGESTION",
  ARTWORK_UPLOAD: "ARTWORK_UPLOAD",
  ARTWORK_PAGE: "ARTWORK_PAGE",
};

export const PROMO_BANNER_STATE = {
  DISPLAY: "DISPLAY",
  HIDDEN: "HIDDEN",
};

export const PROMO_BANNER_OPT = {
  A: 0,
  B: 1,
};

export const ENTITY_TYPES = {
  ARTWORK: "artwork",
  BOARD: "board",
  COLLECTION: "collection",
  BLOGPOST: "blogpost",
  COMMENT: "comment",
  PROFILE: "profile",
};

export const REG_PROMPT_TRIGGERS = {
  LIKE: "LIKE",
  COMMENT_ADD: "COMMENT_ADD",
  COMMENT_REPLY: "COMMENT_REPLY",
  COMMENT_LIKE: "COMMENT_LIKE",
  BOARD_CREATE: "BOARD_CREATE",
  TAG_ADD: "TAG_ADD",
  TAG_UPVOTE: "TAG_UPVOTE",
  TAG_DOWNVOTE: "TAG_DOWNVOTE",
  SENSITIVE_CHANGE: "SENSITIVE_CHANGE",
  ARTWORK_UPLOAD: "ARTWORK_UPLOAD",
  BLOGPOST_CREATE: "BLOGPOST_CREATE",
  PROFILE_FOLLOW: "PROFILE_FOLLOW",
};

export const LANDING_PAGE_SECTIONS = {
  ARTWORKS: "artworks-cta",
  DAISY: "daisy-cta",
  COMMUNITY: "community-cta",
};

export const ARTWORK_CHAT_MSG = {
  INCOMING: "inc",
  SELF: "self",
  HINT: "hint",
};

export const ARTWORK_CHAT_SCORE = {
  UPVOTE: 1,
  DOWNVOTE: 0,
  REMOVE_VOTE: null,
};

export const ARTWORK_CHAT_FILTER = {
  PASS: "PASS",
  OPEN_AI: "OPENAI",
};

export const IMAGE_SIZES = {
  Artwork: {
    srcSet: "{url}&w=660 660w, {url}&w=1320 1320w, {url}&w=1500 1500w",
    sizes: "(min-width: 1024px) 660px, (min-width: 640px) 330px, calc(100vw - 4rem)",
  },
  FullscreenArtwork: {
    srcSet: "{url}&w=2048 2048w",
    sizes: "2048px",
  },
  ArtworkBoards: {
    srcSet: "{url}&w=220&h=220 220w, {url}&w=440&h=440 440w",
    sizes: "220px",
  },
  ArtworkCollection: {
    srcSet:
      "{url}&w=200 200w, {url}&w=300&h=150 300w, {url}&w=600&h=300 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=900 900w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w",
    sizes: "(min-width: 640px) 300px, calc(100vw - 4rem)",
  },
  DaisySmall: {
    srcSet:
      "{url}&w=500&h=500 500w, {url}&w=600&h=600 600w, {url}&w=700&h=700 700w, {url}&w=800&h=800 800w, {url}&w=900&h=900 900w, {url}&w=1000&h=1000 1000w",
    sizes: "calc(calc(100vh - 58px - 148px - 40px) / 2)",
  },
  DaisyBig: {
    srcSet:
      "{url}&h=500 500w, {url}&h=600 600w, {url}&h=700 700w, {url}&h=800 800w, {url}&h=900 900w, {url}&h=1000 1000w, {url}&h=1100 1100w, {url}&h=1200 1200w, {url}&h=1300 1300w, {url}&h=1400 1400w, {url}&h=1500 1500w",
    sizes: "calc(100vh - 58px - 148px - 40px)",
  },
  SimilarArtwork: {
    srcSet: "{url}&w=260&h=260 260w, {url}&w=520&h=520 520w",
    sizes: "260px",
  },
  SimilarModal: {
    srcSet: `{url}&w=260&h=260 260w, {url}&w=320&h=320 320w, {url}&w=400&h=400 400w, {url}&w=520&h=520 520w, {url}&w=640&h=640 640w, {url}&w=700&h=700 700w, {url}&w=800&h=800 800w`,
    sizes: "(min-width: 1280px) 260px, 320px",
  },
  LiveComments: {
    srcSet: "{url}&w=60&h=60 60w, {url}&w=120&h=120 120w",
    sizes: "60px",
  },
  SearchPage: {
    srcSet:
      "{url}&w=290&h=290 290w, {url}&w=580&h=580 580w, {url}&w=600 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=900 900w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w",
    sizes: "(min-width: 640px) 290px, calc(100vw - 4rem)",
  },
  SearchInput: {
    srcSet: "{url}&w=54&h=54 54w, {url}&w=108&h=108 108w, {url}&w=200&h=200 200w",
    sizes: "54px",
  },
  EditCover: {
    srcSet:
      "{url}&w=260&h=260 260w, {url}&w=300&h=300 300w, {url}&w=400&h=400 400w, {url}&w=520&h=520 520w, {url}&w=600&h=600 600w, {url}&w=700&h=700 520w,",
    sizes: "(min-width: 640px) 260px, calc(100vw - 8rem)",
  },
  EditCoverList: {
    srcSet: "{url}&w=80&h=80 80w, {url}&w=160&h=160 160w",
    sizes: "80px",
  },
  LikedArtworks: {
    srcSet: "{url}&w=150&h=150 150w, {url}&w=300&h=300 300w",
    sizes: "150px",
  },
  BoardInfo: {
    srcSet: "{url}&w=130&h=130 130w, {url}&w=260&h=260 260w",
    sizes: "130px",
  },
  AddToBoard: {
    srcSet: "{url}&w=48&h=42 48w, {url}&w=96&h=84 96w, {url}&w=100&h=100 100w",
    sizes: "48px",
  },
  BoardPageHeader: {
    srcSet: "{url}&w=335&h=200 335w, {url}&w=670&h=400 670w, {url}&w=1000&h=1000 1000w",
    sizes: "335px",
  },
  BoardPageHeaderFull: {
    srcSet: "{url}&w=1340&h=200 1340w, {url}&w=2680&h=400 2680w, {url}&w=1024&h=150 1024w, {url}&w=2048&h=300 2048w",
    sizes: "(min-width: 1280px) 1340px, 1024px",
  },
  ProfileArtworks: {
    srcSet:
      "{url}&w=260&h=260 260w, {url}&w=520&h=520 520w, {url}&w=360&h=360 360w, {url}&w=720&h=720 720w, {url}&w=800 800w, {url}&w=900 900w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w, {url}&w=1300 1300w, {url}&w=1400 1400w",
    sizes: "(min-width: 1280px) 260px, (min-width: 768px) 360px, calc(100vw - 4rem)",
  },
  ProfileBoards: {
    srcSet:
      "{url}&w=220&h=220 220w, {url}&w=440&h=440 440w, {url}&w=360&h=360 360w, {url}&w=720&h=720 720w, {url}&w=800&h=800 800w, {url}&w=900&h=900 900w, {url}&w=1000&h=1000 1000w, {url}&w=1100&h=1100 1100w, {url}&w=1200&h=1200 1200w",
    sizes: "(min-width: 768px) 360px, calc(100vw - 10rem)",
  },
  Grid: {
    srcSet:
      "{url}&w=260&h=260 260w, {url}&w=520&h=520 520w, {url}&w=280&h=280 280w, {url}&w=560&h=560 560w, {url}&w=600&h=600 600w, {url}&w=1200&h=1200 1200w",
    sizes: "(min-width: 768px) 260px, (min-width: 640px) 280px, calc(100vw - 40px)",
  },
  ArtworksPage: {
    srcSet:
      "{url}&w=260&h=260 260w, {url}&w=330&h=330 330w, {url}&w=400&h=400 400w, {url}&w=520&h=520 520w, {url}&w=600&h=600 600w, {url}&w=700&h=700 700w, {url}&w=800&h=800 800w, {url}&w=900&h=900 900w, {url}&w=1000&h=1000 1000w",
    sizes: "(min-width: 1024px) 260px, (min-width: 640px) 330px, calc(100vw - 4rem)",
  },
  BoardsPage: {
    srcSet:
      "{url}&w=260&h=260 260w, {url}&w=330&h=330 330w, {url}&w=400&h=400 400w, {url}&w=520&h=520 520w, {url}&w=600&h=600 600w, {url}&w=700&h=700 700w, {url}&w=800&h=800 800w, {url}&w=900&h=900 900w, {url}&w=1000&h=1000 1000w",
    sizes: "(min-width: 1024px) 260px, (min-width: 640px) 330px, calc(100vw - 4rem)",
  },
  Notification: {
    srcSet: "{url}&w=52&h=52 52w, {url}&w=104&h=104 104w",
    sizes: "52px",
  },
  NotificationLarge: {
    srcSet:
      "{url}&w=300 300w, {url}&w=400 400w, {url}&w=500 500w, {url}&w=600 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=900 900w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w, {url}&w=1300 1300w, {url}&w=1400 1400w",
    sizes: "(min-width: 768px) 300px, calc(100vw - 54px)",
  },
  ArtworkSlider: {
    srcSet:
      "{url}&w=500 500w, {url}&w=600 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=900 900w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w, {url}&w=1300 1300w, {url}&w=1400 1400w",
    sizes: "60vh",
  },
  BlogArtwork: {
    srcSet:
      "{url}&w=600 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=820 820w, {url}&w=1640 1640w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w, {url}&w=1300 1300w, {url}&w=1400 1400w",
    sizes: "(min-width: 768px) 820px, calc(100vw - 40px)",
  },
  DaisyReelArtwork: {
    srcSet:
      "{url}&w=600 600w, {url}&w=700 700w, {url}&w=800 800w, {url}&w=820 820w, {url}&w=1640 1640w, {url}&w=1000 1000w, {url}&w=1100 1100w, {url}&w=1200 1200w, {url}&w=1300 1300w, {url}&w=1400 1400w",
    sizes: "(min-width: 1536px) 1200px, (min-width: 1280px) 1000px, (min-width: 1024px) 600px, calc(100vw - 40px)",
  },
};
