import PropTypes from "prop-types";
import { URL } from "const";
import { useRouter } from "next/router";
import cn from "classnames";

export default function Main({ children }) {
  const router = useRouter();

  return (
    <main
      id="main"
      className={cn("break-words relative flex flex-grow flex-col", router.pathname !== URL.DISCOVER && "flew-grow-0")}
    >
      {children}
    </main>
  );
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
};
