import PropTypes from "prop-types";
import { createContext, useContext, useMemo, forwardRef } from "react";
import Button from "components/basic/Button";

const TabsContext = createContext(null);
TabsContext.displayName = "TabsContext";

function Items({ as: El, className, children }) {
  return <El className={className}>{children}</El>;
}

Items.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
};

Items.defaultProps = {
  as: "div",
  className: null,
};

const Item = forwardRef(function Item({ tab, ...props }, ref) {
  const { setActiveTab } = useContext(TabsContext);
  return <Button ref={ref} onClick={() => setActiveTab(tab)} {...props} />;
});

Item.propTypes = {
  tab: PropTypes.string.isRequired,
};

function Panel({ as: El, tab, children, className }) {
  const { activeTab } = useContext(TabsContext);

  if (activeTab !== tab) return null;

  return <El className={className}>{children}</El>;
}

Panel.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node.isRequired,
  tab: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Panel.defaultProps = {
  as: "div",
  className: null,
};

export default function Tabs({ children, activeTab, onChange }) {
  const tabsContext = useMemo(() => ({ activeTab, setActiveTab: onChange }), [activeTab, onChange]);
  return <TabsContext.Provider value={tabsContext}>{children}</TabsContext.Provider>;
}

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onChange: PropTypes.func,
  activeTab: PropTypes.string,
};

Tabs.defaultProps = {
  activeTab: "",
  onChange: () => {},
};

Tabs.Item = Item;
Tabs.Items = Items;
Tabs.Panel = Panel;
