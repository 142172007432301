import PropTypes from "prop-types";
import cn from "classnames";
import { Formik } from "formik";
import { CSSTransition } from "react-transition-group";
import styles from "./Form.module.css";

export default function Form({ className, children, ...props }) {
  return (
    <Formik validateOnBlur={false} {...props}>
      {({ dirty, isValid, isSubmitting, status, setStatus, handleSubmit }) => {
        const canSubmit = !isSubmitting && !status && dirty && isValid;

        return (
          <form
            className={cn("relative flex w-full flex-col overflow-hidden p-20 md:p-12", className)}
            onSubmit={handleSubmit}
          >
            <CSSTransition
              in={status?.visible}
              timeout={150}
              mountOnEnter
              unmountOnExit
              classNames={{
                enter: styles.statusEnter,
                enterActive: styles.statusEnterActive,
                exit: styles.statusExit,
                exitActive: styles.statusExitActive,
              }}
              onExited={() => setStatus(null)}
            >
              {state => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  role="button"
                  tabIndex="0"
                  className={cn(
                    "absolute inset-0 z-50 flex cursor-default select-none items-center justify-center bg-black-100 bg-opacity-75 p-10 text-18 font-light text-pink-100",
                    state === "exiting" && "pointer-events-none"
                  )}
                  onClick={() => setStatus({ ...status, visible: false })}
                >
                  <span className={styles.statusMessage}>{status?.message}</span>
                </div>
              )}
            </CSSTransition>
            <div className="space-y-10">{children({ canSubmit, isSubmitting })}</div>
          </form>
        );
      }}
    </Formik>
  );
}

Form.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Form.defaultProps = {
  className: null,
};
