import { types } from "mobx-state-tree";

const DEFAULT_WAIT_TIME = 60;

export const TimeoutsStore = types
  .model("Timeouts", {
    emailConfirmationTimeout: 0,
    emailConfirmationTimeoutId: types.maybeNull(types.number),
  })
  .actions(self => ({
    toggleTimer(timeoutName, waitTime) {
      if (!self[`${timeoutName}Id`]) {
        self[timeoutName] = waitTime || DEFAULT_WAIT_TIME;
        self[`${timeoutName}Id`] = setInterval(() => {
          if (self[timeoutName] <= 0) {
            self.resetTimeout(timeoutName);
          } else {
            self.decrementTimeout(timeoutName);
          }
        }, 1000);
      }
    },
    decrementTimeout(timeout) {
      self[timeout] -= 1;
    },
    resetTimeout(timeout) {
      self[timeout] = 0;
      clearInterval(self[`${timeout}Id`]);
      self[`${timeout}Id`] = null;
    },
  }));

export function create() {
  return TimeoutsStore.create({});
}
