import { types, getRoot, flow } from "mobx-state-tree";
import { createPageableList } from "store/utils";
import { Blogpost } from "store/BlogpostsStore";
import { FEATURED_POSTS_TYPES } from "const";
import { Artwork } from "store/ArtworksStore";
import { Board } from "store/BoardsStore";
import shuffle from "lodash/shuffle";

const FEATURED_POSTS = {
  ARTWORKS: { featuringType: FEATURED_POSTS_TYPES.RECENT_ARTWORKS, content: "recent artworks" },
  BOARDS: { featuringType: FEATURED_POSTS_TYPES.RECENT_BOARDS, content: "recent boards" },
  // DAISY: { featuringType: FEATURED_POSTS_TYPES.DAISY, content: "some daisy content" },
};

const FEATURED_POSTS_KEYS = Object.keys(FEATURED_POSTS);

let randomPostKey = null;

const getRandomPostKey = (prev = null) => {
  let random;

  do {
    random = FEATURED_POSTS_KEYS[Math.floor(Math.random() * FEATURED_POSTS_KEYS.length)];
  } while (random === prev);

  return random;
};

const RecentPosts = createPageableList(Blogpost, {
  loadMore(self, pagingOptions) {
    return getRoot(self).blogposts.loadRecentBlogposts(pagingOptions);
  },
  perPage: 5,
});

const FeaturedPost = types.model({
  featuringType: types.string,
  content: types.string,
});

const PinnedPosts = createPageableList(Blogpost, {
  loadMore(self, pagingOptions) {
    return getRoot(self).blogposts.loadPinnedPosts(pagingOptions);
  },
  perPage: 5,
});

export const FeedPage = types
  .model("FeedPage", {
    recentPosts: RecentPosts,
    recentArtworks: types.array(types.safeReference(Artwork)),
    recentArtworksToShow: types.array(types.safeReference(Artwork)),
    recentBoards: types.array(types.safeReference(Board)),
    recentBoardsToShow: types.array(types.safeReference(Board)),
    postsFeed: types.array(types.union(types.safeReference(Blogpost), FeaturedPost)),
    pinnedPosts: PinnedPosts,
  })
  .actions(self => ({
    mount: flow(function* mount() {
      if (self.recentPosts.list.length > 0) return;
      const { artworks, boards } = getRoot(self);
      self.recentPosts = RecentPosts.create();

      yield Promise.all([yield self.recentPosts.init(), yield self.pinnedPosts.init()]);
      self.postsFeed = [...self.recentPosts.list];

      const [recentArtworks, recentBoards] = yield Promise.all([
        artworks.loadRecentArtworks(),
        boards.loadRecentBoards({ perPage: 20 }),
      ]);

      self.recentArtworks = [...recentArtworks.items];
      self.recentArtworksToShow = [...self.recentArtworks];

      self.recentBoards = [...recentBoards.items];
      self.recentBoardsToShow = [...self.recentBoards];

      randomPostKey = getRandomPostKey();
      self.postsFeed.splice(2, 0, FEATURED_POSTS[randomPostKey]); // positioning first feat. post closer to feed start
    }),
    loadFeedWithFeatured: flow(function* loadFeedWithFeatured() {
      const paginationItems = yield self.recentPosts.loadMoreItems();
      if (paginationItems) {
        paginationItems.forEach(item => self.postsFeed.push(item));
        if (self.recentPosts.haveMorePages) {
          randomPostKey = getRandomPostKey(randomPostKey);
          self.postsFeed.push(FEATURED_POSTS[randomPostKey]);
        }
      }
    }),
    getRecentArtworks(amount) {
      if (self.recentArtworksToShow.length === 0) {
        const shuffledArtworks = shuffle(self.recentArtworks);
        self.recentArtworksToShow = [...shuffledArtworks];
      }

      const artworksChunk = self.recentArtworksToShow.slice(0, amount);
      self.recentArtworksToShow = self.recentArtworksToShow.slice(amount);

      return artworksChunk;
    },
    getRecentBoards(amount) {
      if (self.recentBoardsToShow.length === 0) {
        const shuffledBoards = shuffle(self.recentBoards);
        self.recentBoardsToShow = [...shuffledBoards];
      }

      const boardsChunk = self.recentBoardsToShow.slice(0, amount);
      self.recentBoardsToShow = self.recentBoardsToShow.slice(amount);

      return boardsChunk;
    },
    addNewPost(post) {
      if (self.recentPosts.list.find(recentPost => recentPost.id === post.id)) return;

      self.recentPosts.addItem(post, { force: true, placement: "start" });
      self.postsFeed.unshift(post);
    },
  }));

export function create() {
  return FeedPage.create({ recentPosts: {}, pinnedPosts: {} });
}
