import { getAvatarFilePath } from "api";
import PropTypes from "prop-types";
import { useState } from "react";
import cn from "classnames";
import { AVATAR_SIZE_PREFIX } from "const";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { Image } from "./basic";
import { Placeholder } from "./placeholders";

function Avatar({ profile, filename, size, borderColor, scale, className, width, height }) {
  const [isLoaded, setIsLoaded] = useState(!profile?.avatarFilename);

  const { currentUserProfile } = useStore("/profiles");

  const getAvatarSrc = () => {
    if (filename) return getAvatarFilePath(filename);

    const isCurrentUserProfile = currentUserProfile?.id === profile?.id;

    if (!isCurrentUserProfile) {
      if (profile?.localAvatar) return profile.localAvatar;

      return profile?.avatarFilename ? getAvatarFilePath(profile?.avatarFilename) : "/img/no-image.svg";
    }

    if (currentUserProfile?.localAvatar) return currentUserProfile.localAvatar;

    return currentUserProfile?.avatarFilename
      ? getAvatarFilePath(currentUserProfile?.avatarFilename)
      : "/img/no-image.svg";
  };

  return (
    <div
      className={cn(
        "relative flex-shrink-0 transform overflow-hidden rounded-full",
        borderColor,
        !width && !height && size === AVATAR_SIZE_PREFIX.LARGE && "h-[14rem] w-[14rem] border-[1rem]",
        !width && !height && size === AVATAR_SIZE_PREFIX.MEDIUM && "h-[12.8rem] w-[12.8rem] border-[.5rem]",
        !width && !height && size === AVATAR_SIZE_PREFIX.SMALL && "h-[4rem] w-[4rem] border-[0.2rem]",
        !width && !height && size === AVATAR_SIZE_PREFIX.EXTRA_SMALL && "h-[3.3rem] w-[3.3rem] border-[0.1rem]",
        width && height && size && "border-[0.5rem]",
        scale && `scale-${scale}`,
        width && `w-${width}`,
        height && `h-${height}`,
        className
      )}
    >
      <Image
        src={getAvatarSrc()}
        alt={`${profile.name}'s avatar`}
        styles={{
          container: "w-full h-full",
          image: "transition duration-150 ease-out opacity-0",
        }}
        onLoad={e => {
          e.target.classList.remove("opacity-0");
          setIsLoaded(true);
        }}
      />
      {!isLoaded && <Placeholder height={140} width={140} animate className="absolute inset-0" />}
    </div>
  );
}

Avatar.propTypes = {
  profile: PropTypes.shape({
    avatarFilename: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    localAvatar: PropTypes.string,
  }),
  filename: PropTypes.string,
  size: PropTypes.string,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  scale: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

Avatar.defaultProps = {
  size: "lg",
  borderColor: "border-purple-300",
  profile: { name: "User", id: null, localAvatar: null },
  className: null,
  scale: null,
  width: null,
  height: null,
  filename: null,
};

export default observer(Avatar);
