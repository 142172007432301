/* eslint-disable import/no-cycle */
import { types, flow, getRoot } from "mobx-state-tree";
import { getCollectionById, getCollections, getCollectionStats } from "api";
import { values } from "mobx";
import { searchAll } from "api/search";
import { Artwork } from "./ArtworksStore";
import { createLikesStats } from "./LikesStore";

const CollectionStats = types
  .model("CollectionStats", {
    amountOfArtworks: 0,
    amountOfBuyers: 0,
    amountOfComments: 0,
    amountOfViews: 0,
    amountOfWishlists: 0,
    amountOfLikes: 0,
    likesToArtworks: 0,
    totalViews: 0,
  })
  .views(self => ({
    get short() {
      const { amountOfArtworks, amountOfComments, amountOfBuyers } = self;
      return { amountOfArtworks, amountOfComments, amountOfBuyers };
    },
  }))
  .actions(self => ({
    incrementLikes() {
      self.amountOfLikes += 1;
    },
    decrementLikes() {
      self.amountOfLikes -= 1;
    },
  }));

export const ArtworkCollection = types
  .model("ArtworkCollection", {
    id: types.identifierNumber,
    name: types.string,
    url: "",
    description: "",
    previewArtwork: types.safeReference(types.late(() => Artwork)),
    cover: types.safeReference(types.late(() => Artwork)),
    coverFocus: 50, // Y offset in percent for collection covers
    stats: types.maybeNull(CollectionStats),
    youtubeVideoUrl: "",
    likes: types.maybeNull(createLikesStats()),
    createdAt: types.maybeNull(types.string),
  })
  .actions(self => ({
    loadStats: flow(function* loadStats() {
      if (self.stats) return;
      self.stats = yield getCollectionStats(self.id);
    }),
    addPreviewArtworkRef(ref) {
      self.previewArtwork = ref;
    },
    addCoverArtworkRef(ref) {
      self.cover = ref;
    },
  }));

export const CollectionsStore = types
  .model("CollectionsStore", {
    list: types.map(ArtworkCollection),
  })
  .actions(self => ({
    addFromSnapshot(collectionData) {
      return self.mapCollection(collectionData);
    },
    mapCollection({ previewArtwork, cover, ...collectionData }) {
      let collection;

      if (self.list.has(collectionData.id)) {
        collection = self.list.get(collectionData.id);
        const mergedCollection = { ...collection, ...collectionData };
        self.list.set(collection.id, mergedCollection);
      } else {
        collection = self.list.put(collectionData);
      }

      if (previewArtwork) {
        collection.addPreviewArtworkRef(getRoot(self).artworks.mapArtwork(previewArtwork));
      }

      if (cover) {
        collection.coverFocus = cover.focus;
        collection.addCoverArtworkRef(getRoot(self).artworks.mapArtwork(cover));
      }

      return collection;
    },
    mapCollections(collectionsData) {
      return collectionsData.map(self.mapCollection);
    },
    loadCollection: flow(function* loadCollection(collectionId) {
      if (self.list.has(collectionId)) return self.list.get(collectionId);
      const collectionData = yield getCollectionById(collectionId);
      return self.mapCollection(collectionData);
    }),
    loadCollections: flow(function* loadCollections() {
      const { collections } = yield getCollections();
      return collections.map(self.mapCollection);
    }),
    search: flow(function* search(query, pageOptions) {
      const { searchResult, total } = yield searchAll(query, {
        ...pageOptions,
        types: ["collection"],
      });
      const collections = searchResult.collection.items.map(collectionData => self.mapCollection(collectionData));

      return { collections, total };
    }),
    resetUserLikes() {
      values(self.list).forEach(collection => {
        if (collection?.likes?.currentUserValue) {
          collection.likes.updateCurrentValue(null);
        }
      });
    },
  }));

export function create() {
  return CollectionsStore.create();
}
