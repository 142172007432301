import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { useState, useContext, useMemo, useEffect, useCallback, forwardRef, createContext, cloneElement } from "react";
import useRouterEvents from "hooks/useRouterEvents";
import { usePopper } from "react-popper";

import { CSSTransition } from "react-transition-group";

import BasicButton from "components/basic/Button";
import popupStyles from "./index.module.css";

const PopupContext = createContext(null);
PopupContext.displayName = "PopupContext";

export function usePopup() {
  return useContext(PopupContext);
}

export const Button = forwardRef(function Button({ as: El, children, popupClick, ...props }, ref) {
  const context = useContext(PopupContext);
  return typeof children === "function" ? (
    cloneElement(children(context), { ...props, ref })
  ) : (
    <El ref={ref} {...props} onClick={popupClick || context.toggle}>
      {children}
    </El>
  );
});

Button.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  popupClick: PropTypes.func,
};

Button.defaultProps = {
  children: null,
  as: BasicButton,
  popupClick: null,
};

const Content = forwardRef(function Content(
  { children, className, styles, attributes, update, mountTarget = null },
  ref
) {
  const { isOpen, close } = useContext(PopupContext);
  useRouterEvents("routeChangeStart", close);

  return createPortal(
    <div className={className} ref={ref} style={styles.popper} {...attributes.popper}>
      <CSSTransition
        in={isOpen}
        timeout={150}
        mountOnEnter
        unmountOnExit
        onEntering={update}
        classNames={{
          enter: popupStyles.popupEnter,
          enterActive: popupStyles.popupEnterActive,
          exit: popupStyles.popupExit,
          exitActive: popupStyles.popupExitActive,
        }}
      >
        {children}
      </CSSTransition>
    </div>,
    mountTarget || document.body
  );
});

Content.propTypes = {
  styles: PropTypes.shape({
    popper: PropTypes.shape({}),
  }),
  attributes: PropTypes.shape({
    popper: PropTypes.shape({}),
  }),
};

Content.defaultProps = {
  styles: {},
  attributes: {},
};

export default function Popup({
  children: passedChildren,
  popperOptions,
  onOpen,
  onOpened,
  onClose,
  onClosed,
  mountTarget,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [refEl, setRefEl] = useState();
  const [popperEl, setPopperEl] = useState();

  const close = useCallback(
    cb => {
      setIsOpen(false);
      if (onClose) onClose();
      setTimeout(() => {
        setIsRendering(false);
        if (typeof cb === "function") cb();
        if (onClosed) onClosed();
      }, 150);
    },
    [onClose, onClosed]
  );

  const open = useCallback(
    cb => {
      setIsRendering(true);
      setTimeout(() => {
        setIsOpen(true);
        if (onOpen) onOpen();
        if (typeof cb === "function") cb();
      }, 0);

      if (onOpened) {
        setTimeout(onOpened, 150);
      }
    },
    [onOpen, onOpened]
  );

  const toggle = useCallback(
    () =>
      new Promise(resolve => {
        if (isRendering) {
          close(resolve);
        } else {
          open(resolve);
        }
      }),
    [open, close, isRendering]
  );

  useEffect(() => {
    if (!isRendering) return () => {};

    function handleKeyDown(e) {
      if (e.key === "Escape") {
        toggle();
      }
    }

    function handleMouseDown(e) {
      const path = e.composedPath();

      if (path.includes(popperEl) || path.includes(refEl)) {
        return;
      }

      toggle();
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isRendering, refEl, popperEl, toggle]);

  const { update, ...popper } = usePopper(refEl, popperEl, popperOptions);

  const children = typeof passedChildren === "function" ? passedChildren({ isOpen, toggle, update }) : passedChildren;

  const button = cloneElement(
    children.find(el => el.type === Button),
    { ref: setRefEl }
  );
  const content = cloneElement(
    children.find(el => el.type === Content),
    {
      ref: setPopperEl,
      isOpen,
      update,
      mountTarget,
      ...popper,
    }
  );

  const popupContext = useMemo(() => ({ isOpen, toggle, close, open }), [isOpen, toggle, close, open]);

  return (
    <PopupContext.Provider value={popupContext}>
      {button}
      {isRendering && content}
    </PopupContext.Provider>
  );
}

Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  popperOptions: PropTypes.shape({}),
  onOpen: PropTypes.func,
  onOpened: PropTypes.func,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  mountTarget: PropTypes.shape({}),
};

Popup.defaultProps = {
  popperOptions: {},
  onOpen: null,
  onOpened: null,
  onClose: null,
  onClosed: null,
  mountTarget: null,
};

Popup.Button = Button;
Popup.Content = Content;
