import { addEvent } from "@stikipixels/analytics-browser";

export default {
  create({ id, name }) {
    addEvent({ type: "boards.create", payload: { id, name } });
  },
  delete({ id, name }) {
    addEvent({ type: "boards.delete", payload: { id, name } });
  },
  publish(boardId) {
    addEvent({ type: "boards.publish", payload: { boardId } });
  },
  unpublish(boardId) {
    addEvent({ type: "boards.unpublish", payload: { boardId } });
  },
  update(boardId, board, changes) {
    const changedFields = [];
    Object.keys(changes).forEach(field => {
      if (board[field] !== changes[field]) {
        changedFields.push(field);
      }
    });

    addEvent({ type: "boards.update", payload: { boardId, changedFields } });
  },
  updateCover(boardId, from, to) {
    addEvent({ type: "boards.update-cover", payload: { boardId, from, to } });
  },
  createNote(boardId, artworkId) {
    addEvent({ type: "boards.create-note", payload: { boardId, artworkId } });
  },
  deleteNote(boardId, artworkId) {
    addEvent({ type: "boards.delete-note", payload: { boardId, artworkId } });
  },
  updateNote(boardId, artworkId) {
    addEvent({ type: "boards.update-note", payload: { boardId, artworkId } });
  },
  openSlider(boardId) {
    addEvent({ type: "boards.open-slider", payload: { boardId } });
  },
  closeSlider(boardId) {
    addEvent({ type: "boards.close-slider", payload: { boardId } });
  },
};
