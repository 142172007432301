import { types } from "mobx-state-tree";

export const UserProfilePage = types
  .model("UserProfilePage", {
    galleryTab: types.maybeNull(types.number),
  })
  .actions(self => ({
    setGalleryTab(tab) {
      self.galleryTab = tab;
    },
  }));

export function create() {
  return UserProfilePage.create();
}
