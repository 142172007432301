import { types } from "mobx-state-tree";

export const HelpPage = types
  .model({
    title: "",
  })
  .actions(self => ({
    setTitle(title) {
      self.title = title;
    },
  }));

export function create() {
  return HelpPage.create();
}
