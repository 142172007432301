import { types, flow, getRoot } from "mobx-state-tree";
import { Profile } from "store/ProfilesStore";
import { Artwork } from "store/ArtworksStore";

export const TrendingArtists = types
  .model("TrendingArtists", {
    list: types.array(types.safeReference(Profile)),
    selectedArtist: types.safeReference(Profile),
    artworks: types.map(types.array(types.safeReference(Artwork))),
    isLoaded: false,
  })
  .views(self => ({
    get selectedArtistArtworks() {
      const { selectedArtist, artworks } = self;
      if (!(selectedArtist && artworks.has(selectedArtist.id))) return [];

      return self.artworks.get(self.selectedArtist.id);
    },
  }))
  .actions(self => ({
    init: flow(function* init() {
      if (self.isLoaded) return;

      self.list = yield getRoot(self).profiles.loadPopularProfiles();
      self.selectedArtist = self.list[0];
      self.loadArtworks();
      self.isLoaded = true;
    }),
    selectArtist(artist) {
      self.selectedArtist = artist;
      self.loadArtworks();
    },
    loadArtworks: flow(function* loadArtworks() {
      if (self.artworks.has(self.selectedArtist.id)) return;

      const { items } = yield getRoot(self).artworks.loadArtistArtworks(self.selectedArtist.id, { PSize: 5 });
      self.artworks.set(self.selectedArtist.id, items);
    }),
  }));

export function create() {
  return TrendingArtists.create();
}
