import { types, flow, getRoot } from "mobx-state-tree";
import { Artwork } from "store/ArtworksStore";

import ae from "lib/analytics";

export const DaisyPage = types
  .model("DaisyPage", {
    suggestions: types.array(types.safeReference(Artwork)),
    isLoading: false,
  })
  .actions(self => ({
    mount: flow(function* mount() {
      if (self.suggestions.length) return;
      yield self.loadSuggestions();
    }),
    loadSuggestions: flow(function* loadSuggestions() {
      self.isLoading = true;
      const { user } = getRoot(self);
      const loadFn = user.isLoggedIn
        ? getRoot(self).artworks.loadDaisySuggestions
        : () =>
            getRoot(self)
              .artworks.tempLoadDaisySuggestions()
              .then(items => {
                items.forEach(item => user.userData.addView(item.id));
                return items;
              });
      const artworks = yield loadFn();
      self.isLoading = false;

      ae.daisy.recommend(artworks.length);

      self.suggestions.push(...artworks);
    }),
    resetSuggestions() {
      self.suggestions = [];
    },
  }));

export function create() {
  return DaisyPage.create({ suggestionsList: [] });
}
