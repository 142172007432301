import { addEvent } from "@stikipixels/analytics-browser";

export default {
  onboarding: {
    bannerShow(option) {
      addEvent({ type: "promo.onboarding.banner-show", payload: { option, path: window.location.pathname } });
    },
    bannerClick(option) {
      addEvent({ type: "promo.onboarding.banner-click", payload: { option, path: window.location.pathname } });
    },
    bannerClose(option) {
      addEvent({ type: "promo.onboarding.banner-close", payload: { option, path: window.location.pathname } });
    },
    landingPageClick(clickedBtn) {
      addEvent({ type: "promo.onboarding.landing-page-click", payload: { cta: clickedBtn } });
    },
  },
};
