import PropTypes from "prop-types";

export default function Icon({ icon, iconSet, ...props }) {
  return (
    <svg {...props}>
      <use xlinkHref={`/${iconSet}.svg#${icon}`} />
    </svg>
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconSet: PropTypes.string,
};

Icon.defaultProps = {
  iconSet: "icons",
};
