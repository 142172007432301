/* eslint-disable react/prop-types */
import cn from "classnames";
import { useRouter } from "next/router";
import { observer } from "mobx-react-lite";
import Link from "components/basic/Link";
import { URL, AUTH_MODAL_STATE, getProfileUrl } from "const";
import { Icon, Button, Image } from "components/basic";
import Popup from "components/popups";
import { useStore } from "store";
import { useMemo } from "react";

const createPopupOptions = {
  placement: "bottom-start",
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        padding: 10,
      },
    },
    {
      name: "offset",
      options: {
        offset({ popper }) {
          return [-popper.width * 0.28, 15];
        },
      },
    },
  ],
};

function PageNav() {
  const router = useRouter();
  const { user, pages, ui, profiles } = useStore();
  const { currentUserProfile } = profiles;

  const profileUrl = useMemo(() => {
    if (!currentUserProfile) return "";
    return getProfileUrl(currentUserProfile.id, currentUserProfile.name);
  }, [currentUserProfile]);

  return (
    <div className="fixed bottom-0 left-0 z-20 h-55 w-full bg-white-100 shadow-page-title md:relative md:z-10 md:rounded-b-6">
      <div className="mx-auto flex h-full w-full max-w-1340 items-center justify-between px-20">
        <ul className="flex h-full w-full items-center justify-between text-15 font-light text-gray-1200 md:w-auto md:gap-70 lg:gap-80">
          <li
            className={cn(
              "flex h-full flex-grow items-center justify-center md:flex-grow-0",
              router.pathname === URL.ROOT && "md:border-b-4 md:border-new-blue-100"
            )}
          >
            <Link
              className={cn(
                "flex h-full w-full items-center justify-center hover:text-new-blue-100",
                router.pathname === URL.ROOT && "text-new-blue-100 md:mt-4"
              )}
              href={URL.ROOT}
              beforeNav={() => pages.feedPage.mount()}
            >
              <Icon icon="home" className="hidden h-26 w-26 flex-shrink-0 fill-current md:block" />
              <span className="md:ml-7 md:inline-flex">Home</span>
            </Link>
          </li>
          <li
            className={cn(
              "flex h-full flex-grow items-center justify-center md:flex-grow-0",
              router.pathname === URL.DISCOVER && "md:border-b-4 md:border-new-blue-100"
            )}
          >
            <Link
              className={cn(
                "group relative flex h-full  w-full min-w-[8.4rem] items-center justify-center hover:text-new-blue-100",
                router.pathname === URL.DISCOVER && "text-new-blue-100 md:mt-4"
              )}
              href={URL.DISCOVER}
            >
              <span className="font-medium text-white-100">Discover</span>
              <div className="absolute top-[50%] z-[-1] translate-y-[-50%]">
                <div />
                <Image
                  src="/img/daisy-btn-bg.webp"
                  alt="DAISY"
                  height={30}
                  width={84}
                  className="h-[3rem] w-[8.4rem] animate-stroke"
                />
              </div>
            </Link>
          </li>
          <li
            className={cn(
              "flex h-full flex-grow items-center justify-center md:flex-grow-0",
              router.pathname === URL.ARTWORK_INDEX && "md:border-b-4 md:border-new-blue-100"
            )}
          >
            <Link
              className={cn(
                "flex h-full w-full items-center justify-center hover:text-new-blue-100",
                router.pathname === URL.ARTWORK_INDEX && "text-new-blue-100 md:mt-4"
              )}
              beforeNav={() => pages.artworkIndexPage.mount()}
              href={URL.ARTWORK_INDEX}
            >
              <Icon icon="brush" className="hidden h-20 w-20 flex-shrink-0 fill-current md:block" />
              <span className="md:ml-7 md:inline-flex">Artworks</span>
            </Link>
          </li>
          <li
            className={cn(
              "flex h-full flex-grow items-center justify-center md:flex-grow-0",
              router.pathname === URL.BOARD_INDEX && "md:border-b-4 md:border-new-blue-100"
            )}
          >
            <Link
              className={cn(
                "flex h-full w-full items-center justify-center hover:text-new-blue-100",
                router.pathname === URL.BOARD_INDEX && "text-new-blue-100 md:mt-4"
              )}
              beforeNav={() => pages.boardIndexPage.mount()}
              href={URL.BOARD_INDEX}
            >
              <Icon icon="board" className="hidden h-20 w-20 flex-shrink-0 fill-current md:block" />
              <span className="md:ml-7 md:inline-flex">Boards</span>
            </Link>
          </li>
        </ul>
        {user.isLoggedIn ? (
          <Popup popperOptions={createPopupOptions}>
            {({ isOpen }) => [
              <Popup.Button
                className={cn(
                  "hidden items-center rounded-6 bg-new-blue-100 px-20 py-10 text-14 font-light text-white-100 hover:bg-new-blue-100/60 md:flex",
                  isOpen && "bg-new-blue-100/60"
                )}
              >
                <Icon icon="plus" className="-mt-1 mr-10 h-12 w-12 fill-current" />
                <span>Create</span>
              </Popup.Button>,
              <Popup.Content>
                <div className="flex flex-col rounded-6 border border-white-900 bg-white-100 shadow-sm">
                  <Link
                    href={URL.POST_EDITOR}
                    className="flex items-center rounded-t-6 px-30 py-5 hover:bg-new-blue-100/60 hover:text-white-100"
                  >
                    <Icon icon="pencil" className="mr-10 h-15 w-15" />
                    Post
                  </Link>
                  <Link
                    href={URL.UPLOAD_ARTWORK}
                    className="flex items-center px-30 py-5 hover:bg-new-blue-100/60 hover:text-white-100"
                  >
                    <Icon icon="brush" className="mr-10 h-15 w-15" />
                    Artwork
                  </Link>
                  <Link
                    href={`${profileUrl}?create=board`}
                    className="flex items-center rounded-b-6 px-30 py-5 hover:bg-new-blue-100/60 hover:text-white-100"
                    rel="canonical"
                  >
                    <Icon icon="board" className="mr-10 h-15 w-15" />
                    Board
                  </Link>
                </div>
              </Popup.Content>,
            ]}
          </Popup>
        ) : (
          <Button
            onClick={() => {
              ui.authModal.setState(AUTH_MODAL_STATE.SIGNUP);
              ui.authModal.setOpen(true);
            }}
            className="hidden items-center rounded-6 bg-new-blue-100 px-20 py-10 text-14 font-light text-white-100 hover:bg-new-blue-100/60 md:flex"
          >
            <Icon icon="plus" className="-mt-1 mr-10 h-12 w-12 fill-current" />
            <span>Create</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default observer(PageNav);
