import { useStore } from "store";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { useState, useEffect } from "react";
import ae from "lib/analytics";

import ControlledModal from "components/Modal/ControlledModal";
import { Icon } from "components/basic";
import Button from "components/Button";

function HideSensitiveModal() {
  const { sensitiveContentModal } = useStore("/ui");
  const { userData, changeSettings } = useStore("/user");

  const [hideSensitive, setHideSensitive] = useState(userData.settings.hideSensitiveContent);

  useEffect(() => {
    setHideSensitive(userData.settings.hideSensitiveContent);
  }, [userData.settings.hideSensitiveContent]);

  return (
    <ControlledModal
      color="white"
      isOpen={sensitiveContentModal.isOpen}
      setOpen={val => sensitiveContentModal.setOpen(val)}
      className="mt-auto w-full rounded-none rounded-t-6 sm:mb-auto sm:max-w-426 sm:rounded-b-6 sm:text-14"
      isClosable={sensitiveContentModal.isClosable}
    >
      <div className="flex w-full flex-col items-center space-y-30 px-25 py-30 text-new-gray-800 md:p-40">
        <Icon icon="alert" className="h-40 w-40 fill-current" />
        <div className="space-y-10 text-center text-15 leading-tight">
          <p>We hide sensitive content on our website by default. You can change it at any time if you want.</p>
          <p>By opting to show sensitive content you are confirming that your age is 18+</p>
        </div>
        <div className="space-y-10">
          <label className="flex items-center">
            <input type="radio" className="hidden" onClick={() => setHideSensitive(true)} />
            <div
              className={cn(
                "mr-10 flex h-15 w-15 items-center justify-center rounded-full border-2",
                hideSensitive ? "border-new-blue-800" : "border-new-gray-800"
              )}
            >
              <div className={cn(hideSensitive ? "h-7 w-7 rounded-full bg-new-blue-700" : "hidden")} />
            </div>
            <span>Hide sensitive content</span>
          </label>

          <label className="flex items-center">
            <input type="radio" className="hidden" onClick={() => setHideSensitive(false)} />
            <div
              className={cn(
                "mr-10 flex h-15 w-15 items-center justify-center rounded-full border-2",
                hideSensitive ? "border-new-gray-800" : "border-new-blue-800"
              )}
            >
              <div className={cn(hideSensitive ? "hidden" : "h-7 w-7 rounded-full bg-new-blue-700")} />
            </div>
            <span>Show sensitive content</span>
          </label>
        </div>
        <Button
          async
          color="blue"
          onClick={async () => {
            if (userData.settings.hideSensitiveContent !== hideSensitive) {
              await changeSettings({ hideSensitiveContent: hideSensitive });
              ae.sensitive.confirmChange(userData.settings.hideSensitiveContent);
            }

            sensitiveContentModal.setOpen(false);
          }}
        >
          Confirm
        </Button>
      </div>
    </ControlledModal>
  );
}

export default observer(HideSensitiveModal);
