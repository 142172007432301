import { useState, useEffect, useRef, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { AUTH_MODAL_STATE } from "const";
import ControlledModal from "components/Modal/ControlledModal";

import LoginForm from "./LoginForm";
// eslint-disable-next-line import/no-cycle
import SignupForm from "./SignupForm";
import RecoveryStartForm from "./RecoveryStartForm";
import RecoveryEndForm from "./RecoveryEndForm";
import EmailConfirmationMessage from "./EmailConfirmationMessage";
import RegisterToContinueMessage from "./RegisterToContinueMessage";
import EmailUnsubscribeMessage from "./EmailUnsubscribeMessage";
import styles from "./index.module.css";
import InvalidConfirmationLink from "./InvalidConfirmationLink";

function AuthModal() {
  const { setOverlayBg, authModal } = useStore("/ui");
  const [height, setHeight] = useState(0);

  const formRef = useRef(null);

  useEffect(() => {
    if (!formRef.current) return () => {};

    const resizeObserver = new ResizeObserver(() => {
      if (formRef.current) {
        setHeight(formRef.current.clientHeight + 76);
      }
    });

    resizeObserver.observe(formRef.current);

    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current]);

  useEffect(() => {
    setOverlayBg("bg-gradient-2");
  }, [setOverlayBg]);

  const gotoSignup = useCallback(
    function gotoSignup() {
      authModal.setState(AUTH_MODAL_STATE.SIGNUP);
    },
    [authModal]
  );
  const gotoRecovery = useCallback(
    function gotoRecovery() {
      authModal.setState(AUTH_MODAL_STATE.RECOVER);
    },
    [authModal]
  );
  const gotoLogin = useCallback(
    function gotoLogin() {
      authModal.setState(AUTH_MODAL_STATE.LOGIN);
    },
    [authModal]
  );

  let form;

  switch (authModal.state) {
    case AUTH_MODAL_STATE.SIGNUP:
      authModal.setTitle("Create an account");
      form = (
        <SignupForm
          gotoLogin={gotoLogin}
          beforeSubmit={() => authModal.setIsClosable(false)}
          afterSubmit={() => authModal.setIsClosable(true)}
          submitSuccess={() => authModal.setTitle("User account created")}
        />
      );
      break;
    case AUTH_MODAL_STATE.RECOVER:
      authModal.setTitle("Password recovery");
      form = <RecoveryStartForm gotoLogin={gotoLogin} />;
      break;
    case AUTH_MODAL_STATE.NEW_PASSWORD:
      authModal.setTitle("Password recovery");
      form = <RecoveryEndForm />;
      break;
    case AUTH_MODAL_STATE.EMAIL_CONFIRM:
      authModal.setTitle("Email confirmation");
      form = <EmailConfirmationMessage />;
      break;
    case AUTH_MODAL_STATE.UNSUBSCRIBE_CONFIRM:
      authModal.setTitle("Unsubscribe");
      form = <EmailUnsubscribeMessage />;
      break;
    case AUTH_MODAL_STATE.REGISTER_TO_CONTINUE:
      authModal.setTitle("Action request");
      form = <RegisterToContinueMessage />;
      break;
    case AUTH_MODAL_STATE.INVALID_CONFIRM_LINK:
      authModal.setTitle("Invalid confirmation link");
      form = <InvalidConfirmationLink />;
      break;
    case AUTH_MODAL_STATE.LOGIN:
    default:
      authModal.setTitle("Login to KULTURA");

      form = (
        <LoginForm
          gotoSignup={gotoSignup}
          gotoRecovery={gotoRecovery}
          beforeSubmit={() => authModal.setIsClosable(false)}
          submitSuccess={() => authModal.setOpen(false)}
          afterSubmit={() => authModal.setIsClosable(true)}
        />
      );
  }

  return (
    <ControlledModal
      color="white"
      title={authModal.title}
      isOpen={authModal.isOpen}
      setOpen={val => authModal.setOpen(val)}
      className={`${styles.modal} w-full max-w-324 md:text-14`}
      style={{ height: height ? `${height}px` : null }}
      onEntering={() => setHeight(formRef.current.clientHeight + 76)}
      onExited={() => {
        authModal.setState(AUTH_MODAL_STATE.LOGIN);
        authModal.setIsClosable(true);
        setHeight(0);
      }}
      isClosable={authModal.isClosable}
    >
      <div ref={formRef} className="w-full">
        {form}
      </div>
    </ControlledModal>
  );
}

export default observer(AuthModal);
