import PropTypes from "prop-types";
import cn from "classnames";
import styles from "./Placeholder.module.css";

export default function Placeholder({ children, className, color, width: w, height: h, animate, delay }) {
  const width = typeof w === "number" ? `${w}px` : w;
  const height = typeof h === "number" ? `${h}px` : h;
  const animationDelay = typeof delay === "number" ? `${delay}ms` : delay;

  return (
    <div className={cn(color, className, animate && styles.loading)} style={{ width, height, animationDelay }}>
      {children}
    </div>
  );
}

Placeholder.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animate: PropTypes.bool,
  delay: PropTypes.number,
};

Placeholder.defaultProps = {
  color: "bg-gray-500",
  className: null,
  children: null,
  width: null,
  height: null,
  animate: false,
  delay: null,
};
