import PropTypes from "prop-types";
import cn from "classnames";
import { useEffect, useMemo, useCallback, useRef, createContext, useContext } from "react";
// eslint-disable-next-line import/no-cycle
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

import styles from "./_Modal.module.css";

const ModalContext = createContext(null);
ModalContext.displayName = "ModalContext";

export function useModal() {
  return useContext(ModalContext);
}

let triggerEl = null;

export function setTrigger(trigger) {
  triggerEl = trigger;
}

function ControlledModal({
  isOpen,
  isClosable,
  setOpen,
  title,
  className,
  renderTrigger,
  onEntering,
  onExited,
  children,
  color,
  ...props
}) {
  const modalRef = useRef(null);

  const ui = useStore("/ui");

  useEffect(() => {
    if (!isOpen) return () => {};

    function handleKeyDown(e) {
      if (!isClosable) return;

      if (e.key === "Escape") {
        setOpen(false);
      }
    }

    function handleMouseDown(e) {
      if (!isClosable) return;

      const path = e.composedPath();

      if (path.includes(modalRef.current) || (triggerEl && path.includes(triggerEl))) {
        return;
      }

      setOpen(false);
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, setOpen, isClosable]);

  const toggle = useCallback(
    function toggle() {
      setOpen(open => !open);
    },
    [setOpen]
  );

  const modalContext = useMemo(() => ({ toggle }), [toggle]);

  const themeStyles = { background: [], title: [] };

  switch (color) {
    case "purple":
      themeStyles.background.push("bg-purple-300/90 shadow-auth");
      themeStyles.title.push("m-20 text-white-100");
      break;

    case "gray":
      themeStyles.background.push("bg-white-100");
      themeStyles.title.push("w-full p-20 text-new-gray-800 bg-new-gray-200 font-semibold");
      break;

    case "white":
    default:
      themeStyles.background.push("bg-white-100");
      themeStyles.title.push("m-20 text-new-gray-800 font-semibold");
  }

  return (
    <>
      {renderTrigger && <div className="inline-flex">{renderTrigger({ toggle })}</div>}
      {createPortal(
        <CSSTransition
          in={isOpen}
          timeout={200}
          mountOnEnter
          unmountOnExit
          onEntering={onEntering}
          onExited={onExited}
          classNames={{
            enter: styles.modalEnter,
            enterActive: styles.modalEnterActive,
            exit: styles.modalExit,
            exitActive: styles.modalExitActive,
          }}
        >
          {state => (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className={cn("overlay fixed h-full w-full opacity-50", ui.overlayBg)} />
              <div
                ref={modalRef}
                {...props}
                className={cn(
                  "modal z-10 flex flex-col items-start overflow-hidden rounded-5 bg-white-100",
                  className,
                  ...themeStyles.background,
                  { "pointer-events-auto": state === "entered" }
                )}
              >
                {title && <h2 className={cn("text-25", ...themeStyles.title)}>{title}</h2>}
                <ModalContext.Provider value={modalContext}>
                  {typeof children === "function" ? children({ toggle }) : children}
                </ModalContext.Provider>
              </div>
            </div>
          )}
        </CSSTransition>,
        document.body
      )}
    </>
  );
}

ControlledModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  renderTrigger: PropTypes.func,
  className: PropTypes.string,
  onEntering: PropTypes.func,
  onExited: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.func]).isRequired,
  isClosable: PropTypes.bool,
  color: PropTypes.string,
};

ControlledModal.defaultProps = {
  className: null,
  onEntering: () => {},
  onExited: () => {},
  renderTrigger: null,
  isClosable: true,
  color: null,
  title: null,
};

function ModalBody({ children }) {
  return <div className="flex w-full flex-col rounded-5 bg-purple-400 bg-opacity-80 p-12">{children}</div>;
}

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
};

const Modal = observer(ControlledModal);

Modal.Body = ModalBody;
Modal.Footer = ({ children }) => children;

export default Modal;
