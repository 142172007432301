import { useEffect } from "react";
import PropTypes from "prop-types";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import LiveComments from "components/LiveComments";
import cn from "classnames";
import { URL } from "const";
import { useRouter } from "next/router";
import HideSensitiveModal from "components/modals/HideSensitiveModal";
import PromoBanner from "components/PromoBanner";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import AuthModal from "./Header/AuthModal";

function Layout({ children }) {
  const user = useStore("/user");
  const ui = useStore("/ui");

  const router = useRouter();

  useEffect(() => {
    user.load();
  }, [user]);

  const displayLiveComments =
    !ui.isMobile &&
    (router.pathname === URL.ROOT || router.pathname === URL.BOARD_INDEX || router.pathname === URL.ARTWORK_INDEX);

  const displayPromoBanner =
    router.pathname === URL.ARTWORK ||
    router.pathname === URL.PROFILE ||
    router.pathname === URL.BOARD ||
    router.pathname === URL.COLLECTION;

  if (!user.isLoaded) return null;

  return (
    <div className="relative z-0 flex min-h-screen flex-col bg-white-1000">
      <Header />
      <div
        className={cn(
          "break-words relative flex flex-grow",
          displayLiveComments ? "container flex-row justify-center py-20 md:px-20" : "flex-col"
        )}
      >
        <Main>{children}</Main>
        {displayLiveComments && <LiveComments />}
      </div>
      <Footer />
      <AuthModal />
      <HideSensitiveModal />
      {!user.isLoggedIn && displayPromoBanner && <PromoBanner />}
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default observer(Layout);
