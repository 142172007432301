import { types, flow } from "mobx-state-tree";
import { getNotifications, markNotificationsAsRead, markAllNotificationsAsRead } from "api";
import { getStompConnection } from "api/stomp";
import { NOTIFICATION_TYPES } from "const";
import { createPageableList } from "./utils";

const Notification = types
  .model("Notification", {
    id: types.identifierNumber,
    type: types.string,
    isRead: types.boolean,
    created: types.string,
    content: types.frozen(),
  })
  .actions(self => ({
    markAsRead() {
      self.isRead = true;
    },
  }));

// note(ars): We limit notifications amount like that, because we don't have virtualized lists atm,
// and it's not reasonable to see full history in "Recent notifications" (?)
// Probably should limit it on the backend
const NOTIFICATIONS_LIMIT = 90;

const PageableNotifications = createPageableList(Notification, {
  loadMore: async (self, { page, perPage }) => {
    const { content } = await getNotifications({ PNumber: page, PSize: perPage });
    const storedIds = self.list.map(notification => notification.id);
    const noDuplicates = content.filter(item => !storedIds.includes(item.id));

    return { items: noDuplicates, total: NOTIFICATIONS_LIMIT };
  },
  perPage: 15,
  safeReference: false,
});

export const NotificationsStore = types
  .model({
    notifications: types.maybeNull(PageableNotifications),
    screenPopups: types.array(types.safeReference(Notification)),
    isPopupOpen: false,
    unreadCount: 0,
  })
  .actions(self => {
    let stompConn = null;

    return {
      setIsPopupOpen(state) {
        self.isPopupOpen = state;
      },
      showNotification(notification) {
        self.screenPopups.unshift(notification);
      },
      hideNotification(notification, index) {
        self.screenPopups.splice(index, 1);
        self.markAsRead(notification.id);
      },
      init: flow(function* init(username) {
        const notifications = PageableNotifications.create();

        if (username === "temp-user") {
          self.notifications = notifications;
          const FAKE_NOTIFICATION = {
            id: 9999,
            isRead: false,
            created: "",
            type: NOTIFICATION_TYPES.ANONYMOUS_NOTIFICATION,
            content: "",
          };

          notifications.list.push(FAKE_NOTIFICATION);
          self.setUnreadCount(1);
          return;
        }

        yield notifications.init({
          initFn: async (_self, { page, perPage }) => {
            const { content, unreadCount } = await getNotifications({
              PNumber: page,
              PSize: perPage,
              unreadCount: true,
            });
            self.setUnreadCount(unreadCount);
            return { items: content, total: NOTIFICATIONS_LIMIT };
          },
        });

        self.subscribe(username);
        self.notifications = notifications;
      }),
      subscribe: flow(function* subscribe(username) {
        stompConn = yield getStompConnection();
        stompConn.subscribe(`/user/${username}/queue/updates`, message => {
          const notification = JSON.parse(message.body);
          self.notifications.addItem(notification, { force: true });

          if (!self.isPopupOpen) {
            self.showNotification(notification.id);
          }

          self.setUnreadCount(self.unreadCount + 1);
        });
      }),
      setUnreadCount(count) {
        self.unreadCount = count;
      },
      unsubscribe() {
        if (stompConn) {
          stompConn.unsubscribe();
        }
      },
      reset() {
        self.unsubscribe();
        stompConn = null;
        self.notifications = null;
        self.screenPopups = [];
        self.isPopupOpen = false;
        self.unreadCount = 0;
      },
      markAsRead(id) {
        const ids = Array.isArray(id) ? id : [id];
        markNotificationsAsRead(ids);

        self.notifications.list
          .filter(notification => ids.includes(notification.id))
          .forEach(notification => notification.markAsRead());
        self.unreadCount -= ids.length;
      },
      markAllAsRead: flow(function* markAllAsRead() {
        yield markAllNotificationsAsRead();
        self.unreadCount = 0;
      }),
    };
  });

export function create() {
  return NotificationsStore.create();
}
