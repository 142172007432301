/* eslint-disable */
import { types, flow, getRoot, getParent } from "mobx-state-tree";
import {
  getUserProfile,
  getProfileById,
  getArtistProfiles,
  getProfileStats,
  searchProfiles,
  updateUserProfile,
  getProfileFollowers,
  getProfileFollowings,
  followProfile,
  unfollowProfile,
  checkIsFollowing,
  getProfileComments,
  ignoreUser,
  unignoreUser,
} from "api/profile";
import mergeWith from "lodash/mergeWith";
import { createPageableList } from "./utils";
import { Artwork } from "./ArtworksStore";
import { Board } from "./BoardsStore";
import { Comment } from "./CommentsStore";
import { Blogpost } from "./BlogpostsStore";
import { createLikesStats } from "./LikesStore";
import { searchAll } from "api/search";

const ProfileStats = types
  .model("ProfileStats", {
    amountOfArtworks: 0,
    amountOfWishlists: 0,
    amountOfComments: 0,
    amountOfViews: 0,
    amountOfBuyers: 0,
    amountOfLikes: 0,
    publishedBoards: 0,
    commentsToArtworks: 0,
    commentsToBoards: 0,
    artworksAddedFromBoards: 0,
    amountOfIndividualCollectors: 0,
    featuredOnBoards: 0,
    publishedPosts: 0,
  })
  .views(self => ({
    get short() {
      const { amountOfArtworks, amountOfComments, amountOfLikes } = self;
      return { amountOfArtworks, amountOfComments, amountOfLikes };
    },
  }));

const ProfileLink = types.model("ProfileLink", {
  name: types.string,
  value: types.string,
});

const PageableFollowers = createPageableList(
  types.late(() => Profile),
  {
    loadMore(self) {
      return getRoot(self).profiles.loadProfileFollowers(getParent(self).id, { PSize: 15, PNumber: self.page });
    },
    perPage: 15,
  }
);

const PageableFollowings = createPageableList(
  types.late(() => Profile),
  {
    loadMore(self) {
      return getRoot(self).profiles.loadProfileFollowings(getParent(self).id, { PSize: 15, PNumber: self.page });
    },
    perPage: 15,
  }
);

const PageableComments = createPageableList(
  types.late(() => Comment),
  {
    loadMore(self, pageOptions) {
      return getRoot(self).profiles.loadProfileComments(getParent(self).id, pageOptions);
    },
    perPage: 15,
    safeReference: false,
  }
);

const PageableRecentPosts = createPageableList(Blogpost, {
  loadMore(self, pageOptions) {
    const profileId = getParent(self).id;
    const isCurrentUserProfile = getRoot(self).profiles.currentUserProfile?.id === profileId;
    return getRoot(self).blogposts.loadBlogposts({
      ...pageOptions,
      ownerId: profileId,
      status: isCurrentUserProfile ? null : "PUBLISHED",
      sortDirection: "DESC",
      sortField: "published_at",
    });
  },
  perPage: 8,
});

const PageablePopularPosts = createPageableList(Blogpost, {
  loadMore(self, pageOptions) {
    const profileId = getParent(self).id;
    const isCurrentUserProfile = getRoot(self).profiles.currentUserProfile?.id === profileId;
    return getRoot(self).blogposts.loadBlogposts({
      ...pageOptions,
      ownerId: profileId,
      status: isCurrentUserProfile ? null : "PUBLISHED",
      sortDirection: "DESC",
      sortField: "likes",
    });
  },
  perPage: 8,
});

const PageablePinnedPosts = createPageableList(Blogpost, {
  loadMore(self, pageOptions) {
    const profileId = getParent(self).id;
    return getRoot(self).blogposts.loadPinnedPosts({
      ...pageOptions,
      ownerId: profileId,
      sortField: "published_at",
    });
  },
  perPage: 5,
});

export const Profile = types
  .model("Profile", {
    id: types.identifierNumber,
    name: types.string,
    biography: types.maybeNull(types.string),
    links: types.snapshotProcessor(types.array(ProfileLink), {
      preProcessor(links) {
        if (!links) return [];

        return links.map(link => {
          if (typeof link === "object") return link;

          if (link.includes("|")) {
            let [name, value] = link.split("|");
            value = value.trim();
            name = name.trim();
            if (!value.startsWith("http")) {
              value = `https://${value}`;
            }

            return { name, value };
          }

          return { name: "", value: link };
        });
      },
    }),
    stats: types.maybeNull(ProfileStats),
    previewArtwork: types.safeReference(types.late(() => Artwork)),
    youtubeVideoUrl: "",
    boards: types.maybeNull(types.array(types.safeReference(Board))),
    recentPosts: types.maybeNull(PageableRecentPosts),
    popularPosts: types.maybeNull(PageablePopularPosts),
    pinnedPosts: types.maybeNull(PageablePinnedPosts),
    artist: false,
    followers: types.maybeNull(PageableFollowers),
    followings: types.maybeNull(PageableFollowings),
    isFollowed: types.maybeNull(types.boolean),
    updatedAt: "",
    comments: types.maybeNull(PageableComments),
    likes: types.maybeNull(createLikesStats()),
    relation: types.maybeNull(types.string),
    avatarFilename: types.maybeNull(types.string),
    localAvatar: types.maybeNull(types.string),
    localProfileCover: types.maybeNull(types.string),
    profileCoverFilename: types.maybeNull(types.string),
    dapsCheckmark: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    setArtist(isArtist) {
      self.artist = isArtist;
    },
    loadFollowInfo: flow(function* loadFollowInfo() {
      if (self.followers && self.followings) return;
      yield self.loadIsFollowed();

      const followers = PageableFollowers.create();
      const followings = PageableFollowings.create();
      self.followers = followers;
      self.followings = followings;

      return Promise.all([followers.init(), followings.init()]);
    }),
    setFollowed(isFollowed) {
      self.isFollowed = isFollowed;
    },
    loadIsFollowed: flow(function* loadIsFollowed() {
      if (getRoot(self).user.isLoggedIn) {
        if (self.isFollowed !== null) return;
        self.isFollowed = yield checkIsFollowing(self.id);
      }
    }),
    follow: flow(function* follow(profileId, index) {
      const profile = getRoot(self).profiles.list.get(profileId);

      if (profile.relation === "IGNORED") {
        yield self.unignore(profileId);
      }

      yield followProfile(profileId);
      profile.setRelation("FOLLOWED");

      if (self.followings && profile) {
        self.followings.addItem(profile, { force: true, placement: typeof index === "number" ? index : "start" });
      }

      if (profile) {
        profile.followers?.addItem(self.id);
        profile.setFollowed(true);
      }
    }),
    unfollow: flow(function* unfollow(profileId) {
      const profile = getRoot(self).profiles.list.get(profileId);

      yield unfollowProfile(profileId);
      profile.setRelation(null);

      if (self.followings && profile) {
        self.followings.removeItem(profile);
      }

      if (profile) {
        profile.followers?.removeItem(self);
        profile.setFollowed(false);
      }
    }),
    update: flow(function* update(profileId, { name, biography, links, avatarFilename }) {
      if (getRoot(self).user.isLoggedIn) {
        const resp = yield updateUserProfile(profileId, { name, biography, links });
        self.updatedAt = resp.updatedAt;
        self.name = name;
        self.biography = biography;
        self.links = links;
      } else {
        self.name = name;
        self.avatarFilename = avatarFilename;
      }

      if (getRoot(self).profiles.currentUserProfile.id === self.id) {
        getRoot(self).user.userData.updateDisplayName(name);
      }
    }),
    loadStats: flow(function* loadStats() {
      if (!self.artist || self.stats) return;
      try {
        const { stats } = yield getProfileStats(self.id);
        self.stats = stats;
      } catch {
        // Note(ars): For fresh profiles backend doesn't give any stats (500 error), so, for now we just ignore this
        self.stats = ProfileStats.create();
      }
    }),
    loadBoards: flow(function* loadBoards() {
      if (self.boards) return;
      self.boards = yield getRoot(self).boards.loadProfileBoards(self.id);
    }),
    addBoard(board) {
      if (!self.boards) return;
      self.boards.push(board);
    },
    removeBoard(board) {
      if (!self.boards) return;
      self.boards = self.boards.filter(publishedBoard => publishedBoard.id !== board.id);
    },
    addPreviewArtworkRef(ref) {
      self.previewArtwork = ref;
    },
    loadComments: flow(function* loadComments() {
      if (self.comments) return;

      const comments = PageableComments.create();
      self.comments = comments;

      return comments.init();
    }),
    loadPosts: flow(function* loadPosts() {
      if (self.recentPosts && self.pinnedPosts && self.popularPosts) return;

      self.recentPosts = PageableRecentPosts.create();
      self.recentPosts.init();

      self.popularPosts = PageablePopularPosts.create();
      self.popularPosts.init();

      self.pinnedPosts = PageablePinnedPosts.create();
      self.pinnedPosts.init();
    }),
    ignore: flow(function* ignore(profileId) {
      const profile = getRoot(self).profiles.list.get(profileId);

      if (profile.relation === "FOLLOWED") {
        yield self.unfollow(profileId);
      }

      profile.setRelation("IGNORED");
      yield ignoreUser(profileId);
    }),
    unignore: flow(function* ignore(profileId) {
      const profile = getRoot(self).profiles.list.get(profileId);
      profile.setRelation(null);
      yield unignoreUser(profileId);
    }),
    setRelation(newVal) {
      self.relation = newVal;
    },
    setLocalAvatar(newVal) {
      self.localAvatar = newVal;
    },
    setLocalProfileCover(newVal) {
      self.localProfileCover = newVal;
    },
    confirm: flow(function* confirm(profileId) {
      const { name, biography, links } = self;
      const socialLinks = self.links.reduce((acc, curr) => {
        if (curr.value) {
          if (curr.value.startsWith("http") || curr.value.startsWith("https")) {
            acc.push(curr.value);
          } else {
            acc.push(curr.value.replace(/^/, "https://"));
          }
        }

        return acc;
      }, []);
      
      const resp = yield updateUserProfile(profileId, { name, biography, links: socialLinks, confirmed: true });

      self.updatedAt = resp.updatedAt;
      self.name = name;
      self.biography = biography;
      self.links = links;
    }),
  }));

export const ProfilesStore = types
  .model("ProfilesStore", {
    list: types.map(Profile),
    currentUserProfile: types.safeReference(Profile),
  })
  .actions(self => ({
    addFromSnapshot(profileData) {
      if (self.list.has(profileData.id)) return self.list.get(profileData.id);
      return self.list.put(profileData);
    },
    mapProfile({ previewArtwork, stats, ...profileData }) {
      let profile;

      if (profileData.relation === "FOLLOWED") {
        profileData.isFollowed = true;
      } else {
        profileData.isFollowed = false;
      }

      if (self.list.has(profileData.id)) {
        profile = self.list.get(profileData.id);
        const mergedProfile = mergeWith(profile, profileData, (v1, v2) => v2 || v1);
        self.list.set(profile.id, mergedProfile);
      } else {
        profile = self.list.put(profileData);
      }

      if (previewArtwork && !profile.previewArtwork) {
        const artwork = getRoot(self).artworks.mapArtwork(previewArtwork);
        profile.addPreviewArtworkRef(artwork);
      }

      if (stats && !profile.stats) {
        profile.stats = stats;
      }

      return profile;
    },
    mapProfiles(profilesData) {
      return profilesData.map(self.mapProfile);
    },
    delete(profileId) {
      self.list.delete(profileId);
    },
    deleteProfiles(profiles) {
      profiles.forEach(profileId => self.list.delete(profileId));
    },
    setCurrentUserProfile(profile) {
      self.currentUserProfile = self.mapProfile(profile);
    },
    loadCurrentUserProfile: flow(function* loadCurrentUserProfile() {
      if (self.currentUserProfile) return;
      const profile = yield getUserProfile();
      self.currentUserProfile = self.mapProfile(profile);
    }),
    loadProfile: flow(function* loadProfile(profileId) {
      if (self.list.has(profileId)) return self.list.get(profileId);
      return self.mapProfile(yield getProfileById(profileId));
    }),
    loadPopularProfiles: flow(function* loadPopularProfiles() {
      const { profiles } = yield getArtistProfiles();
      return self.mapProfiles(profiles);
    }),
    search: flow(function* search(query, pageOptions) {
      const { profiles: profilesData, total } = yield searchProfiles(query, {
        ...pageOptions,
        add: "stats,likes,relation",
        profileType: "artist",
      });

      const profileIds = self.mapProfiles(profilesData);

      return { profiles: profileIds, total };
    }),
    searchArtists: flow(function* searchArtists(query, pageOptions) {
      const { searchResult, total } = yield searchAll(query, {
        ...pageOptions,
        types: ["artist"],
      });

      const profileIds = self.mapProfiles(searchResult.artist.items);

      return { profiles: profileIds, total };
    }),
    loadProfileFollowers: flow(function* loadProfileFollowers(profileId, pageOptions) {
      const { followers, total } = yield getProfileFollowers(profileId, pageOptions);
      return { items: self.mapProfiles(followers), total };
    }),
    loadProfileFollowings: flow(function* loadProfileFollowings(profileId, pageOptions) {
      const { followings, total } = yield getProfileFollowings(profileId, pageOptions);
      return { items: self.mapProfiles(followings), total };
    }),
    resetCurrentUserProfile() {
      self.currentUserProfile = undefined;
    },
    loadProfileComments: flow(function* loadProfileComments(profileId, pageOptions) {
      const { comments, total } = yield getProfileComments(profileId, pageOptions);
      return { items: comments, total };
    }),
    addNewProfilePost: flow(function* addNewProfilePost(profileId, post) {
      const profile = yield self.loadProfile(profileId);
      yield profile.loadPosts();

      if (
        !profile.recentPosts.list.find(userPost => userPost.id === post.id) &&
        !profile.popularPosts.list.find(userPost => userPost.id === post.id)
      ) {
        profile.recentPosts.addItem(post, { force: true });
        profile.popularPosts.addItem(post, { force: true });
      }
    }),
  }));

export function create() {
  return ProfilesStore.create();
}
