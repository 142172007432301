import { Input } from "components/basic";
import { useEffect, useState, useCallback } from "react";
import Form from "components/Modal/Form";
import * as Yup from "yup";
import { useStore } from "store";
import Button from "components/Button";
import { setNewPassword } from "api";
import { AUTH_MODAL_STATE } from "const";

let token;

export function setToken(resetToken) {
  token = resetToken;
}

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .required(true)
    .min(7, "Should be 7 characters or more")
    .max(100, "Should be 100 characters or less")
    .matches(/\d/, "Should contain numbers"),
  confirmPassword: Yup.string()
    .required(true)
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

export default function RecoveryEndForm() {
  const authModal = useStore("/ui/authModal");
  const [isPasswordReset, setPasswordReset] = useState(false);

  useEffect(() => {
    authModal.setIsClosable(false);
  }, [authModal]);

  const handleSubmit = useCallback(
    async function handleSubmit({ password }, { setSubmitting, setStatus }) {
      try {
        authModal.setIsClosable(false);
        await setNewPassword(token, password);
        setPasswordReset(true);
        authModal.setIsClosable(true);
      } catch (error) {
        setStatus({
          visible: true,
          message: error.message,
        });
      } finally {
        setSubmitting(false);
      }
    },
    [authModal]
  );

  return isPasswordReset ? (
    <div className="flex w-full flex-col space-y-10 rounded-5 bg-new-gray-300 bg-opacity-80 p-12">
      <p className="text-14 leading-18">Success, your password is reset!</p>
      <p className="text-14 leading-18">Try logging into your account.</p>
      <p className="mb-38 text-12 leading-14">
        if you have any troubles contact as at{" "}
        <a href="mailto:daisy@oww.io" className="underline">
          daisy@oww.io
        </a>
      </p>
      <Button color="green" className="w-full font-medium" onClick={() => authModal.setState(AUTH_MODAL_STATE.LOGIN)}>
        Ok
      </Button>
    </div>
  ) : (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      className="bg-opacity-80 md:bg-new-gray-300"
      onSubmit={handleSubmit}
    >
      {({ canSubmit, isSubmitting }) => (
        <>
          <p className="text-14 leading-14 text-new-gray-800 md:text-12">Enter new password</p>
          <Input type="password" name="password" placeholder="Password" disabled={isSubmitting} />
          <Input type="password" name="confirmPassword" placeholder="Confirm password" disabled={isSubmitting} />
          <Button
            type="submit"
            color="green"
            padding="h-[4.4rem] p-14 md:h-auto md:p-10"
            className="w-full font-medium"
            disabled={!canSubmit}
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </>
      )}
    </Form>
  );
}
