import { types, flow, getRoot } from "mobx-state-tree";

export const BlogPage = types.model("BlogPage", {}).actions(self => ({
  mount: flow(function* mount() {
    if (!getRoot(self).user.isLoggedIn) return;

    yield getRoot(self).blogposts.userBlogposts.init();
  }),
}));

export function create() {
  return BlogPage.create();
}
