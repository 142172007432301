import PropTypes from "prop-types";
import { useCallback, useRef, useMemo, useEffect, useState, forwardRef } from "react";

const LoadableList = forwardRef(function LoadableList(
  { as: El, isMoreAvailable, loadMore, children, global, threshold, horizontal, fillPastContainerSize, ...props },
  outerRef
) {
  const [ref, setRef] = useState(null);
  const isLoading = useRef(false);

  const handleRef = useCallback(
    function handleRef(el) {
      if (typeof outerRef === "function") {
        outerRef(el);
      } else if (outerRef) {
        // eslint-disable-next-line no-param-reassign
        outerRef.current = el;
      }
      setRef(global ? document : el);
    },
    [outerRef, global]
  );

  const getMeasurements = useCallback(() => {
    const el = global ? ref.documentElement : ref;
    if (horizontal) {
      return {
        client: el.clientWidth,
        scroll: el.scrollWidth,
        scrollPos: el.scrollLeft,
      };
    }
    return {
      client: el.clientHeight,
      scroll: el.scrollHeight,
      scrollPos: el.scrollTop,
    };
  }, [ref, global, horizontal]);

  const isForwardScroll = useMemo(() => {
    let prevScrollPos = 0;

    function isForward(e) {
      const { scrollPos } = getMeasurements(e.target);
      const forward = scrollPos > prevScrollPos;
      prevScrollPos = scrollPos;
      return forward;
    }

    return isForward;
  }, [getMeasurements]);

  const isTriggerPointReached = useCallback(() => {
    const { client, scroll, scrollPos } = getMeasurements();
    return scrollPos + client > scroll * threshold;
  }, [getMeasurements, threshold]);

  useEffect(() => {
    if (!ref) return;

    async function fill() {
      const { client, scroll } = getMeasurements();
      if (isMoreAvailable && client && scroll && (scroll < client || (scroll === client && fillPastContainerSize))) {
        isLoading.current = true;
        await loadMore();
        isLoading.current = false;
      }
    }

    fill();
  }, [fillPastContainerSize, isMoreAvailable, children.length, getMeasurements, loadMore, ref]);

  useEffect(() => {
    if (!ref) return () => {};

    async function scrollHandler(e) {
      if (!isForwardScroll(e) || isLoading.current) return;
      const { client, scroll } = getMeasurements();

      if (client === scroll) return;

      if (isTriggerPointReached()) {
        isLoading.current = true;
        await loadMore();
        isLoading.current = false;
      }
    }

    if (isMoreAvailable) {
      ref.addEventListener("scroll", scrollHandler);
    } else {
      ref.removeEventListener("scroll", scrollHandler);
    }

    return () => ref.removeEventListener("scroll", scrollHandler);
  }, [isTriggerPointReached, isForwardScroll, getMeasurements, global, isMoreAvailable, loadMore, ref, threshold]);

  return (
    <El ref={handleRef} {...props}>
      {children}
    </El>
  );
});

LoadableList.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node.isRequired,
  isMoreAvailable: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  global: PropTypes.bool,
  threshold: PropTypes.number,
  horizontal: PropTypes.bool,
  fillPastContainerSize: PropTypes.bool,
};

LoadableList.defaultProps = {
  as: "div",
  global: false,
  threshold: 0.8,
  horizontal: false,
  fillPastContainerSize: false,
};

export default LoadableList;
