import { types, flow, getRoot, getParent } from "mobx-state-tree";
import {
  getUserBlogposts,
  getBlogpostsReferences,
  deleteBlogpost,
  createBlogpost,
  publishBlogpost,
  getBlogpost,
  createBlog,
  updateBlogpost,
  getBlogposts,
  getPinnedBlogposts,
} from "api/blog";
import ae from "lib/analytics";
import { BLOGPOST_STATUS, ENTITY_TYPES, IMAGE_SIZE_PREFIX } from "const";
import {
  getArtworkFilePath,
  getBlogpostComments,
  commentReply,
  addBlogpostComment,
  getCommentReplies,
  deleteComment,
} from "api";
import { values } from "mobx";
import truncate from "truncate-html";
import { getProfiles } from "api/profile";
import { searchAll } from "api/search";
import { createPageableList } from "./utils";
import { Comment } from "./CommentsStore";
import { createLikesStats } from "./LikesStore";

const TRUNCATE_CONFIG = {
  stripTags: true,
  decodeEntities: true,
  ellipsis: "<...>",
  reserveLastWord: -1,
};

const BlogpostStats = types
  .model("BlogpostStats", {
    comments: 0,
    totalViews: 0,
    amountOfLikes: 0,
    // uniqueViews: 0, // TODO: "do we even need this stat?"
  })
  .actions(self => ({
    incrementLikes() {
      self.amountOfLikes += 1;
    },
    decrementLikes() {
      self.amountOfLikes -= 1;
    },
    setAmountOfLikes(amt) {
      self.amountOfLikes = amt;
    },
  }));

const BlogpostsReferences = types.model({
  category: types.array(types.string),
  status: types.array(types.string),
});

const BlogpostOwner = types.model({
  id: types.number,
  username: types.string,
  avatarFilename: types.string,
});

const BlogpostContent = types.model({
  blocks: types.frozen({}),
});

const BlogpostPreview = types.model("BlogpostPreview", {
  url: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isForcedNull: false,
  crop: types.maybeNull(types.model({ id: types.number, url: types.string, name: types.string })),
  sensitiveContent: types.maybeNull(types.boolean),
  blurhash: types.maybeNull(types.string),
});

const Blog = types.model({
  id: types.identifierNumber,
  owner: BlogpostOwner,
  title: types.maybeNull(types.string),
  createdAt: types.string,
});

const PageableComments = createPageableList(
  types.late(() => Comment),
  {
    loadMore(self, pageOptions) {
      return getRoot(self).blogposts.loadCommentReplies(getParent(self).id, pageOptions);
    },
    perPage: 15,
    safeReference: false,
  }
);

const BlogpostComment = types
  .compose(
    "BlogpostComment",
    Comment,
    types.model({
      replies: types.optional(PageableComments, () => PageableComments.create()),
    })
  )
  .actions(self => ({
    addItem: flow(function* addItem(commentId, commentBody) {
      const comment = yield commentReply(commentId, commentBody);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.replies.list.push(comment);
      self.nestedCommentsCount += 1;
    }),
  }));

const PageableBlogpostComments = createPageableList(BlogpostComment, {
  async loadMore(self, pageOptions) {
    const { comments, total } = await getBlogpostComments(getParent(self).id, pageOptions);
    return { items: comments, total };
  },
  perPage: 10,
  safeReference: false,
});

export const Blogpost = types
  .model("Blogpost", {
    blog: Blog,
    id: types.identifierNumber,
    name: types.string,
    status: types.string,
    category: types.string,
    publishedAt: types.maybeNull(types.string),
    reasonRejection: types.maybeNull(types.string),
    createdAt: types.string,
    updatedAt: types.string,
    stats: types.optional(BlogpostStats, () => BlogpostStats.create()),
    owner: BlogpostOwner,
    isProcessingAction: false,
    content: types.maybeNull(BlogpostContent),
    preview: types.maybeNull(BlogpostPreview),
    comments: types.optional(PageableBlogpostComments, () => PageableBlogpostComments.create()),
    totalComments: types.maybeNull(types.number),
    likes: types.maybeNull(createLikesStats()),
  })
  .actions(self => ({
    setIsProcessingAction(val) {
      self.isProcessingAction = val;
    },
    setBlogpostCover(imageData, isForcedNull, isCrop) {
      if (isCrop) {
        self.preview.crop = imageData;
        self.preview.isForcedNull = isForcedNull;
        return;
      }

      self.preview =
        imageData?.url || imageData?.filename
          ? { url: imageData.url || getArtworkFilePath(imageData.filename, IMAGE_SIZE_PREFIX.LARGE) }
          : { url: null };
      self.preview.isForcedNull = isForcedNull;
    },
    addComment: flow(function* addComment(commentBody) {
      const comment = yield addBlogpostComment(self.id, commentBody);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.comments.list.unshift(comment);
      self.totalComments += 1;

      ae.comment(self.id, ENTITY_TYPES.BLOGPOST, comment.id);
    }),
    setTotalComments(val) {
      self.totalComments = val;
    },
    resetComments() {
      self.comments = {};
      self.totalComments = null;
    },
    initComments: flow(function* initComments() {
      yield self.comments.init();
      self.setTotalComments(self.comments.totalItems);
    }),
    removeComment: flow(function* removeComment(commentId, parentCommentId = null) {
      yield deleteComment(commentId);
      self.totalComments -= 1;

      if (parentCommentId) {
        const parentComment = self.comments.list.find(commentItem => commentItem.id === parentCommentId);
        const comment = parentComment.replies.list.find(replyItem => replyItem.id === commentId);
        return parentComment.replies.removeItem(comment);
      }

      const comment = self.comments.list.find(commentItem => commentItem.id === commentId);
      return self.comments.removeItem(comment);
    }),
  }));

const PageableUserBlogposts = createPageableList(Blogpost, {
  loadMore(self, pagingOptions) {
    return getRoot(self).blogposts.loadUserBlogposts(pagingOptions);
  },
  perPage: 10,
  // safeReference: false,
});

export const BlogpostsStore = types
  .model("BlogpostsStore", {
    list: types.map(Blogpost),
    userBlogposts: PageableUserBlogposts,
    blogpostsReferences: types.maybeNull(BlogpostsReferences),
    showPublished: false,
    currentEditing: types.maybeNull(types.number),
  })
  .views(self => ({
    get displayedPosts() {
      return self.userBlogposts.list.filter(blogpost =>
        self.showPublished ? blogpost.status === BLOGPOST_STATUS.PUBLISHED : blogpost.status === BLOGPOST_STATUS.DRAFT
      );
    },
  }))
  .actions(self => ({
    createUserBlog: flow(function* createUserBlog(username) {
      const { id } = yield createBlog(username);
      getRoot(self).user.userData.updateBlogId(id);
    }),
    mapBlogpost(blogpostData) {
      let blogpost;

      if (self.list.has(blogpostData.id)) {
        blogpost = self.list.get(blogpostData.id);
        self.list.set(blogpost.id, { ...blogpost, ...blogpostData });
      } else {
        blogpost = self.list.put(blogpostData);
      }

      return blogpost;
    },
    mapBlogposts(blogpostsData) {
      return blogpostsData.map(self.mapBlogpost);
    },
    loadUserBlogposts: flow(function* loadUserBlogposts(params) {
      const { items, total } = yield getUserBlogposts(params);
      return { items: self.mapBlogposts(items), total };
    }),
    loadBlogposts: flow(function* loadBlogposts(params) {
      const { items, total } = yield getBlogposts(params);
      return { items: self.mapBlogposts(items), total };
    }),
    loadBlogpostsReferences: flow(function* loadBlogpostsReferences() {
      const resp = yield getBlogpostsReferences();
      self.blogpostsReferences = resp;
    }),
    addBlogpostDraft: flow(function* addBlogpostDraft(title, content, category = self.blogpostsReferences.category[0]) {
      const blogpostContent = content.blocks.length > 0 ? content : null;

      if (!getRoot(self).user.userData.blogId) {
        yield self.createUserBlog(getRoot(self).user.userData.username);
      }

      const blogpostData = yield createBlogpost(getRoot(self).user.userData.blogId, title, category, blogpostContent);
      const blogpost = self.mapBlogpost(blogpostData);
      yield getRoot(self).profiles.addNewProfilePost(blogpostData.owner.id, blogpost);
      self.userBlogposts.addItem(blogpost, { force: true, placement: "start" });

      return blogpost.id;
    }),
    editBlogpost: flow(function* editBlogpost(
      blogpostId,
      title,
      content,
      category = self.blogpostsReferences.category[0]
    ) {
      const blogpostContent = content.blocks.length > 0 ? content : null;

      let blogpostCover = self.list.get(blogpostId).preview;
      const coverEl = content.blocks.find(
        contentBlock => contentBlock.type === "artwork" || contentBlock.type === "media"
      );
      if (!blogpostCover?.url && (coverEl || blogpostCover?.crop) && !blogpostCover?.isForcedNull) {
        if (blogpostCover?.crop) {
          blogpostCover = blogpostCover.preview.crop;
        } else {
          blogpostCover = {
            url: coverEl.data.imageData.url,
            sensitiveContent: coverEl.data.sensitiveContent,
            blurhash: coverEl.data.blurhash,
          };
        }
      } else if (!coverEl || blogpostCover.isForcedNull) {
        blogpostCover = null;
      }

      const firstParagraph = content.blocks.find(contentBlock => contentBlock.type === "paragraph");
      let previewText = null;
      if (firstParagraph) {
        previewText = truncate(firstParagraph.data.text, 360, TRUNCATE_CONFIG);
      }
      const blogpost = yield updateBlogpost(blogpostId, title, category, blogpostContent, blogpostCover, previewText);

      self.mapBlogpost(blogpost);

      return blogpost.id;
    }),
    removeBlogpost: flow(function* removeBlogpost(blogpostId) {
      const blogpost = self.list.get(blogpostId);
      blogpost.setIsProcessingAction(true);
      yield deleteBlogpost(blogpostId);
      self.userBlogposts.removeItem(blogpost);
      self.list.delete(blogpostId);
    }),
    addBlogpost: flow(function* addBlogpost(blogpostId) {
      // if previous version of post was marked as DRAFT => post is new
      // and we should attach it to start of the feed
      const { status } = yield getBlogpost(blogpostId);
      const isNewPost = status === "DRAFT" || false;

      const blogpostData = yield publishBlogpost(blogpostId);
      const blogpost = self.mapBlogpost(blogpostData);

      if (isNewPost) {
        getRoot(self).pages.feedPage.addNewPost(blogpost);
        yield getRoot(self).profiles.addNewProfilePost(blogpostData.owner.id, blogpost);
      }
      return blogpost.id;
    }),
    loadBlogpost: flow(function* loadBlogpost(blogpostId) {
      const blogpost = yield getBlogpost(blogpostId);
      return self.mapBlogpost(blogpost);
    }),
    resetUserBlogposts() {
      self.userBlogposts = PageableUserBlogposts.create();
    },
    toggleShowPublished() {
      self.showPublished = !self.showPublished;
    },
    updateBlogpostCover(blogpostId, imageData, forceNull = false, isCrop = false) {
      self.list.get(blogpostId).setBlogpostCover(imageData, forceNull, isCrop);
    },
    setCurrentEditing(blogpostId) {
      self.currentEditing = blogpostId;
    },
    loadRecentBlogposts: flow(function* loadRecentBlogposts(pagingOptions) {
      const { items, total } = yield getBlogposts({ ...pagingOptions, sortDirection: "desc", status: "PUBLISHED" });
      const blogposts = self.mapBlogposts(items);

      const ownerIds = [];
      blogposts.forEach(post => {
        if (!ownerIds.includes(post.owner.id)) ownerIds.push(post.owner.id);
      });
      const profiles = yield getProfiles(ownerIds, { perPage: ownerIds.length });
      getRoot(self).profiles.mapProfiles(profiles);

      return { items: blogposts, total };
    }),
    resetBlogpostsComments() {
      values(self.list).forEach(blogpost => {
        blogpost.resetComments();
      });
    },
    resetUserCommentsLikes() {
      values(self.list).forEach(blogpost => {
        blogpost.comments.list.forEach(comment => {
          if (comment?.likes?.currentUserValue) {
            comment.likes.updateCurrentValue(null);
          }
        });
      });
    },
    loadCommentReplies: flow(function* loadCommentReplies(commentId, pageOpts) {
      const { comments, total } = yield getCommentReplies(commentId, pageOpts);
      return { items: comments, total };
    }),
    search: flow(function* search(q, pageOptions) {
      const { searchResult, total } = yield searchAll(q, { ...pageOptions, types: ["post"] });
      const posts = self.mapBlogposts(searchResult.post.items);

      return { posts, total };
    }),
    loadPinnedPosts: flow(function* loadPinnedPosts(params) {
      const { items, total } = yield getPinnedBlogposts({
        ...params,
        sortDirection: "desc",
        status: "PUBLISHED",
      });
      const blogposts = self.mapBlogposts(items);

      const ownerIds = [];
      blogposts.forEach(post => {
        if (!ownerIds.includes(post.owner.id)) ownerIds.push(post.owner.id);
      });
      const profiles = yield getProfiles(ownerIds, { perPage: ownerIds.length });
      getRoot(self).profiles.mapProfiles(profiles);

      return { items: blogposts, total };
    }),
  }));

export function create() {
  const userBlogposts = PageableUserBlogposts.create();
  return BlogpostsStore.create({
    userBlogposts,
  });
}
