import PropTypes from "prop-types";
import { forwardRef } from "react";
import cn from "classnames";
import Blurhash from "./Blurhash";

const Image = forwardRef(function Image(
  {
    as: El,
    width,
    height,
    styles,
    src,
    srcSet,
    sizes,
    alt,
    color,
    blurHash,
    ratio,
    onLoad,
    decoding,
    objectCover,
    loading = "lazy",
    ...props
  },
  ref
) {
  return (
    <El
      className={cn("overlap-container", styles.container)}
      style={color ? { backgroundColor: color } : null}
      {...props}
    >
      {blurHash && (
        <Blurhash
          hash={blurHash}
          className="overlap-item h-full w-full"
          style={ratio ? { aspectRatio: `${ratio}` } : null}
        />
      )}
      <img
        ref={ref}
        loading={loading}
        className={cn(
          "overlap-item relative h-full w-full",
          objectCover ? "object-cover" : "object-contain",
          styles.image
        )}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        alt={alt}
        decoding={decoding}
        onLoad={onLoad}
        onError={e => {
          // eslint-disable-next-line no-param-reassign
          e.target.src = "/img/no-image.svg";
          e.target.removeAttribute("srcset");
          e.target.removeAttribute("sizes");
          e.target.classList.add("bg-white-300");
          e.target.classList.remove("object-cover");
          e.target.classList.add("object-contain");
        }}
        width={width}
        height={height}
        style={ratio ? { aspectRatio: `${ratio}` } : null}
      />
    </El>
  );
});

Image.propTypes = {
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node, PropTypes.string]),
  src: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  ratio: PropTypes.number,
  blurHash: PropTypes.string,
  styles: PropTypes.shape({
    container: PropTypes.string,
    image: PropTypes.string,
  }),
  onLoad: PropTypes.func,
  loading: PropTypes.string,
  decoding: PropTypes.string,
  objectCover: PropTypes.bool,
};

Image.defaultProps = {
  as: "div",
  color: null,
  ratio: null,
  blurHash: null,
  styles: { container: "", image: "" },
  width: null,
  height: null,
  src: null,
  srcSet: null,
  sizes: null,
  onLoad: () => {},
  loading: "lazy",
  decoding: "async",
  objectCover: true,
};

export default Image;
