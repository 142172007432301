import { types, flow } from "mobx-state-tree";
import { getPaymentConfiguration, startPaymentSession } from "api";

const CodePack = types.model({
  priceId: types.string,
  amount: types.number,
  price: types.number,
});

export const GetCodesPage = types
  .model({
    packs: types.maybeNull(types.array(CodePack)),
  })
  .actions(self => {
    let stripe;

    return {
      mount: flow(function* mount() {
        if (self.packs) return;

        const { publicApiKey, prices } = yield getPaymentConfiguration();

        stripe = yield import("@stripe/stripe-js").then(({ loadStripe }) => loadStripe(publicApiKey));

        self.packs = prices
          .map(({ priceId, codesAmount, unitAmount }) => ({
            priceId,
            amount: codesAmount,
            price: unitAmount / 100,
          }))
          .sort((a, b) => (a.price < b.price ? -1 : 1));
      }),
      processPayment: flow(function* processPayment(email, pack) {
        const sessionId = yield startPaymentSession(email, pack);
        stripe.redirectToCheckout({ sessionId });
      }),
    };
  });

export function create() {
  return GetCodesPage.create();
}
