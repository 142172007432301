import PropTypes from "prop-types";
import { Formik } from "formik";

export default function Form({ children, className, allowDirty, ...props }) {
  return (
    <Formik validateOnBlur={false} {...props}>
      {({ dirty, isValid, isSubmitting, status, handleSubmit, errors }) => {
        const canSubmit = !isSubmitting && !status && (allowDirty ? true : dirty) && isValid;

        return (
          <form className={className} onSubmit={handleSubmit}>
            {children({ canSubmit, isSubmitting, errors })}
          </form>
        );
      }}
    </Formik>
  );
}

Form.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  allowDirty: PropTypes.bool,
};

Form.defaultProps = {
  className: null,
  allowDirty: false,
};
