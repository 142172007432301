import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useStore } from "store";
import { Input, Button as BasicButton } from "components/basic";
import Button from "components/Button";
import Form from "components/Modal/Form";
import * as Yup from "yup";
import { URL } from "const";
import Link from "components/basic/Link";
import ae from "lib/analytics";
import { useRouter } from "next/router";
import ThirdPartyLinks from "../ThirdPartyLinks";

Yup.addMethod(Yup.string, "notMatches", function notMatches(regex, errorMessage) {
  return this.test("notMatches", errorMessage, function noMatch(value) {
    return !regex.test(value);
  });
});

const initialValues = {
  email: "",
  confirmEmail: "",
  username: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  email: Yup.string().required(true).email("Invalid email address"),
  confirmEmail: Yup.string()
    .required(true)
    .oneOf([Yup.ref("email"), null], "Emails do not match"),
  username: Yup.string()
    .required(true)
    .min(6, "Should be 6 characters or more")
    .notMatches(/\s/, "Spaces are not allowed")
    .notMatches(/\W|_/, "Special characters are not allowed"),
  password: Yup.string()
    .required(true)
    .min(7, "Should be 7 characters or more")
    .max(100, "Should be 100 characters or less")
    .matches(/\d/, "Should contain numbers"),
  confirmPassword: Yup.string()
    .required(true)
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});

export default function SignupForm({ gotoLogin, beforeSubmit, afterSubmit, submitSuccess, transparentBg, setIsOpen }) {
  const { user, ui } = useStore();

  const router = useRouter();

  const isMobile = ui.isMobile;
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const handleSubmit = useCallback(
    async function handleSubmit(form, { setSubmitting, setStatus }) {
      try {
        beforeSubmit();
        const { email, username, password } = form;
        await user.createUser(email, username.trim().toLowerCase(), password);

        let onboarding = null;
        let q = null;
        if (Object.keys(router.query).includes("ob_page") || Object.keys(router.query).includes("ob_banner")) {
          // store existing onboarding queryparams
          onboarding = Object.keys(router.query).filter(k => k === "ob_page" || k === "ob_banner");
          // filter existing queryparams from onbaording-related params which were assigned upon banner/landing-page clicks
          q = Object.keys(router.query)
            .filter(k => k !== "ob_page" && k !== "ob_banner")
            .reduce(
              (obj, key) =>
                Object.assign(obj, {
                  [key]: router.query[key],
                }),
              {}
            );
        }

        // add (if any) - previously existing queryparams, onboarding refs, signup_success queryparam
        router.replace({
          pathname: router.pathname,
          query: {
            ...(q ? { ...q } : null),
            ...(onboarding ? { ob_refs: String(onboarding) } : null),
            signup_success: true,
          },
        });

        setIsAccountCreated(true);
        ae.user.signup.success();
        submitSuccess();
      } catch (error) {
        const message = error.message || "Unexpected error";
        setStatus({ visible: true, message });
        ae.user.signup.failure({ errorCode: error.code || null, message });
      } finally {
        afterSubmit();
        setSubmitting(false);
      }
    },
    [user, beforeSubmit, afterSubmit, submitSuccess, router]
  );

  return isAccountCreated ? (
    <div className="flex w-full flex-col space-y-10 p-20 md:bg-new-gray-300 md:bg-opacity-80 md:p-12">
      <p className="text-14 leading-18">Thanks for creating an account with KULTURA and Occupy White Walls!</p>
      <p className="text-14 leading-18">
        We hope you enjoy exploring the game and the library of artists and artworks on KULTURA
      </p>
      <p className="mb-38 text-14 font-medium leading-18">
        Please don&apos;t forget to check your email to confirm the account
      </p>
      <Button
        color="green"
        className="w-full font-medium"
        onClick={() => {
          if (!setIsOpen) {
            ui.authModal.setOpen(false);
            return;
          }

          setIsOpen(false);
        }}
      >
        Continue
      </Button>
    </div>
  ) : (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        className={!transparentBg && "md:bg-new-gray-300 md:bg-opacity-80"}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, canSubmit }) => (
          <>
            <Input
              name="email"
              placeholder="Email"
              autoFocus={!isMobile}
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <Input
              name="confirmEmail"
              placeholder="Confirm email"
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <Input
              name="username"
              placeholder="Username"
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <Input
              type="password"
              name="password"
              placeholder="Password"
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <p className="text-12 leading-14 text-new-gray-800">
              By creating an account you agree to our terms and conditions, code of conduct and{" "}
              <Link href={URL.PRIVACY} target="_blank" rel="noreferrer" className="underline">
                privacy policy
              </Link>
              . We may send you the occasional email too. We will never sell your information.
            </p>

            <Button
              color="green"
              type="submit"
              className="w-full font-medium"
              padding="h-[4.4rem] p-14 md:h-auto md:p-10"
              disabled={!canSubmit}
              loading={isSubmitting}
            >
              Sign up
            </Button>
          </>
        )}
      </Form>
      <div className="mx-20 mt-18 text-new-gray-800 md:mx-12 md:mb-12">
        <BasicButton onClick={gotoLogin} className="mb-18 underline">
          Already have an account?
        </BasicButton>
        <ThirdPartyLinks action="Sign up" />
      </div>
    </>
  );
}

SignupForm.propTypes = {
  gotoLogin: PropTypes.func.isRequired,
  beforeSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  submitSuccess: PropTypes.func,
  transparentBg: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

SignupForm.defaultProps = {
  beforeSubmit: () => {},
  afterSubmit: () => {},
  submitSuccess: () => {},
  transparentBg: false,
  setIsOpen: null,
};
