import { useEffect } from "react";
import { useRouter } from "hooks/router";

/* Router events:
 *  https://nextjs.org/docs/api-reference/next/router#routerevents
 *  routeChangeStart
 *  routeChangeComplete
 *  beforeHistoryChange
 *  hashChangeStart
 *  hashChangeComplete
 *  routeChangeError
 */

export default function useRouterEvents(events, handler) {
  const router = useRouter();
  useEffect(() => {
    if (typeof events === "object") {
      const eventsArr = Object.entries(events);
      eventsArr.forEach(([event, cb]) => router.events.on(event, cb));
      return () => eventsArr.forEach(([event, cb]) => router.events.off(event, cb));
    }

    router.events.on(events, handler);
    return () => router.events.off(events, handler);
  }, [events, handler, router]);

  return router;
}
