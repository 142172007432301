import { types, flow, getRoot, getParent, detach } from "mobx-state-tree";
import { RANDOM_ARTWORK_BOARDS_AMOUNT } from "const";
import ae from "lib/analytics";
import { Artwork, Tag } from "../ArtworksStore";
import { createPageableList } from "../utils";
import { Board } from "../BoardsStore";

const PageableSimilarArtworks = createPageableList(Artwork, {
  loadMore: async self =>
    getRoot(self).artworks.loadSimilarArtworks(getParent(self).artwork, {
      page: self.page,
      perPage: 5,
    }),
  perPage: 5,
  initPage: 0,
});

const PageableSimilarArtworksByTag = createPageableList(Artwork, {
  loadMore: async ({ self, sourceId, tagName }) =>
    getRoot(self).artworks.loadSimilarArtworksByTag(sourceId, tagName, {
      page: self.page,
      perPage: 4,
    }),
  perPage: 4,
  initPage: 2,
});

export const ArtworkPage = types
  .model("ArtworkPage", {
    artwork: types.safeReference(Artwork),
    similarArtworks: types.maybe(PageableSimilarArtworks),
    artworkBoards: types.map(
      types.model({
        items: types.maybeNull(types.array(types.safeReference(Board))),
        total: types.maybeNull(types.number),
      })
    ),
    tagOnVote: types.maybeNull(Tag),
    similarArtworksByTag: types.maybe(types.map(types.model({ artworks: PageableSimilarArtworksByTag }))),
  })
  .actions(self => ({
    mount: flow(function* mount(artworkId) {
      if (!self.artworkBoards.get(artworkId)) {
        getRoot(self)
          .boards.loadRandomArtworkBoards(RANDOM_ARTWORK_BOARDS_AMOUNT, artworkId)
          .then(({ items, total }) => {
            self.mapArtworkBoards(artworkId, items, total);
          });
      }

      const artwork = yield getRoot(self).artworks.loadArtwork(artworkId);
      const thingsToLoad = [
        artwork.loadCollection(),
        artwork.comments.init(),
        artwork.loadArtist().then(() => artwork.artist.loadIsFollowed()),
        artwork.loadStats(),
        // artwork.loadSuggestedTags(),
      ];

      if (getRoot(self).user.isLoaded) {
        artwork.loadUserBoards();
      }

      yield Promise.all(thingsToLoad);

      if (getRoot(self).user.isLoggedIn) {
        artwork.suggestTagVote(self.setTagVote);
      } else {
        const hiddenTags = [...artwork.tags].splice(6, artwork.tags.length);
        const randomHiddenTag = hiddenTags[Math.floor(Math.random() * hiddenTags.length)];
        if (randomHiddenTag) self.tagOnVote = { ...randomHiddenTag };
      }

      self.artwork = artwork;
    }),
    unmount() {
      self.artwork = undefined;
      self.tagOnVote = null;
      self.totalArtworkBoards = null;
      self.randomArtworkBoards = null;

      if (self.similarArtworks) {
        detach(self.similarArtworks);
        self.similarArtworks = undefined;
      }

      if (self.similarArtworksByTag) {
        detach(self.similarArtworksByTag);
        self.similarArtworksByTag = undefined;
      }
    },
    setTagVote(tag) {
      self.tagOnVote = tag;
    },
    loadSimilar() {
      ae.artworks.findSimilar(self.artwork.id);
      if (self.similarArtworks) return;

      self.similarArtworks = PageableSimilarArtworks.create();
      self.similarArtworks.init();
    },
    setTotalArtworkBoards(newVal) {
      self.totalArtworkBoards = newVal;
    },
    setRandomArtworkBoards(newVal) {
      self.randomArtworkBoards = newVal;
    },
    mapArtworkBoards(artworkId, artworkBoards, total) {
      self.artworkBoards.set(artworkId, { items: artworkBoards, total });
    },
    loadArtworksByTag(artworkId, tagName) {
      const tagInstance = self.similarArtworksByTag?.get(tagName);
      if (tagInstance) {
        return tagInstance;
      }

      if (!self.similarArtworksByTag) self.similarArtworksByTag = {};
      const pagingResults = PageableSimilarArtworksByTag.create();
      pagingResults.init({
        initFn: () => getRoot(self).artworks.loadSimilarArtworksByTag(artworkId, tagName, { page: 0, perPage: 12 }),
      });
      self.similarArtworksByTag.set(tagName, { artworks: pagingResults });
      return { artworks: pagingResults };
    },
  }))
  .views(self => ({
    get currentArtworkBoards() {
      if (self.artwork?.id && self.artworkBoards.get(self.artwork.id)) {
        return self.artworkBoards.get(self.artwork.id);
      }

      return null;
    },
  }));

export function create() {
  return ArtworkPage.create();
}
