import PropTypes from "prop-types";
import cn from "classnames";

export default function Divider({ className, width, height, color }) {
  return <hr className={cn("border-none", width, height, color, className)} />;
}

Divider.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

Divider.defaultProps = {
  className: null,
  width: "w-full",
  height: "h-2",
  color: "bg-gray-500",
};
