import { Stomp } from "stompjs/lib/stomp";
import { HOST_NAME } from "api";

let stompConn = null;

export async function getStompConnection() {
  if (stompConn) return stompConn;

  const ws = new WebSocket(`wss://${HOST_NAME}/api/v1/socket`);
  const stompClient = Stomp.over(ws);
  if (process.env.NODE_ENV === "production") {
    stompClient.debug = null;
  }

  stompConn = new Promise((resolve, reject) => {
    stompClient.connect({}, () => resolve(stompClient), reject);
  });

  return stompConn;
}
