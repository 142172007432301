import { types, flow, getRoot } from "mobx-state-tree";
import { Board } from "store/BoardsStore";
import { createPageableList } from "../utils";

const RecentBoards = createPageableList(Board, {
  loadMore(self, pagingOptions) {
    return getRoot(self).boards.loadRecentBoards(pagingOptions);
  },
  perPage: 9,
});

const PopularBoards = createPageableList(Board, {
  loadMore(self, pagingOptions) {
    return getRoot(self).boards.loadPopularBoards(pagingOptions);
  },
  perPage: 9,
});

const ArtworkBoards = createPageableList(Board, {
  loadMore(self, pagingOptions) {
    return getRoot(self).boards.loadArtworkBoards(getRoot(self).pages.boardIndexPage.artworkId, pagingOptions);
  },
  perPage: 9,
});

export const BoardIndexPage = types
  .model("BoardIndexPage", {
    recentBoards: RecentBoards,
    popularBoards: PopularBoards,
    artworkId: types.maybe(types.number),
    artworkTitle: types.maybe(types.string),
    artworkBoards: types.map(ArtworkBoards),
    isLoaded: false,
  })
  .actions(self => ({
    mount: flow(function* mount(artworkId = null) {
      if (artworkId) {
        self.artworkId = +artworkId;
        const artwork = yield getRoot(self).artworks.loadArtwork(artworkId);
        self.artworkTitle = artwork.title;

        if (!self.currentArtworkBoards) {
          const board = ArtworkBoards.create({ id: String(artworkId) });
          self.mapArtworkBoards(artworkId, board);
          yield board.init();
        }
      } else if (
        !artworkId &&
        !self.recentBoards.isLoaded &&
        self.recentBoards.list.length <= self.recentBoards.totalItems
      ) {
        self.recentBoards = RecentBoards.create();
        self.popularBoards = PopularBoards.create();
        yield Promise.all([self.recentBoards.init(), self.popularBoards.init()]);
      }

      self.isLoaded = true;
    }),
    unmount() {
      self.artworkId = undefined;
      self.artworkTitle = undefined;
      self.isLoaded = false;
    },
    mapArtworkBoards(artworkId, artworkBoards) {
      self.artworkBoards.set(artworkId, artworkBoards);
    },
  }))
  .views(self => ({
    get currentArtworkBoards() {
      if (self.artworkId && self.artworkBoards.get(self.artworkId)) {
        return self.artworkBoards.get(self.artworkId);
      }

      return null;
    },
  }));

export function create() {
  return BoardIndexPage.create({
    recentBoards: {},
    popularBoards: {},
  });
}
