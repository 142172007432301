import { client } from "./index";

export function addArtworkLike(artworkId) {
  const params = { itemType: "artwork", itemId: artworkId };

  return client.put("/user/like", params);
}

export function removeArtworkLike(artworkId) {
  const params = { itemType: "artwork", itemId: artworkId };

  return client.put("/user/removelike", params);
}

export function addBoardLike(boardId) {
  return client.post(`/kultura/board/${boardId}/like`);
}

export function removeBoardLike(boardId) {
  return client.post(`/kultura/board/${boardId}/removelike`);
}

export function addArtworkCollectionLike(collectionId) {
  const params = { itemType: "collection", itemId: collectionId };

  return client.put("/user/like", params);
}

export function removeArtworkCollectionLike(collectionId) {
  const params = { itemType: "collection", itemId: collectionId };

  return client.put("/user/removelike", params);
}

export function addProfileLike(profileId) {
  return client.post(`/profile/${profileId}/like`);
}

export function removeProfileLike(profileId) {
  return client.post(`/profile/${profileId}/removelike`);
}

export function dislikeArtwork(artworkId) {
  const params = { itemType: "artwork", itemId: artworkId };

  return client.put("/user/dislike", params);
}

export function dislikeProfile(profileId) {
  return client.post(`/profile/${profileId}/dislike`);
}

export function addCommentLike(commentId) {
  const params = { itemType: "comment", itemId: commentId };

  return client.put("/user/like", params);
}

export function removeCommentLike(commentId) {
  const params = { itemType: "comment", itemId: commentId };

  return client.put("/user/removelike", params);
}

export function addBlogpostLike(postId) {
  const params = { itemType: "post", itemId: postId };

  return client.put("/user/like", params);
}

export function removeBlogpostLike(postId) {
  const params = { itemType: "post", itemId: postId };

  return client.put("/user/removelike", params);
}

export function dislikeBlogpost(postId) {
  const params = { itemType: "post", itemId: postId };

  return client.put("/user/dislike", params);
}
