import SEOTags from "components/SEOTags";
import PropTypes from "prop-types";
import "assets/styles/index.css";
import dynamic from "next/dynamic";
import { AppLayout } from "components/layout";
import { StoreContext, createRootStore } from "store";
import { isProd } from "utils";
import { useRouter as useNextRouter } from "next/router";
import { useEffect } from "react";
import { setRouter } from "hooks/router";
import ae from "lib/analytics";

// Note(ars): This wrapper ignores second parameter of router event callback { shallow: boolean }
// It is needed because we cannot directly use ae.navigation (second parameter is used there for `from` url)
function routeChangeAnalytics(url) {
  ae.navigation(url);
}

if (typeof window !== "undefined" && isProd()) {
  import("react-gtm-module").then(TagManager => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    });
  });
}

// https://github.com/behnammodi/polyfill/blob/master/window.polyfill.js#L7-L24
if (typeof window !== "undefined" && !window.requestIdleCallback) {
  window.requestIdleCallback = (callback, options = {}) => {
    //eslint-disable-next-line no-var, no-redeclare
    const relaxation = 1;
    const timeout = options.timeout || relaxation;
    const start = performance.now();
    return setTimeout(() => {
      callback({
        get didTimeout() {
          return options.timeout ? false : performance.now() - start - relaxation > timeout;
        },
        timeRemaining() {
          return Math.max(0, relaxation + (performance.now() - start));
        },
      });
    }, relaxation);
  };
}

export const rootStore = createRootStore();

function App({ Component, pageProps }) {
  const r = useNextRouter(null);

  const params = new URLSearchParams(r.query);
  if (params.get("editing") && params.get("editing") !== "0") {
    rootStore.blogposts.setCurrentEditing(+params.get("editing"));
  }

  useEffect(() => {
    ae.navigation(window.location.pathname, null);
  }, []);

  useEffect(() => {
    r.events.on("routeChangeStart", routeChangeAnalytics);
    setRouter(r);
    return () => r.events.off("routeChangeStart", routeChangeAnalytics);
  }, [r]);

  return (
    <StoreContext.Provider value={rootStore}>
      <SEOTags />
      {Component.noLayout ? (
        <Component {...pageProps} />
      ) : (
        <AppLayout>
          <Component {...pageProps} />
        </AppLayout>
      )}
    </StoreContext.Provider>
  );
}

App.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
  pageProps: PropTypes.shape().isRequired,
};

export default dynamic(() => Promise.resolve(App), { ssr: false });
