/* eslint-disable import/no-cycle */
import { types, flow, getRoot, getParent } from "mobx-state-tree";
import { nanoid } from "nanoid";
import {
  getBoards,
  getTopBoards,
  createBoard,
  deleteBoard,
  publishBoard,
  unpublishBoard,
  updateBoard,
  getBoardById,
  addArtworkToBoard,
  deleteArtworkFromBoard,
  getBoardComments,
  addBoardComment,
  getCommentReplies,
  commentReply,
  getArtworkRandomBoards,
  updateBoardNote,
  deleteComment,
} from "api";
import { getProfileBoards } from "api/profile";
import { values } from "mobx";
import ae from "lib/analytics";

import { ENTITY_TYPES, ERROR_CODES, ERROR_MESSAGES, MAX_ARTWORKS_PER_BOARD } from "const";
import { searchAll } from "api/search";
import { Artwork } from "./ArtworksStore";
import { Comment } from "./CommentsStore";
import { createLikesStats } from "./LikesStore";

import { createPageableList } from "./utils";

const PageableComments = createPageableList(
  types.late(() => Comment),
  {
    loadMore(self, pageOptions) {
      return getRoot(self).boards.loadCommentReplies(getParent(self).id, pageOptions);
    },
    perPage: 15,
    safeReference: false,
  }
);

const BoardComment = types
  .compose(
    "BoardComment",
    Comment,
    types.model({
      replies: types.optional(PageableComments, () => PageableComments.create()),
    })
  )
  .actions(self => ({
    addItem: flow(function* addItem(commentId, commentBody) {
      const comment = yield commentReply(commentId, commentBody);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.replies.list.push(comment);
      self.nestedCommentsCount += 1;
    }),
  }));

const PageableBoardComments = createPageableList(BoardComment, {
  async loadMore(self, pageOptions) {
    const { comments, total } = await getBoardComments(getParent(self).id, pageOptions);
    return { items: comments, total };
  },
  perPage: 10,
  safeReference: false,
});

const BoardStats = types
  .model("BoardStats", {
    artworks: 0,
    totalViews: 0,
    uniqueViews: 0,
    shares: 0,
    artworksAddedFromHere: 0,
    comments: 0,
    amountOfLikes: 0,
  })
  .views(self => ({
    get short() {
      const { totalViews, comments, artworks } = self;
      return { amountOfViews: totalViews, amountOfComments: comments, amountOfArtworks: artworks };
    },
  }))
  .actions(self => ({
    incrementLikes() {
      self.amountOfLikes += 1;
    },
    decrementLikes() {
      self.amountOfLikes -= 1;
    },
  }));

const BoardOwner = types.model("BoardOwner", {
  id: types.number,
  username: types.string,
  name: types.string,
  avatarFilename: types.maybe(types.string),
});

export const Board = types
  .model("Board", {
    isProcessingAction: false,
    id: types.union(types.identifier, types.identifierNumber),
    owner: types.maybeNull(BoardOwner),
    ownerNotes: types.map(types.string),
    name: types.string,
    description: types.optional(types.string, ""),
    comments: types.optional(PageableBoardComments, () => PageableBoardComments.create()),
    totalComments: types.maybeNull(types.number),
    previewArtwork: types.safeReference(types.late(() => Artwork)),
    artworksAmount: types.number,
    published: types.boolean,
    isOwnedByCurrentUser: types.optional(types.boolean, false),
    stats: types.maybeNull(BoardStats),
    likes: types.maybeNull(createLikesStats()),
    isTemp: false,
    publishedAt: types.maybeNull(types.string),
  })
  .views(self => ({
    hasArtwork(artworkId) {
      const { artworks } = getRoot(self);
      const boardArtworks = artworks.getBoardArtworks(self.id);
      return !!boardArtworks.list.find(artwork => artwork.id === artworkId);
    },
    get isEmpty() {
      return self.artworksAmount <= 0;
    },
    get isFilled() {
      return self.artworksAmount >= MAX_ARTWORKS_PER_BOARD;
    },
  }))
  .actions(self => ({
    addArtwork: flow(function* addArtwork(artwork, { source, sourceId } = {}) {
      if (self.isProcessingAction) return;

      self.isProcessingAction = true;

      if (getRoot(self).user.isLoggedIn) {
        yield addArtworkToBoard(self.id, artwork.id, source, sourceId);
      } else {
        getRoot(self).user.userData.addArtworkToBoard(self.id, artwork.id);
      }

      ae.artworks.addToBoard(artwork.id, self.id);

      const storedUserBoards = JSON.parse(localStorage.getItem("userboards")) || {};
      const storedArtworksAmount = storedUserBoards[self.id];
      if (storedArtworksAmount) {
        self.artworksAmount = storedArtworksAmount + 1;
      } else {
        self.artworksAmount += 1;
      }
      localStorage.setItem("userboards", JSON.stringify({ ...storedUserBoards, [self.id]: self.artworksAmount }));

      if (!self.previewArtwork) {
        self.previewArtwork = artwork;
      }

      getRoot(self).artworks.getBoardArtworks(self.id).addItem(artwork, { placement: "end" });

      self.isProcessingAction = false;

      artwork.addUserBoard(self);
      artwork.loadStats();
      artwork.loadArtist();
    }),
    deleteArtwork: flow(function* deleteArtwork(artwork) {
      if (self.isProcessingAction) return;

      self.isProcessingAction = true;

      if (getRoot(self).user.isLoggedIn) {
        yield deleteArtworkFromBoard(self.id, artwork.id);
      } else {
        getRoot(self).user.userData.deleteArtworkFromBoard(self.id, artwork.id);
      }

      const storedUserBoards = JSON.parse(localStorage.getItem("userboards")) || {};
      const storedArtworksAmount = storedUserBoards[self.id];
      if (storedArtworksAmount) {
        self.artworksAmount = storedArtworksAmount - 1;
      } else {
        self.artworksAmount -= 1;
      }
      localStorage.setItem("userboards", JSON.stringify({ ...storedUserBoards, [self.id]: self.artworksAmount }));

      const boardArtworks = getRoot(self).artworks.getBoardArtworks(self.id);

      artwork.deleteUserBoard(self);
      boardArtworks.removeItem(artwork);

      if (self.previewArtwork && self.previewArtwork.id === artwork.id) {
        if (boardArtworks.list.length) {
          self.previewArtwork = boardArtworks.list[0];
        } else {
          self.previewArtwork = undefined;
        }
      }

      self.isProcessingAction = false;
    }),
    addPreviewArtworkRef(ref) {
      self.previewArtwork = ref;
    },
    loadComments: flow(function* loadComments() {
      if (Number.isInteger(self.totalComments) && self.comments.length === self.totalComments) return;

      if (getRoot(self).user.isLoggedIn) {
        const { comments } = yield getBoardComments(self.id);
        self.comments = comments;
        self.totalComments = comments.length;
      } else {
        self.comments = [];
        self.totalComments = 0;
      }
    }),
    addComment: flow(function* addComment(commentData) {
      const comment = yield addBoardComment(self.id, commentData);
      comment.likes = { currentUserValue: null, amountOfLikes: 0 };
      self.comments.addItem(comment);
      self.totalComments += 1;

      ae.comment(self.id, ENTITY_TYPES.BOARD, comment.id);
    }),
    updateOwnerNote: flow(function* updateOwnerNote(artwork, note) {
      yield updateBoardNote(self.id, artwork.id, note);
      if (note) {
        if (self.ownerNotes.get(artwork.id)) {
          ae.boards.updateNote(self.id, artwork.id);
        } else {
          ae.boards.createNote(self.id, artwork.id);
        }
        self.ownerNotes.set(artwork.id, note);
      } else {
        self.ownerNotes.delete(artwork.id);
        ae.boards.deleteNote(self.id, artwork.id);
      }
    }),
    updateCover: flow(function* updateCover(artwork) {
      if (getRoot(self).user.isLoggedIn) {
        yield updateBoard(self.id, { previewArtworkId: artwork.id });
      } else {
        getRoot(self).user.userData.updateBoard(self.id, { previewArtworkId: artwork.id });
      }

      ae.boards.updateCover(self.id, self.previewArtwork.id, artwork.id);

      self.previewArtwork = artwork;
    }),
    setTotalComments(val) {
      self.totalComments = val;
    },
    initComments: flow(function* initComments() {
      yield self.comments.init();
      self.setTotalComments(self.comments.totalItems);
    }),
    loadStats: flow(function* LoadStats() {
      if (self.stats || !getRoot(self).user.isLoggedIn) return;

      const { stats, likes } = yield getBoardById(self.id);
      self.likes = likes;
      self.stats = stats;
    }),
    removeComment: flow(function* removeComment(commentId, parentCommentId = null) {
      yield deleteComment(commentId);
      self.totalComments -= 1;

      if (parentCommentId) {
        const parentComment = self.comments.list.find(commentItem => commentItem.id === parentCommentId);
        const comment = parentComment.replies.list.find(replyItem => replyItem.id === commentId);
        return parentComment.replies.removeItem(comment);
      }

      const comment = self.comments.list.find(commentItem => commentItem.id === commentId);
      return self.comments.removeItem(comment);
    }),
  }));

const PageableUserBoards = createPageableList(Board, {
  loadMore(self, pagingOptions) {
    return getRoot(self).boards.loadUserBoards(pagingOptions);
  },
  perPage: 8,
});

const TempPageableUserBoards = createPageableList(Board, {
  loadMore(self, pagingOptions) {
    return getRoot(self).boards.tempLoadUserBoards(pagingOptions);
  },
  perPage: 8,
});

export const BoardsStore = types
  .model("BoardsStore", {
    list: types.map(Board),
    userBoards: types.maybeNull(types.union(PageableUserBoards, TempPageableUserBoards)),
  })
  .actions(self => ({
    mapBoard({ previewArtwork, comments, ...other }) {
      const boardData = {
        previewArtwork: previewArtwork ? getRoot(self).artworks.mapArtwork(previewArtwork) : undefined,
        ...other,
      };

      if (comments) {
        if (Array.isArray(comments)) {
          boardData.comments = { list: comments };
        } else {
          boardData.comments = comments;
        }
      }

      let board;

      if (self.list.has(boardData.id)) {
        board = self.list.get(boardData.id);
        self.list.set(board.id, { ...board, ...boardData });
      } else {
        board = self.list.put(boardData);
      }

      return board;
    },
    mapBoards(boardsData) {
      return boardsData.map(self.mapBoard);
    },
    loadBoard: flow(function* loadBoard(boardId) {
      if (self.list.has(boardId)) return self.list.get(boardId);
      let boardData;

      if (Number.isInteger(Number(boardId))) {
        boardData = yield getBoardById(boardId);
        return self.mapBoard(boardData);
      }

      while (!self.userBoards?.isLoaded) {
        yield new Promise(resolve => {
          setTimeout(resolve, 100);
        });
      }

      const board = self.list.get(boardId);
      if (!board) {
        const error = new Error(ERROR_MESSAGES[ERROR_CODES.BOARD_NOT_FOUND]);
        error.code = ERROR_CODES.BOARD_NOT_FOUND;
        throw error;
      }

      return board;
    }),
    loadTopBoards: flow(function* loadTopBoards() {
      const { boards } = yield getTopBoards();
      return boards.map(self.mapBoard);
    }),
    initUserBoards() {
      if (self.userBoards) return;
      if (getRoot(self).user.isLoggedIn) {
        self.userBoards = PageableUserBoards.create();
      } else {
        self.userBoards = TempPageableUserBoards.create();
      }

      self.userBoards.init();
    },
    loadUserBoards: flow(function* loadUserBoards(pagingOptions) {
      const { boards: boardsData, total } = yield getBoards({ ...pagingOptions, forCurrentUser: true });
      const boards = self.mapBoards(boardsData);

      return { items: boards, total };
    }),
    tempLoadUserBoards: flow(function* tempLoadUserBoards({ page, perPage }) {
      const user = getRoot(self).user.userData;
      const boards = user.boards;
      if (!boards?.length) return { items: [], total: 0 };

      const offset = page * perPage;
      const boardsData = yield Promise.all(
        boards.slice(offset, offset + perPage).map(({ id, name, description, previewArtworkId, artworks }) =>
          (previewArtworkId ? getRoot(self).artworks.loadArtwork(previewArtworkId) : Promise.resolve()).then(
            artwork => ({
              id,
              name,
              description,
              owner: { id: user.id, username: user.username, name: user.name },
              likes: { currentUserValue: null },
              previewArtwork: artwork,
              artworksAmount: artworks.length,
              published: false,
              isTemp: true,
            })
          )
        )
      );

      return {
        items: self.mapBoards(boardsData),
        total: boards.length,
      };
    }),
    loadProfileBoards: flow(function* loadProfileBoards(profileId) {
      const boardsData = yield getProfileBoards(profileId, { add: "stats,likes" });
      return self.mapBoards(boardsData);
    }),
    resetUserBoards() {
      self.userBoards.list.forEach(board => {
        if (board.isTemp) {
          self.list.delete(board.id);
        }
      });

      self.userBoards = null;
    },
    newBoard: flow(function* newBoard(name, description) {
      let board;

      if (getRoot(self).user.isLoggedIn) {
        const boardData = yield createBoard(name, description);
        board = self.list.put({ ...boardData, likes: { currentUserValue: null } });
      } else {
        const user = getRoot(self).user.userData;
        board = self.mapBoard({
          id: nanoid(),
          owner: { id: user.id, username: user.username, name: user.name },
          name,
          description,
          artworksAmount: 0,
          published: false,
          likes: { currentUserValue: null },
          isTemp: true,
        });

        getRoot(self).user.userData.addBoard(board);
      }

      ae.boards.create(board);

      self.userBoards.addItem(board, { placement: "end" });
    }),
    updateBoard: flow(function* update(id, data) {
      if (getRoot(self).user.isLoggedIn) {
        yield updateBoard(id, data);
      } else {
        getRoot(self).user.userData.updateBoard(id, data);
      }
      const board = self.list.get(id);

      ae.boards.update(id, board, data);

      self.list.set(id, { ...board, ...data });
    }),
    publishBoard: flow(function* publish(id) {
      yield publishBoard(id);
      const board = self.list.get(id);
      const currentUserProfile = getRoot(self).profiles.currentUserProfile;
      if (board.owner.id === currentUserProfile.id) {
        currentUserProfile.addBoard(board);
      }

      board.published = true;

      ae.boards.publish(id);
    }),
    unpublishBoard: flow(function* unpublish(id) {
      yield unpublishBoard(id);
      const board = self.list.get(id);
      const currentUserProfile = getRoot(self).profiles.currentUserProfile;
      if (board.owner.id === currentUserProfile.id) {
        currentUserProfile.removeBoard(board);
      }
      board.published = false;

      ae.boards.unpublish(id);
    }),
    deleteBoard: flow(function* del(board) {
      if (getRoot(self).user.isLoggedIn) {
        yield deleteBoard(board.id);
      } else {
        getRoot(self).user.userData.deleteBoard(board.id);
      }

      self.userBoards.removeItem(board);
      self.list.delete(board.id);

      ae.boards.delete(board);
    }),
    search: flow(function* search(q, pageOptions) {
      const { searchResult, total } = yield searchAll(q, { ...pageOptions, types: ["board"] });
      const boards = self.mapBoards(searchResult.board.items);

      return { boards, total };
    }),
    loadCommentReplies: flow(function* loadCommentReplies(commentId, pageOpts) {
      const { comments, total } = yield getCommentReplies(commentId, pageOpts);
      return { items: comments, total };
    }),
    loadRandomArtworkBoards: flow(function* loadRandomArtworkBoards(amount, artworkId) {
      const { boards, total } = yield getArtworkRandomBoards(amount, artworkId);
      return { items: self.mapBoards(boards), total };
    }),
    loadRecentBoards: flow(function* loadRecentBoards(pagingOptions) {
      const { boards, total } = yield getBoards({
        sortField: "publishedAt",
        sortDirection: "desc",
        perPage: 12,
        ...pagingOptions,
      });
      return { items: self.mapBoards(boards), total };
    }),
    loadPopularBoards: flow(function* loadRecentBoards(pagingOptions) {
      const { boards, total } = yield getBoards({
        ...pagingOptions,
        sortField: "statistic.likes,id",
        sortDirection: "desc,desc",
        perPage: 12,
      });
      return { items: self.mapBoards(boards), total };
    }),
    loadArtworkBoards: flow(function* loadArtworkBoards(artworkId, pagingOptions) {
      const { boards, total } = yield getBoards({ ...pagingOptions, sortDirection: "desc", artworkId });
      return { items: self.mapBoards(boards), total };
    }),
    resetUserLikes() {
      values(self.list).forEach(board => {
        if (board?.likes?.currentUserValue) {
          board.likes.updateCurrentValue(null);
        }
      });
    },
  }));

export function create() {
  return BoardsStore.create();
}
