import { useRouter } from "next/router";
import { useEffect } from "react";
import { useStore } from "store";
import { URL } from "const";

export default function useAuthRedirectPopup(modalState) {
  const router = useRouter();
  const { ui, user } = useStore();

  useEffect(() => {
    async function moveToIndex() {
      await router.replace(`${URL.ROOT}`);

      if (!user.isLoggedIn) {
        ui.authModal.setState(modalState);
        ui.authModal.setOpen(true);
      }
    }

    if (user.isLoaded && router.isReady) {
      moveToIndex();
    }
  }, [router, ui, user, user.isLoaded, modalState]);

  return null;
}
