/* eslint-disable no-restricted-syntax */
import { types, flow, getRoot, getType, getParent, detach, onSnapshot, getSnapshot } from "mobx-state-tree";
import { keys } from "mobx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ACCESS_RIGHTS, TEMP_USER_DAISY_VIEWS_LIMIT, VOTE_TYPES } from "const";
import ae, { setUser } from "lib/analytics";

import {
  getUserInfo,
  logoutUser,
  authenticate,
  getUserUploadCodes,
  getUserGalleries,
  uploadArtwork,
  getUserLikes,
  createAccount,
  updateUserSettings,
} from "api";
import getRandomName from "utils/getRandomName";
import getRandomAvatar from "utils/getRandomAvatar";
import { removeArtworkLike } from "api/likes";
import { Artwork } from "./ArtworksStore";
import { createPageableList } from "./utils";

dayjs.extend(relativeTime);

const AccessRights = types.array(types.enumeration("AccessRights", Object.values(ACCESS_RIGHTS)));

const UserSettings = types.model("UserSettings", { hideSensitiveContent: true });

const TempBoard = types.model("TempBoard", {
  id: types.identifier,
  name: types.string,
  description: types.string,
  previewArtworkId: types.maybeNull(types.number),
  artworks: types.array(types.number),
});

const TempUser = types
  .model("TempUser", {
    id: types.identifierNumber,
    username: types.string,
    displayName: types.string,
    avatar: types.string,
    likes: types.array(types.number),
    dislikes: types.array(types.number),
    views: types.array(types.number),
    boards: types.array(TempBoard),
    settings: UserSettings,
  })
  .views(self => ({
    get totalLikes() {
      return self.likes?.length || 0;
    },
    get name() {
      return self.displayName || self.username;
    },
  }))
  .actions(self => ({
    addBoard(board) {
      self.boards.push(getSnapshot(board));
    },
    updateBoard(id, data) {
      const index = self.boards.findIndex(tempBoard => tempBoard.id === id);
      if (index > -1) {
        self.boards[index] = { ...self.boards[index], ...data };
      }
    },
    deleteBoard(boardId) {
      self.boards = self.boards.filter(board => board.id !== boardId);
    },
    addArtworkToBoard(boardId, artworkId) {
      const board = self.boards.find(tempBoard => tempBoard.id === boardId);
      if (board) {
        if (!board.artworks.length) {
          board.previewArtworkId = artworkId;
        }

        board.artworks.push(artworkId);
      }
    },
    deleteArtworkFromBoard(boardId, artworkId) {
      const board = self.boards.find(tempBoard => tempBoard.id === boardId);
      if (board) {
        board.artworks = board.artworks.filter(tempArtworkId => tempArtworkId !== artworkId);
        if (artworkId === board.previewArtworkId) {
          if (board.artworks.length) {
            board.previewArtworkId = board.artworks[0];
          } else {
            board.previewArtworkId = null;
          }
        }
      }
    },
    updateBoardArtworks(boardId, changes) {
      const board = self.boards.find(tempBoard => tempBoard.id === boardId);

      for (const change of Object.entries(changes)) {
        const artworkId = Number(change[0]);
        const newPosition = Number(change[1]);

        const prevPosition = board.artworks.indexOf(artworkId);
        if (prevPosition === -1) {
          throw new Error(`Board ${board.id} doesn't have an artwork ${artworkId}`);
        }

        const artworkAtNewPosition = board.artworks[newPosition];
        board.artworks[newPosition] = artworkId;
        board.artworks[prevPosition] = artworkAtNewPosition;
      }
    },
    getBoardsWithArtwork(artworkId) {
      return self.boards.filter(board => board.artworks.includes(artworkId)).map(board => board.id);
    },
    addLike(artworkId) {
      self.likes.push(artworkId);
      if (self.dislikes.includes(artworkId)) {
        self.removeDislike(artworkId);
      }
    },
    removeLike(artworkId) {
      self.likes = self.likes.filter(id => id !== artworkId);
    },
    addDislike(artworkId) {
      self.dislikes.push(artworkId);

      if (self.likes.includes(artworkId)) {
        self.removeLike(artworkId);
      }
    },
    removeDislike(artworkId) {
      self.dislikes = self.dislikes.filter(id => id !== artworkId);
    },
    addView(artworkId) {
      self.views.push(artworkId);
      if (self.views.length > TEMP_USER_DAISY_VIEWS_LIMIT) {
        self.views.shift();
      }
    },
    updateDisplayName(name) {
      self.displayName = name;
    },
    changeName() {
      const name = getRandomName();
      const avatarFilename = getRandomAvatar();
      self.avatar = avatarFilename;
      getRoot(self).profiles.currentUserProfile.update({ name, avatarFilename });
    },
  }));

const User = types
  .model("User", {
    username: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    authorities: AccessRights,
    confirmed: false,
    id: types.number,
    // All likes on the platform (artworks, boards, collections, artists, etc...)
    totalLikes: 0,
    groupId: types.maybeNull(types.number),
    blogId: types.maybeNull(types.number),
    settings: UserSettings,
  })
  .views(self => ({
    get name() {
      return self.displayName || self.username;
    },
  }))
  .actions(self => ({
    updateDisplayName(name) {
      self.displayName = name; // TODO: Replace this with a link to user profile
    },
    addLike() {
      self.totalLikes += 1;
    },
    removeLike() {
      if (self.totalLikes) {
        self.totalLikes -= 1;
      }
    },
    updateBlogId(id) {
      self.blogId = id;
    },
  }));

const UnusedCode = types.model("UnusedCode", {
  createdAt: "",
  patron: "",
  uuid: types.string,
});

const UsedCode = types.compose(
  UnusedCode,
  types.model({
    usedAt: types.string,
    usedBy: types.string,
    usedFor: types.maybeNull(types.number),
  })
);
const UploadCodes = types.model("UploadCodes", {
  unused: types.array(UnusedCode),
  used: types.array(UsedCode),
});

const GalleriesStats = types.model("GalleriesStats", {
  ownedGalleries: types.number,
  galleryName: types.string,
  playersAmount: types.number,
});

const PageableLikedArtworks = createPageableList(Artwork, {
  loadMore: async (self, pageOptions) => {
    const { artworks: items, total } = await getRoot(self).artworks.loadLikedArtworks(pageOptions);

    return { items, total };
  },
  perPage: 20,
});

const TempPageableLikedArtworks = createPageableList(Artwork, {
  loadMore: async (self, pageOptions) => {
    const { artworks: items, total } = await getRoot(self).artworks.loadTempLikedArtworks(pageOptions);
    return { items, total };
  },
  perPage: 20,
});

const TempPageableLikedArtworksFiltered = createPageableList(Artwork, {
  loadMore: async (self, pageOptions) => {
    const { artworks: items, total } = await getRoot(self).artworks.searchTempLikedArtworks(
      getParent(self).searchQuery,
      pageOptions
    );

    return { items, total };
  },
  perPage: 20,
});

const PageableLikedArtworksFiltered = createPageableList(Artwork, {
  loadMore: async (self, pageOptions) => {
    const { artworks: items, total } = await getRoot(self).artworks.searchLikedArtworks(
      getParent(self).searchQuery,
      pageOptions
    );

    return { items, total };
  },
  perPage: 20,
});

const LikedArtworks = types
  .model("LikedArtworks", {
    isProcessingAction: false,
    searchQuery: types.optional(types.string, ""),
    all: types.maybe(types.union(PageableLikedArtworks, TempPageableLikedArtworks)),
    filtered: types.maybe(types.union(PageableLikedArtworksFiltered, TempPageableLikedArtworksFiltered)),
  })
  .views(self => ({
    get artworks() {
      return self.searchQuery ? self.filtered : self.all;
    },
  }))
  .actions(self => ({
    setSearchQuery(query) {
      if (self.searchQuery === query) return;
      self.searchQuery = query;

      if (query) {
        detach(self.filtered);
        self.filtered = PageableLikedArtworksFiltered.create();
        self.filtered.init();
      }
    },
    reset() {
      self.searchQuery = "";
      self.all = PageableLikedArtworks.create();
      self.filtered = PageableLikedArtworksFiltered.create();
    },
    addArtwork(artwork) {
      const { all } = self;

      if (all.isLoaded) {
        all.addItem(artwork, { force: true });
      }
    },
    deleteArtwork: flow(function* deleteArtwork(artwork, localDelete = false) {
      self.isProcessingAction = true;
      const artworkId = artwork.id;

      if (getParent(self).isLoggedIn) {
        if (!localDelete) yield removeArtworkLike(artworkId);
      } else {
        getParent(self).userData.removeLike(artworkId);
      }

      self.all.removeItem(artwork);

      if (self.searchQuery) {
        self.filtered.removeItem(artwork);
      }

      self.isProcessingAction = false;
    }),
    hasArtwork({ id }) {
      return !!self.all.list.find(artwork => artwork.id === id);
    },
  }));

export const UserStore = types
  .model("UserStore", {
    likedArtworks: LikedArtworks,
    userData: types.maybeNull(types.union(User, TempUser)),
    uploadCodes: types.maybeNull(UploadCodes),
    galleriesStats: types.maybeNull(GalleriesStats),
    isLoaded: false,
  })
  .views(self => ({
    get isTempUser() {
      return getType(self.userData).name === "TempUser";
    },
    get isLoggedIn() {
      const { isLoaded, userData } = self;
      return isLoaded && !!userData && !self.isTempUser;
    },
    get isAdmin() {
      return self.isLoggedIn && self.hasAccessRight(ACCESS_RIGHTS.ADMIN);
    },
    get hasBoards() {
      return self.isLoggedIn && !!getRoot(self).boards.userBoards.length;
    },
    get hasUploadCodes() {
      if (!self.uploadCodes) return false;

      const { used, unused } = self.uploadCodes;

      return !!(used.length || unused.length);
    },
    get publishedBoardsAmount() {
      return getRoot(self).boards.userBoards.list.reduce(
        (publishedBoardsAmount, board) => (board.published ? publishedBoardsAmount + 1 : publishedBoardsAmount),
        0
      );
    },
    hasAccessRight(roles) {
      if (!self.userData || self.isTempUser) return false;

      const authorities = self.userData.authorities;
      if (Array.isArray(roles)) {
        return roles.some(role => authorities.includes(role));
      }

      return authorities.includes(roles);
    },
    get canCreateProfiles() {
      return self.hasAccessRight([ACCESS_RIGHTS.ADMIN, ACCESS_RIGHTS.MASTER, ACCESS_RIGHTS.DEVELOPER]);
    },
    get uploadedArtworks() {
      return getRoot(self).artworks.getArtistArtworks(self.userData.id);
    },
  }))
  .actions(self => ({
    loadGalleriesStats: flow(function* loadStats() {
      if (self.galleriesStats) return;

      const galleries = yield getUserGalleries(getRoot(self).user.userData.username);

      const { name, playersAmount } = galleries[0];

      self.galleriesStats = {
        ownedGalleries: galleries.length,
        galleryName: name,
        playersAmount,
      };
    }),
    load: flow(function* load() {
      if (self.isLoaded) return;

      const userData = yield getUserInfo();

      if (userData) {
        self.userData = userData;
        setUser(userData);

        self.isLoaded = true;
        getRoot(self).boards.initUserBoards();
        getRoot(self).profiles.loadCurrentUserProfile();
        getRoot(self).notifications.init(self.userData.name);
      } else {
        self.setupTempUser();
      }
    }),
    initNewUser(userData) {
      getRoot(self).reset();
      self.userData = userData;
      setUser(userData);
      setTimeout(() => {
        getRoot(self).boards.initUserBoards();
        getRoot(self).profiles.loadCurrentUserProfile();
        getRoot(self).pages.daisyPage.loadSuggestions();

        self.updateUserLikes();
      }, 0);

      getRoot(self).notifications.init(self.userData.name);
      self.isLoaded = true;
    },
    login: flow(function* login(username, password) {
      const userData = yield authenticate(username, password);
      self.initNewUser(userData);
    }),
    createUser: flow(function* createUser(email, username, password) {
      const { boards, likes, dislikes, views } = self.userData;
      const anonymousData = {
        boards: boards.map(({ name, description, previewArtworkId, artworks }) => ({
          name,
          description,
          previewArtworkId,
          artworks,
        })),
        likes,
        dislikes,
        views,
      };

      const userData = yield createAccount({ email, username, password, anonymousData });
      self.initNewUser(userData);
    }),
    updateUserLikes: flow(function* updateUserLikes() {
      const boards = getRoot(self).boards.list;
      const artworks = getRoot(self).artworks.list;
      const collections = getRoot(self).collections.list;

      const { board, artwork, collection } = yield getUserLikes({
        board: keys(boards),
        artwork: keys(artworks),
        collection: keys(collections),
      });

      self.updateLikes(board, "boards");
      self.updateLikes(artwork, "artworks");
      self.updateLikes(collection, "collections");
    }),
    updateLikes(likes, target) {
      if (!likes) return;

      Object.keys(likes).forEach(itemId => {
        if (getRoot(self)[target].list.get(itemId).likes) {
          getRoot(self)[target].list.get(itemId).likes.updateCurrentValue(likes[itemId]);
        }
      });
    },
    updateTempLikes() {
      const artworkIds = keys(getRoot(self).artworks.list);
      const artworkVotes = artworkIds.reduce((votes, id) => {
        const artworkId = Number(id);
        if (self.userData.likes.includes(artworkId)) {
          // eslint-disable-next-line no-param-reassign
          votes[artworkId] = VOTE_TYPES.LIKE;
        } else if (self.userData.dislikes.includes(artworkId)) {
          // eslint-disable-next-line no-param-reassign
          votes[artworkId] = VOTE_TYPES.DISLIKE;
        }

        return votes;
      }, {});

      self.updateLikes(artworkVotes, "artworks");
    },
    logout: flow(function* logout() {
      yield logoutUser();
      getRoot(self).reset();
      ae.user.logout();
      setUser(null);
      self.setupTempUser();
    }),
    reset() {
      self.userData = null;
      self.isLoaded = false;
      self.galleriesStats = null;
      self.uploadCodes = null;
      self.likedArtworks.reset();
      getRoot(self).artworks.resetUserLikes();
      getRoot(self).boards.resetUserLikes();
      getRoot(self).collections.resetUserLikes();
      getRoot(self).blogposts.resetUserCommentsLikes();
      getRoot(self).blogposts.resetBlogpostsComments();
      localStorage.removeItem("userboards");
      localStorage.removeItem("temp-user");
    },
    setupTempUser() {
      const { likes, dislikes, views, boards, name, avatar } = JSON.parse(
        window.localStorage.getItem("temp-user") || "{}"
      );

      const userName = name || getRandomName();
      const userAvatar = avatar || getRandomAvatar();
      const tempUser = TempUser.create({
        id: 999999999,
        username: "temp-user",
        displayName: userName,
        avatar: userAvatar,
        likes,
        dislikes,
        views,
        boards,
        settings: { hideSensitiveContent: true },
      });

      getRoot(self).notifications.init("temp-user");

      self.userData = tempUser;

      self.likedArtworks = LikedArtworks.create({
        all: TempPageableLikedArtworks.create(),
        filtered: TempPageableLikedArtworksFiltered.create(),
      });

      getRoot(self).profiles.setCurrentUserProfile({
        id: 999999999,
        name: userName,
        avatarFilename: userAvatar,
        artist: false,
      });

      getRoot(self).boards.initUserBoards();
      self.updateTempLikes();

      onSnapshot(tempUser, sn => {
        window.localStorage.setItem(
          "temp-user",
          JSON.stringify({
            name: sn.displayName,
            avatar: sn.avatar,
            likes: sn.likes,
            dislikes: sn.dislikes,
            views: sn.views,
            boards: sn.boards,
            version: 1,
          })
        );
      });

      onSnapshot(tempUser, sn => {
        window.localStorage.setItem(
          "temp-user",
          JSON.stringify({ likes: sn.likes, dislikes: sn.dislikes, views: sn.views, boards: sn.boards, version: 1 })
        );
      });

      self.isLoaded = true;
    },
    loadUploadCodes: flow(function* loadUploadCodes() {
      if (self.uploadCodes) return;

      const uploadCodes = yield getUserUploadCodes();
      self.uploadCodes = uploadCodes.reduce(
        (codes, uploadCode) => {
          if (uploadCode.isUsed) {
            codes.used.push(uploadCode);
          } else {
            codes.unused.push(uploadCode);
          }

          return codes;
        },
        { used: [], unused: [] }
      );
    }),
    submitArtwork: flow(function* submitArtwork(file, metaData, code) {
      const data = { ...metaData };
      if (code) data.code = code;
      const uploadedArtworkData = yield uploadArtwork(file, data);
      const uploadedArtwork = getRoot(self).artworks.mapArtwork({
        ...uploadedArtworkData,
        likes: {},
        stats: {},
      });

      if (code) {
        const codeIndex = self.uploadCodes.unused.findIndex(({ uuid }) => uuid === code);
        let usedCode;

        if (codeIndex !== -1) {
          const unusedCode = self.uploadCodes.unused[codeIndex];
          usedCode = UsedCode.create({
            ...unusedCode,
            usedAt: dayjs().toJSON(),
            usedBy: self.userData.name,
            usedFor: uploadedArtwork.id,
          });

          self.uploadCodes.unused.splice(codeIndex, 1);
        } else {
          usedCode = UsedCode.create({
            uuid: code,
            usedAt: dayjs().toJSON(),
            usedBy: self.userData.name,
            usedFor: uploadedArtwork.id,
          });
        }
        self.uploadCodes.used.push(usedCode);
        self.uploadedArtworks.addItem(uploadedArtwork, { placement: "end" });
        const profile = getRoot(self).profiles.currentUserProfile;

        if (!profile.artist) profile.setArtist(true);
      } else {
        const artistArtworks = getRoot(self).artworks.getArtistArtworks(metaData.artistId);
        artistArtworks.addItem(uploadedArtwork, { placement: "end" });
      }

      return uploadedArtwork;
    }),
    updateUserData(updates) {
      self.userData = { ...self.userData, ...updates };
    },
    changeSettings: flow(function* updateUserSetting(updates) {
      const updatedSettings = { ...self.userData.settings, ...updates };
      const resp = yield updateUserSettings(updatedSettings);
      self.userData.settings = resp.settings;
    }),
  }));

export function create() {
  return UserStore.create({
    likedArtworks: LikedArtworks.create({
      all: PageableLikedArtworks.create(),
      filtered: PageableLikedArtworksFiltered.create(),
    }),
  });
}
