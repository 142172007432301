import PropTypes from "prop-types";
import { forwardRef, useState } from "react";
import cn from "classnames";
import Link from "./Link";
import CirclesLoader from "../loaders/CirclesLoader";

const AsyncButton = forwardRef(function AsyncButton(
  { onClick, disabled, LoadPlaceholder, loadPlaceholderStyles, getStyles, className, children, ...props },
  ref
) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleClick() {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  }

  return (
    <button
      ref={ref}
      type="button"
      {...props}
      disabled={isLoading || disabled}
      className={cn(className, getStyles({ disabled: isLoading || disabled }))}
      onClick={handleClick}
    >
      {isLoading && LoadPlaceholder ? (
        <LoadPlaceholder className={cn("pointer-events-none", loadPlaceholderStyles)} />
      ) : (
        children
      )}
    </button>
  );
});

AsyncButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  LoadPlaceholder: PropTypes.elementType,
  loadPlaceholderStyles: PropTypes.string,
  className: PropTypes.string,
  getStyles: PropTypes.func,
  children: PropTypes.node,
};

AsyncButton.defaultProps = {
  disabled: false,
  LoadPlaceholder: CirclesLoader,
  loadPlaceholderStyles: "h-10",
  className: null,
  getStyles: () => null,
  children: null,
};

const Button = forwardRef(function Button({ async, href, className, defaultStyles, ...props }, ref) {
  if (href) {
    return <Link ref={ref} href={href} className={cn(defaultStyles, className)} {...props} />;
  }

  if (async) {
    return <AsyncButton ref={ref} className={cn(defaultStyles, className)} {...props} />;
  }

  return <button ref={ref} type="button" className={cn(defaultStyles, className)} {...props} />;
});

Button.propTypes = {
  async: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  className: PropTypes.string,
  defaultStyles: PropTypes.string,
};

Button.defaultProps = {
  async: false,
  href: null,
  className: null,
  defaultStyles: "inline-flex justify-center items-center",
};

export default Button;
