import { client } from "./index";

export function getArtworkTagSuggestions(artworkId, searchQuery) {
  return client.get(`/kultura/artwork/${artworkId}/tags`, { q: searchQuery });
}

export function callArtworkTagAction(artworkId, tag, action) {
  return client.put(`/kultura/artwork/${artworkId}/tags`, { tag, action });
}

export function suggestArtworkTagVote(artworkId) {
  return client.get(`/kultura/artwork/${artworkId}/tags/suggest`);
}

export function findSimilarArtworksByTag(artworkId, tagName, { page = 0, perPage = 5 } = {}) {
  const params = { pNumber: page, pSize: perPage, add: ["artist,stats,likes"] };

  return client.get(`/kultura/artwork/${artworkId}/tags/${tagName}`, params);
}
