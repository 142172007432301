import PropTypes from "prop-types";
import cn from "classnames";
import Divider from "components/basic/Divider";
import { pickBetween } from "utils";
import { useStore } from "store";
import Placeholder from "./Placeholder";

export default function PlaceholderImageCard({ className, height: h, delay, renderTitle }) {
  const isMobile = useStore("/ui/isMobile");
  const height = Array.isArray(h) ? pickBetween(h[0], h[1]) : h;

  return (
    <div className={cn("flex w-full flex-col", className)} style={{ height }}>
      <Placeholder className="w-full flex-grow" animate delay={delay} />
      {renderTitle({ delay, Placeholder })}
      <Divider color="bg-white-300" className="mb-8 mt-10" />
      <div className="flex space-x-10">
        <div className="flex flex-col">
          {isMobile ? (
            <Placeholder className="rounded-2" height={16} width={40} animate delay={delay + 400} />
          ) : (
            <>
              <Placeholder className="rounded-2" height={14} width={48} animate delay={delay + 400} />
              <Placeholder className="mt-6 rounded-2" height={10} width={30} animate delay={delay + 600} />
            </>
          )}
        </div>
        <div className="flex flex-col">
          {isMobile ? (
            <Placeholder className="rounded-2" height={16} width={44} animate delay={delay + 400} />
          ) : (
            <>
              <Placeholder className="rounded-2" height={14} width={70} animate delay={delay + 400} />
              <Placeholder className="mt-6 rounded-2" height={10} width={38} animate delay={delay + 600} />
            </>
          )}
        </div>
        <div className="flex flex-col">
          {isMobile ? (
            <Placeholder className="rounded-2" height={16} width={42} animate delay={delay + 400} />
          ) : (
            <>
              <Placeholder className="rounded-2" height={14} width={58} animate delay={delay + 400} />
              <Placeholder className="mt-6 rounded-2" height={10} width={38} animate delay={delay + 600} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

PlaceholderImageCard.propTypes = {
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  delay: PropTypes.number,
  renderTitle: PropTypes.func,
};

PlaceholderImageCard.defaultProps = {
  className: null,
  height: null,
  delay: 0,
  // eslint-disable-next-line react/prop-types
  renderTitle: ({ delay }) => <Placeholder className="mt-8 rounded-2" height={22} animate delay={delay + 200} />,
};
