import { client } from "./index";

export function getUserProfile() {
  return client.get("/kultura/user/current?add=stats");
}

export function updateUserProfile(profileId, payload) {
  return client.put(`/kultura/user/${profileId}`, payload);
}

export function getProfileById(id) {
  return client.get(`/kultura/user/${id}?add=stats,likes,relation`);
}

export async function getProfiles(ids, { page = 0, perPage = 10 } = {}) {
  const params = { id: ids, PNumber: page, PSize: perPage, add: ["stats,likes,relation"] };

  const { items } = await client.get("/kultura/user", params);

  return items;
}

export function getArtistProfiles() {
  return client.get("/profile/artists?add=likes,relation&PSize=12");
}

export function getProfileStats(id) {
  return client.get(`/profile/${id}?add=stats`);
}

export function getProfileArtworks(id, params) {
  return client.get(`/profile/${id}/artworks`, params);
}

export async function getProfileBoards(id, params) {
  const { boards } = await client.get(`/kultura/board/user/${id}`, params);
  return boards;
}

export async function getProfileFollowers(profileId, params) {
  const { items, total } = await client.get(`/kultura/user/${profileId}/followers`, params);
  return { followers: items, total };
}

export async function getProfileFollowings(profileId, params) {
  const { items, total } = await client.get(`/kultura/user/${profileId}/followings`, params);
  return { followings: items, total };
}

export function followProfile(followedId) {
  return client.post("/following", { followedId });
}

export function unfollowProfile(followedId) {
  return client.delete(`/following?followedId=${followedId}`);
}

export async function searchProfiles(query, params) {
  const { searchResult, total } = await client.get("/profile/search", { ...params, searchQuery: query });
  return { profiles: searchResult, total };
}

export async function checkIsFollowing(followedId) {
  const { followed } = await client.get(`/following/check?followedId=${followedId}`);

  return followed;
}

export function getProfileComments(profileId, { page = 0, perPage = 15 }) {
  return client.get(`/comments?profileId=${profileId}&PNumber=${page}&PSize=${perPage}&sortBy=addedAt&orderBy=desc`);
}

export function ignoreUser(ignoredId) {
  return client.post(`/ignoring`, { ignoredId });
}

export function unignoreUser(ignoredId) {
  return client.delete(`/ignoring?ignoredId=${ignoredId}`);
}

export function createProfile(payload) {
  return client.post("/kultura/user/artists", payload);
}

export function changeProfileCover(profileId, file) {
  const formData = new FormData();
  formData.append("file", file);

  return client.put(`/kultura/user/${profileId}/profile-cover`, formData);
}

export function deleteProfile(profileId) {
  return client.delete(`/kultura/user/${profileId}`);
}

export function changeAvatar(profileId, file) {
  const formData = new FormData();
  formData.append("file", file);

  return client.put(`/kultura/user/${profileId}/avatar`, formData);
}
