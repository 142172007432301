import { client } from "api";

// available types = "artist", "artwork", "board", "collection", "post"
export async function searchAll(query, { types = null, page = 0, perPage = 10 } = {}) {
  const params = {
    types,
    pNumber: page,
    pSize: perPage,
    q: query,
  };

  const { searchResult, total } = await client.get(`/kultura/search`, params);

  return { searchResult, total };
}
