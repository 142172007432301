import { types, flow, getRoot } from "mobx-state-tree";
import { Profile } from "store/ProfilesStore";

import { Blogpost } from "store/BlogpostsStore";

export const BlogpostPage = types
  .model("BlogpostPage", {
    blogpost: types.maybeNull(types.safeReference(Blogpost)),
    blogpostOwner: types.safeReference(Profile),
  })
  .actions(self => ({
    mount: flow(function* mount(blogpostId) {
      if (getRoot(self).blogposts.list.has(blogpostId) && getRoot(self).blogposts.list.get(blogpostId).content) {
        self.blogpost = getRoot(self).blogposts.list.get(blogpostId);
        self.blogpostOwner = getRoot(self).profiles.list.get(self.blogpost.owner.id);
        return;
      }

      self.blogpost = yield getRoot(self).blogposts.loadBlogpost(blogpostId);

      const [ownerProfile] = yield Promise.all([
        getRoot(self).profiles.loadProfile(self.blogpost.owner.id),
        self.blogpost.initComments(),
      ]);
      yield ownerProfile.loadIsFollowed();

      self.blogpostOwner = ownerProfile;
      // yield Promise.all([getRoot(self).blogs.loadblogpostsReferences(), getRoot(self).blogs.userBlogs.init()]);
    }),
    unmount() {
      self.blogpost = null;
    },
  }));

export function create() {
  return BlogpostPage.create();
}
