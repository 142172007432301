import PropTypes from "prop-types";
import cn from "classnames";
import { forwardRef } from "react";
import CirclesLoader from "components/loaders/CirclesLoader";
import { Icon, Button as BaseButton } from "./basic";
import Ripples from "./Ripples";

const Button = forwardRef(function Button(
  { icon, iconStyles, color, padding, height, transition, className, disabled, children, loading, ...props },
  ref
) {
  const styles = ["rounded-5", padding, height, transition];
  let ripplesColor;

  switch (color) {
    case "white":
      styles.push("text-purple-300 active:bg-new-blue-100/60", {
        "bg-white-100 hover:text-white-100 hover:bg-new-blue-100/60": !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
      ripplesColor = "green";
      break;
    case "red":
      styles.push("text-white-100 active:bg-pink-100", {
        "bg-red-700 hover:bg-pink-400": !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
      ripplesColor = "red";
      break;
    case "green":
      styles.push("text-white-100 active:bg-new-blue-100/60", {
        "bg-new-blue-100 hover:bg-new-blue-100/60": !disabled,
        "bg-green-700 active:bg-green-700 pointer-events-none": disabled,
      });
      ripplesColor = "green";
      break;
    case "darkgreen":
      styles.push("text-white-100 active:bg-new-blue-100/60", {
        "bg-new-blue-100 hover:bg-new-blue-100/60": !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
      ripplesColor = "green";
      break;
    case "blue":
      styles.push("text-white-100 active:bg-new-blue-100/60", {
        "bg-new-blue-100 hover:bg-new-blue-100/60": !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
      ripplesColor = "green";
      break;
    case "purple-blue":
      styles.push("text-white-100 active:bg-new-blue-100/60", {
        "bg-gradient-to-r from-new-purple-100 via-new-blue-100 to-new-purple-100 bg-[length:200%_200%] bg-pos-0 hover:bg-[center_top_200%]":
          !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
      ripplesColor = "green";
      break;
    case "blankwhite":
    default:
      ripplesColor = "white";
      styles.push("text-new-gray-800 active:bg-white-100", {
        "bg-white-100 hover:bg-white-700": !disabled,
        "bg-gray-200 pointer-events-none": disabled,
      });
  }

  return (
    <Ripples color={ripplesColor} className={className} disabled={disabled}>
      <BaseButton ref={ref} className={cn("h-full w-full", styles)} disabled={disabled} {...props}>
        {loading ? (
          <CirclesLoader className="pointer-events-none h-10" />
        ) : (
          <>
            {icon && <Icon icon={icon} className={cn("pointer-events-none", iconStyles)} />}
            {children}
          </>
        )}
      </BaseButton>
    </Ripples>
  );
});

Button.propTypes = {
  color: PropTypes.oneOf(["blankwhite", "green", "red", "white", "darkgreen", "blue", "purple-blue"]),
  padding: PropTypes.string,
  height: PropTypes.string,
  transition: PropTypes.string,
  icon: PropTypes.string,
  iconStyles: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: "blankwhite",
  padding: "px-15 py-10",
  height: "min-h-38",
  transition: "transition-all duration-150 ease-out",
  icon: null,
  iconStyles: "w-20 h-20 mr-20",
  className: null,
  disabled: false,
  loading: false,
  children: null,
  onClick: () => {},
};

export default Button;
