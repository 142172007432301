import { useCallback, useEffect } from "react";
import useRouterEvents from "./useRouterEvents";

export default function usePopupHandler({ isOpen, close, open, refs }) {
  useRouterEvents("routeChangeStart", close);

  const handleMouseDown = useCallback(
    function handleMouseDown(e) {
      const path = e.composedPath();

      if (refs.some(ref => path.includes(ref.current))) {
        open();
      } else {
        close();
      }
    },
    [refs, close, open]
  );

  useEffect(() => {
    if (!isOpen) return () => {};

    function handleKeyDown(e) {
      if (e.key === "Escape") {
        close();
      }
    }

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleMouseDown, close]);

  useEffect(() => {
    function handleMenuClose(e) {
      if (isOpen && e.target === refs[0].current) {
        close();
      }
    }

    document.addEventListener("mousedown", handleMenuClose);
    return () => {
      document.removeEventListener("mousedown", handleMenuClose);
    };
  }, [isOpen, close, refs]);
}
