import PropTypes from "prop-types";
import { Icon, Divider } from "components/basic";
import { pickBetween } from "utils";
import Placeholder from "./Placeholder";

export default function PlaceholderComments({ className, commentsAmount, delay }) {
  return (
    <div className={className}>
      <div className="flex items-center">
        <div className="mr-6 flex flex-shrink-0 items-center font-medium">
          <Icon icon="chat-bubble" className="mr-10 h-20 w-20" />
          <Placeholder height={16} width={pickBetween(100, 400)} className="rounded-2" animate delay={delay} />
        </div>
        <Divider />
      </div>
      <ul className="mt-14 space-y-20">
        {Array.from({ length: commentsAmount }).map((_item, index) => (
          <li key={index}>
            <Placeholder
              className="mb-14 rounded-2"
              height={14}
              width={pickBetween(80, 200)}
              animate
              delay={200 + index * 200 + delay}
            />
            <Placeholder
              className="rounded-2"
              height={20}
              width={pickBetween(120, 400)}
              animate
              delay={400 + index * 400 + delay}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

PlaceholderComments.propTypes = {
  commentsAmount: PropTypes.number,
  className: PropTypes.string,
  delay: PropTypes.number,
};

PlaceholderComments.defaultProps = {
  commentsAmount: 3,
  className: null,
  delay: 0,
};
