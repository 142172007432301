/* eslint-disable no-unused-expressions */
import { client } from "./index";

export function createBlog(name) {
  return client.post("/blog", { title: `${name}'s blog`, skipModeration: true });
}

export function createBlogpost(blogId, name, category, content) {
  return client.post(`/blog/${blogId}`, { name, category, content });
}

export function getUserBlogposts({ page = 0, perPage = 10 }) {
  const params = {
    PNumber: page,
    PSize: perPage,
    orderBy: "DESC",
  };
  return client.get("/post/current", params);
}

export function getBlogpostsReferences() {
  return client.get("/post/references");
}

export function getBlogpost(blogpostId) {
  return client.get(`/post/${blogpostId}?add=stats,likes`);
}

export function updateBlogpost(blogpostId, name, category, content, cover = null, previewText = null) {
  const references = { artwork: [], file: [], user: [], board: [] };
  let preview = null;

  if (cover?.url || cover?.crop || previewText) {
    preview = {
      ...(cover?.url && { url: cover.url }),
      ...(cover?.crop && { crop: { id: cover.crop.id, url: cover.crop.url, name: cover.crop.name } }),
      ...(previewText && { text: previewText }),
      ...(cover?.sensitiveContent && { sensitiveContent: cover.sensitiveContent }),
      ...(cover?.blurhash && { blurhash: cover.blurhash }),
    };
  }

  if (cover?.crop) {
    references.file.push(cover.crop.id);
  }

  if (content) {
    content.blocks.forEach(block => {
      const { type, data } = block;

      // We are checking for optional image data since not all custom components should have one
      // f.e. persona/quotation tools may not have according avatar/image
      if (data?.imageData || data?.metaData?.image || !!data?.media?.length) {
        switch (type) {
          case "media":
            data.media.forEach(mediaItem => references.file.push(mediaItem.id));
            break;

          case "persona":
          case "quotation":
            references.file.push(data.imageData.id);
            break;

          case "hyperlink":
            references.file.push(data.metaData.image.id);
            break;

          default:
          // no-op
        }
      }

      if (type === "board" || type === "artwork" || type === "profile") {
        if (type === "board" && data.type === "collection") return;

        const parsedUrl = data.url.split("/");
        const id = Number(parsedUrl[parsedUrl.length - 2]) || Number(parsedUrl[parsedUrl.length - 1]);

        if (type === "profile") {
          references.user.includes(id) ? null : references.user.push(id);
        } else {
          references[type].includes(id) ? null : references[type].push(id);
        }
      }
    });
  }

  return client.put(`/post/${blogpostId}`, { name, category, content: content ?? { blocks: [] }, references, preview });
}

export function deleteBlogpost(blogpostId) {
  return client.delete(`/post/${blogpostId}`);
}

export function publishBlogpost(blogpostId) {
  return client.put(`/post/${blogpostId}/publish`);
}

export function uploadFile(file) {
  const formData = new FormData();

  formData.append("file", file);

  return client.post("/post/file", formData);
}

export function parseLink(link) {
  return client.post("/post/parse_link", { link });
}

export function getBlogposts({
  page: PNumber = 0,
  perPage: PSize = 10,
  add = "stats,likes",
  sortField: sortBy = null,
  ownerId = null,
  sortDirection: orderBy = null,
  searchQuery = null,
  status = null,
  pinned = false,
} = {}) {
  return client.get("/post", {
    PSize,
    PNumber,
    add,
    sortBy,
    orderBy,
    ownerId,
    searchQuery,
    status,
    pinned,
  });
}

export function getPinnedBlogposts({
  page: PNumber = 0,
  perPage: PSize = 10,
  add = "stats,likes",
  sortField: sortBy = null,
  ownerId = null,
  sortDirection: orderBy = null,
  status = null,
  pinned = true,
}) {
  return client.get("/post", { PSize, PNumber, add, sortBy, orderBy, ownerId, status, pinned });
}
