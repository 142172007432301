import { types } from "mobx-state-tree";
import { RootPage, create as createRootPage } from "./RootPage";
import { ArtworkPage, create as createArtworkPage } from "./ArtworkPage";
import { ProfilePage, create as createProfilePage } from "./ProfilePage";
import { CollectionPage, create as createCollectionPage } from "./CollectionPage";
import { BoardPage, create as createBoardPage } from "./BoardPage";
import { DaisyPage, create as createDaisyPage } from "./DaisyPage";
import { SearchPage, create as createSearchPage } from "./SearchPage";
import { GetCodesPage, create as createGetCodesPage } from "./GetCodesPage";
import { UserProfilePage, create as createUserProfilePage } from "./UserProfilePage";
import { HelpPage, create as createHelpPage } from "./HelpPage";
import { BoardIndexPage, create as createBoardIndexPage } from "./BoardIndexPage";
import { ArtworkUploadPage, create as createArtworkUploadPage } from "./ArtworkUploadPage";
import { BlogPage, create as createBlogPage } from "./BlogPage";
import { BlogpostPage, create as createBlogpostPage } from "./BlogpostPage";
import { FeedPage, create as createFeedPage } from "./FeedPage";
import { ArtworkIndexPage, create as createArtworkIndexPage } from "./ArtworkIndexPage";

export const PagesStore = types
  .model("PagesStore", {
    isPageLoading: false,
    rootPage: RootPage,
    artworkPage: ArtworkPage,
    profilePage: ProfilePage,
    collectionPage: CollectionPage,
    boardPage: BoardPage,
    daisyPage: DaisyPage,
    searchPage: SearchPage,
    getCodesPage: GetCodesPage,
    userProfilePage: UserProfilePage,
    helpPage: HelpPage,
    boardIndexPage: BoardIndexPage,
    artworkUploadPage: ArtworkUploadPage,
    blogPage: BlogPage,
    blogpostPage: BlogpostPage,
    feedPage: FeedPage,
    artworkIndexPage: ArtworkIndexPage,
  })
  .actions(self => ({
    setIsPageLoading(isLoading) {
      self.isPageLoading = isLoading;
    },
  }));

export function create() {
  return PagesStore.create({
    rootPage: createRootPage(),
    artworkPage: createArtworkPage(),
    profilePage: createProfilePage(),
    collectionPage: createCollectionPage(),
    boardPage: createBoardPage(),
    daisyPage: createDaisyPage(),
    searchPage: createSearchPage(),
    getCodesPage: createGetCodesPage(),
    userProfilePage: createUserProfilePage(),
    helpPage: createHelpPage(),
    boardIndexPage: createBoardIndexPage(),
    artworkUploadPage: createArtworkUploadPage(),
    blogPage: createBlogPage(),
    blogpostPage: createBlogpostPage(),
    feedPage: createFeedPage(),
    artworkIndexPage: createArtworkIndexPage(),
  });
}
