import PropTypes from "prop-types";
import * as Yup from "yup";
import { Input, Button as BasicButton } from "components/basic";
import { useCallback } from "react";
import Form from "components/Modal/Form";
import Button from "components/Button";
import { useStore } from "store";
import { ERROR_CODES } from "const";
import ae from "lib/analytics";
import ThirdPartyLinks from "../ThirdPartyLinks";

const initialValues = { username: "", password: "" };
const validation = Yup.object({
  username: Yup.string().required(true),
  password: Yup.string().required(true),
});

export default function LoginForm({ gotoSignup, gotoRecovery, beforeSubmit, afterSubmit, submitSuccess }) {
  const { user, ui } = useStore();

  const handleSubmit = useCallback(
    async function handleSubmit(form, { setSubmitting, setStatus }) {
      try {
        beforeSubmit();

        const { username, password } = form;
        await user.login(username.trim().toLowerCase(), password);
        ae.user.login.success();

        submitSuccess();
      } catch (error) {
        // TODO(ars): handle more error cases
        const message = error.code === ERROR_CODES.UNAUTHORIZED ? "Invalid credentials" : "Unexpected error";
        ae.user.login.failure({ errorCode: error.code, message });

        setStatus({
          visible: true,
          message,
        });
      } finally {
        setSubmitting(false);
        afterSubmit();
      }
    },
    [beforeSubmit, afterSubmit, submitSuccess, user]
  );

  return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validation}
        className="md:bg-new-gray-300 md:bg-opacity-80"
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, canSubmit }) => (
          <>
            <Input
              name="username"
              placeholder="Username/Email"
              autoFocus={!ui.isMobile}
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <div className="mt-20 flex justify-between md:mt-0">
              <Button
                color="green"
                type="submit"
                padding="p-14 md:p-10"
                className="w-full max-w-105"
                disabled={!canSubmit}
                loading={isSubmitting}
              >
                <span className="pointer-events-none font-medium">Login</span>
              </Button>
              <Button
                color="green"
                onClick={gotoSignup}
                padding="p-14 md:p-10"
                className="w-full max-w-105"
                disabled={isSubmitting}
              >
                <span className="pointer-events-none font-medium">Sign up</span>
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mx-20 text-new-gray-800 md:mx-12 md:mb-12">
        <BasicButton className="my-18 underline" onClick={gotoRecovery}>
          Forgotten your password?
        </BasicButton>
        <ThirdPartyLinks action="Login" />
      </div>
    </>
  );
}

LoginForm.propTypes = {
  gotoSignup: PropTypes.func.isRequired,
  gotoRecovery: PropTypes.func.isRequired,
  beforeSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  submitSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  beforeSubmit: () => {},
  afterSubmit: () => {},
  submitSuccess: () => {},
};
