import { addEvent } from "@stikipixels/analytics-browser";

export default {
  artwork: {
    addTag(payload) {
      addEvent({ type: "tags.artwork.add", payload });
    },
    deleteTag(payload) {
      addEvent({ type: "tags.artwork.delete", payload });
    },
    upvote(payload) {
      addEvent({ type: "tags.artwork.upvote", payload });
    },
    downvote(payload) {
      addEvent({ type: "tags.artwork.downvote", payload });
    },
    removevote(payload) {
      addEvent({ type: "tags.artwork.remove-vote", payload });
    },
  },
};
