import PropTypes from "prop-types";
import cn from "classnames";
import { useEffect, useRef, forwardRef, useState } from "react";

const BasicSidebar = forwardRef(function BasicSidebar({ width, className, children, styles }, ref) {
  return (
    <div
      ref={ref}
      className={cn(
        "flex flex-shrink-0 flex-col xl:mb-0 xl:mr-40 xl:flex-col xl:items-start xl:justify-start",
        width,
        className
      )}
      style={styles}
    >
      {children}
    </div>
  );
});

BasicSidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  styles: PropTypes.shape({}),
};

BasicSidebar.defaultProps = {
  className: null,
  width: "w-full xl:max-w-496",
  styles: {},
};

const PADDING = 20;
const STICKY_TOP_POS = 78;

function StickySidebar(props) {
  const container = useRef();
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (!container.current) return () => {};

    let scrollY = 0;
    let scrollMode = "initial";

    function scrollHandler() {
      const main = document.querySelector("main");
      if (!(container.current && main)) return;

      const containerEl = container.current;
      const n = containerEl.scrollHeight;
      const r = window.innerHeight;
      if (n <= r) {
        setStyles({ top: `${STICKY_TOP_POS}px`, position: "sticky" });
        return;
      }

      const up = scrollY > window.scrollY;
      const down = scrollY < window.scrollY;

      const rect = containerEl.getBoundingClientRect();
      const b = containerEl.scrollHeight + rect.y;

      if (down) {
        if (b < r) {
          setStyles({ marginTop: 0, top: `${-(n - r + PADDING)}px`, position: "sticky" });
          scrollMode = "stick-bottom";
        } else if (scrollMode === "stick-top") {
          setStyles({ marginTop: `${containerEl.offsetTop - PADDING}px`, top: 0, position: "relative" });
          scrollMode = "margin";
        }
      } else if (up) {
        if (rect.y - STICKY_TOP_POS >= 0) {
          setStyles({ marginTop: 0, top: `${STICKY_TOP_POS}px`, position: "sticky" });
          scrollMode = "stick-top";
        } else if (scrollMode === "stick-bottom") {
          setStyles({ marginTop: `${containerEl.offsetTop - PADDING}px`, top: 0, position: "relative" });
          scrollMode = "margin";
        }
      }

      scrollY = window.scrollY;
    }

    function matchMediaHandler({ matches }) {
      if (matches) {
        document.addEventListener("scroll", scrollHandler);
        scrollHandler();
      } else {
        document.removeEventListener("scroll", scrollHandler);
        setStyles({ margin: 0, top: 0, position: "relative" });
      }
    }

    const match = window.matchMedia("(min-width: 1280px)");
    match.addEventListener("change", matchMediaHandler);
    matchMediaHandler(match);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, [container]);

  return <BasicSidebar ref={container} {...props} styles={styles} />;
}

function Sidebar({ sticky, ...props }) {
  return sticky ? <StickySidebar {...props} /> : <BasicSidebar {...props} />;
}

Sidebar.propTypes = {
  sticky: PropTypes.bool,
};

Sidebar.defaultProps = {
  sticky: false,
};

const Main = ({ children }) => children;

Main.propTypes = {
  children: PropTypes.node,
};

Main.defaultProps = {
  children: null,
};

export default function TwoColumnLayout({ className, children }) {
  const main = children.find(el => el.type === Main);
  const sidebar = children.find(el => el.type === Sidebar);

  return (
    <div className={cn("break-words flex flex-col items-start xl:flex-row", className)}>
      {sidebar}
      {main}
    </div>
  );
}

TwoColumnLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TwoColumnLayout.defaultProps = {
  className: null,
};

TwoColumnLayout.Sidebar = Sidebar;
TwoColumnLayout.Main = Main;
