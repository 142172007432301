import { types, flow, getRoot } from "mobx-state-tree";
import { ArtworkCollection } from "store/CollectionsStore";

export const NewCollections = types
  .model("NewCollections", {
    list: types.array(types.safeReference(ArtworkCollection)),
    activeCollectionIndex: types.number,
    isLoaded: false,
  })
  .actions(self => ({
    init: flow(function* init() {
      if (self.isLoaded) return;

      self.list = yield getRoot(self).collections.loadCollections();
      self.isLoaded = true;
    }),
    setActiveCollectionIndex(index) {
      self.activeCollectionIndex = index;
    },
  }));

export function create() {
  return NewCollections.create({ activeCollectionIndex: 0 });
}
