import Button from "components/Button";
import { useStore } from "store";
import { AUTH_MODAL_STATE, URL } from "const";
import { useRouter } from "next/router";

export default function InvalidConfirmationLink() {
  const { ui, user } = useStore();

  const router = useRouter();

  return (
    <div className="flex w-full flex-col space-y-10 bg-new-gray-300 bg-opacity-80 p-12">
      <p className="text-15 leading-18 text-new-gray-800">
        {user.isLoggedIn
          ? "Click the button below to resend confirmation link"
          : "Log in to resend email confirmation link"}
      </p>
      <Button
        color="green"
        className="w-full font-medium"
        onClick={
          user.isLoggedIn
            ? () => {
                router.push(URL.UPLOAD_ARTWORK);
                ui.authModal.setOpen(false);
              }
            : () => ui.authModal.setState(AUTH_MODAL_STATE.LOGIN)
        }
      >
        {user.isLoggedIn ? "Resend confirmation link" : "Log in"}
      </Button>
    </div>
  );
}
