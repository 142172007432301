import { getRoot, types, flow } from "mobx-state-tree";
import { deleteComment, getLiveComments } from "api";
import { getStompConnection } from "api/stomp";
import { createLikesStats } from "./LikesStore";
import { createPageableList } from "./utils";

const CommentStats = types.model("CommentStats", { amountOfLikes: 0 }).actions(self => ({
  incrementLikes() {
    self.amountOfLikes += 1;
  },
  decrementLikes() {
    self.amountOfLikes -= 1;
  },
  setAmountOfLikes(amt) {
    self.amountOfLikes = amt;
  },
}));

const AuthorModel = types.model({
  avatarFilename: types.maybeNull(types.string),
  id: types.number,
  name: types.string,
  username: types.string,
});

const ParentComment = types.model({
  author: AuthorModel,
});

export const Comment = types.model("Comment", {
  id: types.identifierNumber,
  author: AuthorModel,
  body: types.string,
  addedAt: types.string,
  nestedCommentsCount: types.number,
  boardId: types.maybeNull(types.number),
  artworkId: types.maybeNull(types.number),
  boardName: types.maybeNull(types.string),
  galleryName: types.maybeNull(types.string),
  artworkTitle: types.maybeNull(types.string),
  authorId: types.maybeNull(types.number),
  stats: types.optional(CommentStats, () => CommentStats.create()),
  likes: types.maybeNull(createLikesStats()),
  postName: types.maybeNull(types.string),
  postId: types.maybeNull(types.number),
  parentCommentId: types.maybeNull(types.number),
  parent: types.maybeNull(ParentComment),
  blogId: types.maybeNull(types.number),
});

const PageableLiveComments = createPageableList(Comment, {
  loadMore(self, pageOptions) {
    return getRoot(self).liveComments.loadLiveComments(pageOptions);
  },
  perPage: 20,
  safeReference: false,
});

export const LiveCommentsStore = types
  .model("LiveComments", {
    comments: PageableLiveComments,
  })
  .actions(self => ({
    addItem(item) {
      self.comments.list.unshift(item);
      self.comments.totalItems += 1;
    },
    loadLiveComments: flow(function* loadLiveComments(pageOpts) {
      const { comments, total } = yield getLiveComments(pageOpts);

      if (total > 0) {
        comments.forEach(comment => {
          const { source } = comment;

          if (source?.artwork) {
            getRoot(self).artworks.mapArtwork(source.artwork);
          }
          if (source?.board) {
            getRoot(self).boards.mapBoard(source.board);
          }
          if (source?.post) {
            getRoot(self).blogposts.mapBlogpost(source.post);
          }
        });
      }

      return { items: comments, total };
    }),
    mount: flow(function* mount() {
      self.comments = PageableLiveComments.create();
      yield self.comments.init();
    }),
    unmount: flow(function* unmount() {
      const stompConn = yield getStompConnection();
      stompConn.unsubscribe("/comment");
    }),
    removeComment: flow(function* removeComment(commentId) {
      yield deleteComment(commentId);
      const comment = self.comments.list.find(commentItem => commentItem.id === commentId);
      self.comments.removeItem(comment);
    }),
  }));

export function create() {
  const comments = PageableLiveComments.create();
  return LiveCommentsStore.create({ comments });
}
