import Button from "components/Button";
import { AUTH_MODAL_STATE } from "const";
import { useStore } from "store";

export default function RegisterToContinueMessage() {
  const { authModal } = useStore("/ui");

  return (
    <div className="flex w-full flex-col space-y-10 bg-new-gray-300 bg-opacity-80 p-12">
      <p className="text-14 leading-18 text-new-gray-800">
        Please register if you wish to access the full functionality of the site
      </p>
      <Button color="green" className="w-full font-medium" onClick={() => authModal.setState(AUTH_MODAL_STATE.LOGIN)}>
        Ok
      </Button>
    </div>
  );
}
