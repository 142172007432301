import throttle from "lodash/throttle";
import { addEvent } from "@stikipixels/analytics-browser";

export default {
  view(artworkId) {
    addEvent({ type: "artworks.view", payload: { artworkId } });
  },
  zoomIn: throttle(artworkId => {
    addEvent({ type: "artworks.zoom-in", payload: { artworkId } });
  }, 1000),
  findSimilar: throttle(artworkId => {
    addEvent({ type: "artworks.find-similar", payload: { artworkId } });
  }, 1000),
  addToBoard(artworkId, boardId) {
    addEvent({ type: "artworks.board-add", payload: { artworkId, boardId, path: window.location.pathname } });
  },
  removeFromBoard(artworkId, boardId) {
    addEvent({ type: "artworks.board-remove", payload: { artworkId, boardId, path: window.location.pathname } });
  },
};
