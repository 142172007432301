import { addEvent } from "@stikipixels/analytics-browser";

export default {
  newMsg(chatId, type, message) {
    // init || suggested || self-written ||
    addEvent({ type: "whisperer.new-message", payload: { chatId, type, message } });
  },
  contentClick(chatId, type, content) {
    // artwork-link || artist-link || artwork
    addEvent({ type: "whisperer.message-content-click", payload: { chatId, type, content } });
  },
  hintClick(chatId, type, content) {
    // DAISY || Artworks || Boards
    addEvent({ type: "whisperer.hint-click", payload: { chatId, type, content } });
  },
  close(chatId) {
    addEvent({ type: "whisperer.chat-close", payload: { chatId } });
  },
  error(chatId, error) {
    addEvent({ type: "whisperer.error", payload: { chatId, error } });
  },
};
