import Button from "components/Button";
import { useStore } from "store";
// import { useEffect } from "react";

export default function EmailConfirmationMessage() {
  const { ui } = useStore();

  return (
    <div className="flex w-full flex-col space-y-10 bg-new-gray-300 bg-opacity-80 p-12">
      <p className="text-14 leading-18 text-new-gray-800">
        Thank you for being a part of KULTURA/OWW. You&apos;ve been successfully unsubscribed from all future
        communications.
      </p>
      <Button
        color="green"
        className="w-full font-medium"
        onClick={() => {
          ui.authModal.setOpen(false);
        }}
      >
        Ok
      </Button>
    </div>
  );
}
