import { types, flow, getRoot } from "mobx-state-tree";
import { Artwork } from "store/ArtworksStore";
import { createPageableList } from "../utils";

const RecentArtworks = createPageableList(Artwork, {
  loadMore(self, pagingOptions) {
    return getRoot(self).artworks.loadRecentArtworks(pagingOptions);
  },
  perPage: 9,
});

const PopularArtworks = createPageableList(Artwork, {
  loadMore(self, pagingOptions) {
    return getRoot(self).artworks.loadPopularArtworks(pagingOptions);
  },
  perPage: 9,
});

export const ArtworkIndexPage = types
  .model({ recentArtworks: RecentArtworks, popularArtworks: PopularArtworks, isLoaded: false })
  .actions(self => ({
    mount: flow(function* mount() {
      if (self.recentArtworks.list.length === 0 && self.popularArtworks.list.length === 0) {
        yield Promise.all([self.recentArtworks.init(), self.popularArtworks.init()]);
      }

      self.isLoaded = true;
    }),
    unmount() {
      self.isLoaded = false;
    },
  }));

export function create() {
  return ArtworkIndexPage.create({
    recentArtworks: {},
    popularArtworks: {},
  });
}
