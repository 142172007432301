import PropTypes from "prop-types";
import cn from "classnames";
import { useState } from "react";
import { useField, useFormikContext } from "formik";

export default function Input({ id, name, as: El, disabled, className, classNames, ...props }) {
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const { submitForm } = useFormikContext();
  const [{ onChange, onBlur, ...field }, { error }] = useField(name);
  const hasError = touched && error;
  const classes = classNames || { container: className, input: "p-10" };

  return (
    <label htmlFor={id || name} className={cn("relative block", classes.container)}>
      <El
        id={id || name}
        name={name}
        className={cn(
          "relative w-full rounded-5 border text-new-gray-800 transition-bg duration-150 ease-out focus:z-40",
          {
            "placeholder-gray-200": !hasError,
            "border-pink-100 placeholder-pink-100": hasError,
            "border-gray-600": !focused && !hasError,
            "border-new-blue-100": !disabled && focused && !hasError,
            "bg-white-100": !disabled,
            "bg-new-gray-300": disabled,
          },
          classes.input
        )}
        disabled={disabled}
        {...field}
        {...props}
        onChange={e => {
          setTouched(true);
          onChange(e);
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={e => {
          setFocused(false);
          onBlur(e);
        }}
        onKeyDown={
          El === "textarea"
            ? e => {
                if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
                  submitForm();
                }
              }
            : () => {}
        }
      />
      <span
        className={cn(
          "pointer-events-none absolute bottom-0 left-10 right-1 top-0 my-auto h-24 select-none bg-white-100 py-6 text-12 leading-14 text-pink-100",
          typeof error === "string" ? "block" : "hidden",
          classes.error
        )}
      >
        {error}
      </span>
    </label>
  );
}

Input.propTypes = {
  as: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  classNames: PropTypes.shape(),
};

Input.defaultProps = {
  as: "input",
  id: null,
  disabled: false,
  className: null,
  classNames: null,
};
