import cn from "classnames";
import PropTypes from "prop-types";
import { Link, Icon } from "components/basic";
import Ripples from "components/Ripples";
import { thirdPartyAuth } from "api";

// const authFacebook = thirdPartyAuth("facebook");
const authGoogle = thirdPartyAuth("google");

export default function ThirdPartyLinks({ className, action }) {
  return (
    <div className={cn("flex flex-col space-y-10", className)}>
      {/* <Ripples>
        <Link
          className="flex items-center rounded-5 border border-purple-100 bg-purple-200 px-10 py-8 text-14 font-light text-white-300 transition-colors duration-150 ease-out hover:bg-purple-100"
          href={authFacebook}
        >
          <Icon icon="facebook" className="mr-10 h-26 w-26 text-white-100" />
          {action} with Facebook
        </Link>
      </Ripples> */}
      <Ripples>
        <Link
          className="flex items-center rounded-5 bg-new-blue-100 px-10 py-8 text-14 font-light text-white-300 transition-colors duration-150 ease-out hover:bg-new-blue-100/60"
          href={authGoogle}
        >
          <Icon icon="google" className="mr-10 h-26 w-26 text-white-100" />
          {action} with Google
        </Link>
      </Ripples>
    </div>
  );
}

ThirdPartyLinks.propTypes = {
  className: PropTypes.string,
  action: PropTypes.string.isRequired,
};

ThirdPartyLinks.defaultProps = {
  className: null,
};
