import { types } from "mobx-state-tree";

export const ArtworkUploadPage = types
  .model("ArtworkUploadPage", {
    uploadWithoutCode: false,
  })
  .actions(self => ({
    toggleUploadWithoutCode() {
      self.uploadWithoutCode = !self.uploadWithoutCode;
    },
  }));

export function create() {
  return ArtworkUploadPage.create();
}
