import { addEvent } from "@stikipixels/analytics-browser";

export default {
  open(hideSensitiveContent, type = null, id = null) {
    addEvent({
      type: "sensitive.open",
      payload: {
        hideSensitiveContent,
        ...(id && type ? { id, type, path: window.location.pathname } : null),
      },
    });
  },
  confirmChange(hideSensitiveContent) {
    addEvent({
      type: "sensitive.confirm-change",
      payload: { hideSensitiveContent },
    });
  },
};
