import { types, flow, getRoot } from "mobx-state-tree";
import { Artwork } from "store/ArtworksStore";
import { Board } from "store/BoardsStore";
import { TrendingArtists, create as createTrendingArtists } from "./TrendingArtists";
import { NewCollections, create as createNewCollections } from "./NewCollections";
import { RecentActivity, create as createRecentActivity } from "./RecentActivity";

export const RootPage = types
  .model("RootPage", {
    featuredArtworks: types.array(types.safeReference(Artwork)),
    recentArtworks: types.array(types.safeReference(Artwork)),
    trendingArtists: TrendingArtists,
    newCollections: NewCollections,
    recentActivity: RecentActivity,
    popularBoards: types.array(types.safeReference(Board)),
    recentBoards: types.array(types.safeReference(Board)),
  })
  .views(self => ({
    get isLoaded() {
      return (
        !!self.featuredArtworks.length &&
        !!self.recentArtworks.length &&
        !!self.popularBoards.length &&
        !!self.recentBoards.length &&
        !!self.newCollections.isLoaded &&
        self.trendingArtists.isLoaded &&
        self.recentActivity.isLoaded
      );
    },
  }))
  .actions(self => ({
    mount() {
      if (self.isLoaded) return;

      const { artworks, boards } = getRoot(self);

      artworks.loadFeaturedArtworks();
      artworks.loadRecentArtworks();
      boards.loadTopBoards();

      self.loadFeaturedArtworks();
      self.loadRecentArtworks();
      self.loadTopBoards();
      self.loadRecentBoards();

      self.trendingArtists.init();
      self.newCollections.init();
      self.recentActivity.init();
    },
    loadFeaturedArtworks: flow(function* loadFeaturedArtworks() {
      self.featuredArtworks = yield getRoot(self).artworks.loadFeaturedArtworks();
    }),
    loadRecentArtworks: flow(function* loadRecentArtworks() {
      self.recentArtworks = yield getRoot(self).artworks.loadRecentArtworks();
    }),
    loadTopBoards: flow(function* loadTopBoards() {
      self.popularBoards = yield getRoot(self).boards.loadTopBoards();
    }),
    loadRecentBoards: flow(function* loadRecentBoards() {
      const { items } = yield getRoot(self).boards.loadRecentBoards({ perPage: 6 });
      self.recentBoards = items;
    }),
  }));

export function create() {
  return RootPage.create({
    trendingArtists: createTrendingArtists(),
    newCollections: createNewCollections(),
    recentActivity: createRecentActivity(),
  });
}
