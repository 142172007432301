import { observer } from "mobx-react-lite";
import { useEffect, useState, useCallback } from "react";
import { getStompConnection } from "api/stomp";
import { useStore } from "store";
import { Link } from "components/basic";
import Artwork from "components/Artwork";
import PropTypes from "prop-types";
import { AVATAR_SIZE_PREFIX, getArtworkUrl, getBlogpostUrl, getBoardUrl, getProfileUrl, IMAGE_SIZES } from "const";
import { getRelativeTime } from "utils/date";
import BoardCover from "components/boards/BoardCover";
import Avatar from "./Avatar";
import LoadableList from "./LoadableList";
import { Placeholder } from "./placeholders";
import Button from "./Button";

function CommentItemCover({ type, id, name }) {
  const [cover, setCover] = useState(null);

  const { artworks, boards } = useStore();

  useEffect(() => {
    async function loadCover() {
      if (type === "artwork") {
        try {
          const artwork = await artworks.loadArtwork(id);
          const coverComponent = (
            <Artwork
              animation={false}
              sizes={IMAGE_SIZES.LiveComments}
              artwork={artwork}
              className="mb-10 h-60 w-60 rounded-6"
            />
          );
          setCover(coverComponent);
        } catch (err) {
          return null;
        }
      }

      if (type === "board") {
        try {
          const board = await boards.loadBoard(id);
          const coverComponent = (
            <BoardCover className="mb-10 h-60 w-60">
              <Artwork
                url={getBoardUrl(id, name)}
                animation={false}
                sizes={IMAGE_SIZES.LiveComments}
                artwork={board.previewArtwork}
                rel="canonical"
              />
            </BoardCover>
          );
          setCover(coverComponent);
        } catch (err) {
          return null;
        }
      }

      return null;
    }

    loadCover();
  }, [artworks, boards, type, id, name]);

  if (!cover) return <Placeholder className="mb-10 h-60 w-60 rounded-6" animate />;

  return cover;
}

CommentItemCover.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
};

CommentItemCover.defaultProps = {
  name: null,
};

function LiveCommentItem(props) {
  const {
    id,
    body,
    author,
    addedAt,
    postName,
    boardName,
    artworkTitle,
    postId,
    boardId,
    artworkId,
    parentCommentId,
    parent,
    blogId,
  } = props;
  const [commentReference, setCommentReference] = useState(null);

  const { pages, user, liveComments } = useStore();

  useEffect(() => {
    // for some reason we've got comments for user (probably coming from game) which are not represented in design nor have any referenceId
    if (!artworkId && !boardId && !postId && !parentCommentId) return null;

    const getReferenceParams = async () => {
      const params = {};

      if (postId) {
        params.type = "post";
        params.href = getBlogpostUrl(blogId, postId);
        params.beforeNav = () => pages.blogpostPage.mount(postId);
      }

      if (boardId) {
        params.type = "board";
        params.href = getBoardUrl(boardId, boardName);
        params.beforeNav = () => pages.boardPage.mount(boardId);
        params.cover = <CommentItemCover type={params.type} id={boardId} name={boardName} />;
      }

      if (artworkId) {
        params.type = "artwork";
        params.href = getArtworkUrl(artworkId, artworkTitle);
        params.beforeNav = () => pages.artworkPage.mount(artworkId);
        params.cover = <CommentItemCover type={params.type} id={artworkId} />;
      }

      if (parentCommentId) {
        params.type = "reply";
        params.parentUsername = parent.author.username;
        params.parentHref = getProfileUrl(parent.author.id, parent.author.username);
        params.parentBeforeNav = () => pages.profilePage.mount(parent.author.id);
      }

      params.name = postName || boardName || artworkTitle;

      setCommentReference(params);
    };

    getReferenceParams();
    return null;
  }, [artworkId, boardId, postId, boardName, artworkTitle, postName, parentCommentId, pages, parent, blogId]);

  const deleteComment = useCallback(
    async function deleteComment(commentId) {
      liveComments.removeComment(commentId);
    },
    [liveComments]
  );

  if (!commentReference) return null;

  return (
    <li className="flex animate-appear flex-col">
      <div className="flex items-center justify-between">
        <div className="flex items-center self-start">
          <Link
            href={getProfileUrl(author.id, author.name)}
            beforeNav={() => pages.profilePage.mount(author.id, author.name)}
            rel="canonical"
          >
            <Avatar profile={author} size={AVATAR_SIZE_PREFIX.EXTRA_SMALL} scale={90} />
          </Link>
          <Link
            href={getProfileUrl(author.id, author.name)}
            className="ml-15 max-w-120 truncate text-15 font-semibold text-black-300 hover:text-new-blue-100"
            beforeNav={() => pages.profilePage.mount(author.id, author.name)}
            rel="canonical"
          >
            {author.name}
          </Link>
        </div>
        <div className="mr-1 flex flex-shrink-0 flex-col space-y-5">
          <span className="text-13 text-gray-1200">{getRelativeTime(addedAt)}</span>
          {user.isAdmin && (
            <Button
              color="red"
              className="text-13 shadow-whiteButtons"
              padding="p-5"
              height="h-15"
              onClick={() => deleteComment(id, parentCommentId)}
            >
              Remove
            </Button>
          )}
        </div>
      </div>
      <p className="my-10 text-15 text-black-300">{body}</p>
      {commentReference.cover}
      <p className="text-13">
        {parentCommentId ? (
          <>
            <span className="text-new-gray-700">{commentReference.type} to</span>
            <Link
              href={commentReference.parentHref}
              beforeNav={() => commentReference.parentBeforeNav()}
              className="ml-3 font-semibold underline hover:text-new-blue-100"
              target="_blank"
              rel="canonical"
            >
              {commentReference.parentUsername}
            </Link>
            <span className="ml-3 text-new-gray-700">on</span>
            <Link
              href={commentReference.href}
              beforeNav={() => commentReference.beforeNav()}
              className="ml-3 font-semibold underline hover:text-new-blue-100"
              rel="canonical"
            >
              {commentReference.name}
            </Link>
          </>
        ) : (
          <>
            <span className="text-new-gray-700">to {commentReference.type}</span>
            <Link
              href={commentReference.href}
              beforeNav={() => commentReference.beforeNav()}
              className="ml-3 font-semibold underline hover:text-new-blue-100"
              rel="canonical"
            >
              {commentReference.name}
            </Link>
          </>
        )}
      </p>
    </li>
  );
}

LiveCommentItem.propTypes = {
  id: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  author: PropTypes.shape({ name: PropTypes.string, avatarFilename: PropTypes.string, id: PropTypes.number })
    .isRequired,
  postName: PropTypes.string,
  boardName: PropTypes.string,
  artworkTitle: PropTypes.string,
  addedAt: PropTypes.string.isRequired,
  postId: PropTypes.number,
  boardId: PropTypes.number,
  artworkId: PropTypes.number,
  parentCommentId: PropTypes.number,
  parent: PropTypes.shape({ author: PropTypes.shape({ id: PropTypes.number.isRequired, username: PropTypes.string }) }),
  blogId: PropTypes.number,
};

LiveCommentItem.defaultProps = {
  postName: null,
  boardName: null,
  artworkTitle: null,
  postId: null,
  boardId: null,
  artworkId: null,
  parentCommentId: null,
  parent: { author: { username: "" } },
  blogId: null,
};

function LiveComments() {
  const { liveComments } = useStore();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadLiveComments() {
      await liveComments.mount();
      const stompConn = await getStompConnection();
      stompConn.subscribe(`/comment`, message => {
        const notification = JSON.parse(message.body);
        liveComments.addItem(notification);
      });
      setIsLoading(false);

      return null;
    }

    loadLiveComments();

    return () => {
      liveComments.unmount();
      setIsLoading(true);
    };
  }, [liveComments]);

  return (
    <div className="sticky top-70 ml-auto hidden max-h-comments-desktop-full w-[32rem] overflow-hidden rounded-6 bg-white-100 p-20 xl:ml-20 xl:block">
      <h2 className="mb-15 text-25 font-semibold text-black-300">Recent comments</h2>
      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <div className="h-50 w-50 animate-spin rounded-[50%] border-4 border-t-white-100 duration-1000" />
        </div>
      ) : (
        <LoadableList
          as="ul"
          className="scroll-hidden h-full space-y-20 overflow-y-scroll overscroll-contain"
          isMoreAvailable={liveComments.comments.haveMorePages}
          loadMore={liveComments.comments.loadMoreItems}
        >
          {liveComments.comments.list.map(comment => (
            <LiveCommentItem key={comment.id} {...comment} />
          ))}
        </LoadableList>
      )}
    </div>
  );
}

export default observer(LiveComments);
