/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useStore } from "store";
import { useRouter } from "hooks/router";

let isLoading = false;

const AsyncLink = forwardRef(function AsyncLink({ href, beforeNav, ...props }, ref) {
  const { setIsPageLoading } = useStore("/pages");
  const router = useRouter();

  async function preloadHandler(e) {
    e.stopPropagation();
    e.preventDefault();
    if (isLoading) return;

    const timerId = setTimeout(() => {
      isLoading = true;
      setIsPageLoading(true);
    }, 10);
    await beforeNav();
    clearTimeout(timerId);
    await router.push(href);
    isLoading = false;
    setIsPageLoading(false);
  }

  return <a ref={ref} href={href} {...props} onClick={preloadHandler} />;
});

AsyncLink.propTypes = {
  href: PropTypes.string.isRequired,
  beforeNav: PropTypes.func.isRequired,
};

export default AsyncLink;
