import { types } from "mobx-state-tree";
import { AUTH_MODAL_STATE } from "const";
import ae from "lib/analytics";
// eslint-disable-next-line import/no-cycle
import { setTrigger } from "components/Modal/ControlledModal";

const AuthModal = types
  .model("AuthModal", {
    title: "",
    isOpen: false,
    state: 0,
    isClosable: true,
    triggerAction: types.maybeNull(
      types.model("TriggerAction", { type: types.string, id: types.number, action: types.string })
    ),
  })
  .actions(self => ({
    setTitle(title) {
      self.title = title;
    },
    setOpen(val) {
      self.isOpen = val;
      if (!val) return;

      switch (self.state) {
        case AUTH_MODAL_STATE.LOGIN:
          ae.user.login.open();
          break;
        case AUTH_MODAL_STATE.SIGNUP:
          ae.user.signup.open();
          break;
        default:
        // noop
      }
    },
    setState(state, triggerAction = null) {
      self.state = state;
      if (triggerAction) self.triggerAction = triggerAction;

      if (self.isOpen) {
        switch (self.state) {
          case AUTH_MODAL_STATE.LOGIN:
            ae.user.login.open({ path: window.location.pathname, ...self.triggerAction });
            self.triggerAction = null;
            break;
          case AUTH_MODAL_STATE.SIGNUP:
            ae.user.signup.open({ path: window.location.pathname, ...self.triggerAction });
            self.triggerAction = null;
            break;
          default:
          // noop
        }
      }
    },
    setIsClosable(val) {
      self.isClosable = val;
    },
    promptRegistration() {
      self.state = AUTH_MODAL_STATE.REGISTER_TO_CONTINUE;
      self.isOpen = true;
    },
    toggleLogin(e) {
      setTrigger(e.target);
      if (self.state === AUTH_MODAL_STATE.LOGIN) {
        self.isOpen = !self.isOpen;
        ae.user.login.open();
      } else {
        self.state = AUTH_MODAL_STATE.LOGIN;
        if (!self.isOpen) self.isOpen = true;
      }
    },
    toggleSignup(e) {
      setTrigger(e.target);
      if (self.state === AUTH_MODAL_STATE.SIGNUP) {
        self.isOpen = !self.isOpen;
      } else {
        self.state = AUTH_MODAL_STATE.SIGNUP;
        if (!self.isOpen) {
          self.isOpen = true;
          ae.user.signup.open();
        }
      }
    },
  }));

const SensitiveContentModal = types
  .model("SensitiveContentModal", {
    isOpen: false,
    isClosable: true,
  })
  .actions(self => ({
    setOpen(val) {
      self.isOpen = val;
    },
    setIsClosable(val) {
      self.isClosable = val;
    },
  }));

export const UIStore = types
  .model("UIStore", {
    overlayBg: "",
    authModal: AuthModal,
    isMobile: false,
    sensitiveContentModal: SensitiveContentModal,
  })
  .actions(self => ({
    setOverlayBg(overlayBg) {
      self.overlayBg = overlayBg;
    },
    setIsMobile(isMobile) {
      self.isMobile = isMobile;
    },
  }));

export function create() {
  const uiStore = UIStore.create({
    authModal: AuthModal.create(),
    sensitiveContentModal: SensitiveContentModal.create(),
  });

  function handleMedia({ matches }) {
    uiStore.setIsMobile(matches);
  }

  if (typeof window !== "undefined") {
    const media = window.matchMedia("(max-width: 768px)");
    handleMedia(media);

    media.addEventListener("change", handleMedia);
  }

  return uiStore;
}
