import { addEvent } from "@stikipixels/analytics-browser";
import throttle from "lodash/throttle";

export default {
  login: {
    open: throttle((from = null) => {
      addEvent({ type: "user.login.open", payload: { from } });
    }, 1000),
    success() {
      addEvent({ type: "user.login.success", payload: null });
    },
    failure(payload) {
      addEvent({ type: "user.login.failure", payload });
    },
  },
  logout() {
    addEvent({ type: "user.logout", payload: null });
  },
  signup: {
    open: throttle((from = null) => {
      addEvent({ type: "user.signup.open", payload: { from } });
    }, 1000),
    success() {
      addEvent({ type: "user.signup.success", payload: null });
    },
    failure(payload) {
      addEvent({ type: "user.signup.failure", payload });
    },
  },
};
