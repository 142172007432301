import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { Input, Button as BasicButton } from "components/basic";
import Form from "components/Modal/Form";
import * as Yup from "yup";
import { useStore } from "store";
import Button from "components/Button";
import { requestPasswordRecovery } from "api";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().required(true).email("Invalid email address"),
});

export default function RecoveryStartForm({ gotoLogin, beforeSubmit, afterSubmit }) {
  const [recoveryEmail, setRecoveryEmail] = useState(null);
  const isMobile = useStore("/ui/isMobile");

  const handleSubmit = useCallback(
    async function handleSubmit({ email }, { setSubmitting, setStatus }) {
      try {
        beforeSubmit();
        await requestPasswordRecovery(email);
        setRecoveryEmail(email);
      } catch (error) {
        setStatus({ visible: true, message: error.message });
      } finally {
        afterSubmit();
        setSubmitting(false);
      }
    },
    [beforeSubmit, afterSubmit]
  );

  return recoveryEmail ? (
    <div className="flex w-full flex-col space-y-10 rounded-5 bg-new-gray-300 bg-opacity-80 p-20 md:p-12">
      <p className="text-14 leading-18">
        We&apos;ve sent a mail to <span className="font-medium">{recoveryEmail}</span> with a link to recover your
        password.
      </p>
      <p className="mb-38 text-14 leading-18">Please check your mailbox.</p>
      <Button color="green" className="w-full font-medium" onClick={gotoLogin}>
        Sure, can do
      </Button>
    </div>
  ) : (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        className="md:bg-new-gray-300 md:bg-opacity-80"
        onSubmit={handleSubmit}
      >
        {({ canSubmit, isSubmitting }) => (
          <>
            <Input
              name="email"
              placeholder="Email"
              autoFocus={!isMobile}
              classNames={{ input: "p-14 md:p-10" }}
              disabled={isSubmitting}
            />
            <p className="text-12 leading-14 text-new-gray-800">
              You will shortly receive a password reset link by email, if you don&apos;t see it check your Spam folder
              too.
            </p>
            <Button
              type="submit"
              color="green"
              className="w-full font-medium"
              padding="h-[4.4rem] md:h-auto p-14 md:p-10"
              disabled={!canSubmit}
              loading={isSubmitting}
            >
              Send reset password email
            </Button>
          </>
        )}
      </Form>
      <BasicButton className="mx-20 mt-18 text-new-gray-800 underline md:mx-12 md:mb-14" onClick={gotoLogin}>
        Return to login
      </BasicButton>
    </>
  );
}

RecoveryStartForm.propTypes = {
  gotoLogin: PropTypes.func.isRequired,
  beforeSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
};

RecoveryStartForm.defaultProps = {
  beforeSubmit: () => {},
  afterSubmit: () => {},
};
