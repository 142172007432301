import { ACCESS_RIGHTS } from "const";

export function pickBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function pick(arr) {
  return arr[pickBetween(0, arr.length - 1)];
}

export function pluralize(num, str, onlyWord = false, suffix = "s") {
  const word = `${str}${num === 1 ? "" : suffix}`;
  if (onlyWord) return word;

  return `${num} ${word}`;
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function truncate(str, limit) {
  if (!str) return "";

  if (str.length <= limit) return str;
  return `${str.substring(0, limit)}...`;
}

export function round(num, decimalPlaces = 2) {
  return Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
}

export function updateSlug(correctPath) {
  const { pathname, hash } = window.location;
  if (correctPath !== pathname) {
    window.history.replaceState(window.history.state, null, `${correctPath}${hash}`);
  }
}

export function isProd() {
  return process.env.NODE_ENV === "production";
}

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function isUUID(str) {
  return UUID_REGEX.test(str);
}

export function isInViewport(el, rootEl = document.body) {
  const rect = el.getBoundingClientRect();
  const rootRect = rootEl.getBoundingClientRect();
  return (
    rect.bottom >= rootRect.top &&
    rect.top <= rootRect.bottom &&
    rect.left >= rootRect.left &&
    rect.right <= rootRect.right
  );
}

export function handleProcessEnd(toggleProcess, timeout = 500) {
  return () =>
    new Promise(res => {
      setTimeout(() => {
        toggleProcess(false);
        res();
      }, timeout);
    });
}

export function getRole(user, authorId) {
  if (user.isAdmin) {
    return ACCESS_RIGHTS.ADMIN;
  }

  if (user?.userData?.id === authorId) {
    return ACCESS_RIGHTS.UPLOADER;
  }

  return ACCESS_RIGHTS.USER;
}

export async function getYoutubeEmbedUrl(url) {
  const videoId = url.match(
    /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&?/#]+)/
  )[1];

  return new Promise((res, rej) => {
    const img = new Image();
    img.src = `http://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
    img.onload = () => {
      //HACK a mq thumbnail has width of 320.
      //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
      res(img.width === 120 ? "" : `https://www.youtube.com/embed/${videoId}`);
    };
    img.onerror = rej;
  });
}
