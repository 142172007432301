import { types, getRoot, getParent, onPatch } from "mobx-state-tree";
import { Blogpost } from "store/BlogpostsStore";
import { Artwork } from "../ArtworksStore";
import { Profile } from "../ProfilesStore";
import { ArtworkCollection } from "../CollectionsStore";
import { Board } from "../BoardsStore";
import { createPageableList } from "../utils";

const PageableArtworksList = createPageableList(Artwork, {
  loadMore: async self => {
    const { artworks, total } = await getRoot(self).artworks.search(getParent(self).query, {
      page: self.page,
      perPage: 8,
    });
    return { items: artworks, total };
  },
  perPage: 8,
});
const PageableArtistsList = createPageableList(Profile, {
  loadMore: async self => {
    const { profiles, total } = await getRoot(self).profiles.searchArtists(getParent(self).query, {
      page: self.page,
      perPage: 8,
    });
    return { items: profiles, total };
  },
  perPage: 8,
});

const PageableCollectionsList = createPageableList(ArtworkCollection, {
  loadMore: async (self, pageOptions) => {
    const { collections, total } = await getRoot(self).collections.search(getParent(self).query, {
      ...pageOptions,
    });
    return { items: collections, total };
  },
  perPage: 8,
});

const PageableBoardsList = createPageableList(Board, {
  loadMore: async (self, pageOptions) => {
    const { boards, total } = await getRoot(self).boards.search(getParent(self).query, {
      ...pageOptions,
    });
    return { items: boards, total };
  },
  perPage: 8,
});

const PageableBlogpostsList = createPageableList(Blogpost, {
  loadMore: async (self, pageOptions) => {
    const { posts, total } = await getRoot(self).blogposts.search(getParent(self).query, {
      ...pageOptions,
    });
    return { items: posts, total };
  },
  perPage: 8,
});

export const SearchPage = types
  .model("SearchPage", {
    query: "",
    activeTab: "",
    artworks: PageableArtworksList,
    artists: PageableArtistsList,
    collections: PageableCollectionsList,
    boards: PageableBoardsList,
    blogposts: PageableBlogpostsList,
  })
  .views(self => ({
    get isPageLoaded() {
      const { artworks, artists, collections, boards, blogposts } = self;
      return artworks.isLoaded && artists.isLoaded && collections.isLoaded && boards.isLoaded && blogposts.isLoaded;
    },
    get totalResults() {
      const { artworks, artists, collections, boards, blogposts } = self;
      return (
        artworks.totalItems + artists.totalItems + collections.totalItems + boards.totalItems + blogposts.totalItems
      );
    },
    preview(category) {
      return self[category].list.slice(0, 4);
    },
  }))
  .actions(self => ({
    setQuery(query) {
      self.query = query;
    },
    setTab(tab) {
      self.activeTab = tab;
    },
    mount() {
      self.artworks = PageableArtworksList.create();
      self.artists = PageableArtistsList.create();
      self.collections = PageableCollectionsList.create();
      self.boards = PageableBoardsList.create();
      self.blogposts = PageableBlogpostsList.create();

      self.artworks.init();
      self.artists.init();
      self.collections.init();
      self.boards.init();
      self.blogposts.init();
    },
  }));

export function create() {
  const searchPage = SearchPage.create({
    artworks: PageableArtworksList.create(),
    artists: PageableArtistsList.create(),
    collections: PageableCollectionsList.create(),
    boards: PageableBoardsList.create(),
    blogposts: PageableBlogpostsList.create(),
  });

  onPatch(searchPage, ({ path }) => {
    if (path !== "/query") return;
    searchPage.mount();
  });

  return searchPage;
}
