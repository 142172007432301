import { useRef, useCallback } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

const LEFT_MOUSE = 0;

function createRipples(e, color, container, outer) {
  if (e.button !== LEFT_MOUSE) return;

  const outerContainer = e.currentTarget;
  const circle = document.createElement("div");

  const largestSide = Math.max(outerContainer.clientWidth, outerContainer.clientHeight);
  const size = `${largestSide}px`;

  const rect = outerContainer.getBoundingClientRect();
  const { left, top } = rect;

  if (outer) {
    circle.style.left = "50%";
    circle.style.top = "50%";
    circle.style.marginTop = `-${largestSide / 2}px`;
    circle.style.marginLeft = `-${largestSide / 2}px`;
  } else {
    const x = e.clientX - left - largestSide / 2;
    const y = e.clientY - top - largestSide / 2;
    circle.style.left = `${x}px`;
    circle.style.top = `${y}px`;
  }

  circle.style.width = size;
  circle.style.height = size;
  const styles = ["rounded-full", "absolute", "opacity-0", "animate-ripples", "cursor-pointer"];

  switch (color) {
    case "purple": {
      styles.push("bg-purple-100");
      break;
    }
    case "gray": {
      styles.push("bg-gray-700");
      break;
    }
    case "orange": {
      styles.push("bg-orange-100");
      break;
    }
    case "red": {
      styles.push("bg-pink-500");
      break;
    }
    case "green": {
      styles.push("bg-green-200");
      break;
    }
    case "blue": {
      styles.push("bg-new-blue-100");
      break;
    }
    case "white":
    default:
      styles.push("bg-white-300");
  }

  circle.classList.add(...styles);

  container.current.appendChild(circle);
  setTimeout(() => container.current?.removeChild(circle), 600);
}

function Ripples({ children, color, className, outer, disabled }) {
  const ripplesContainer = useRef(null);

  const mouseDownHandler = useCallback(
    e => {
      createRipples(e, color, ripplesContainer, outer);
    },
    [outer, color]
  );

  return (
    <div
      className={cn("relative", !outer && "z-0 overflow-hidden rounded-5", className)}
      onMouseDown={disabled ? null : mouseDownHandler}
    >
      {children}
      <div className="pointer-events-none" ref={ripplesContainer} />
    </div>
  );
}

Ripples.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  outer: PropTypes.bool,
  disabled: PropTypes.bool,
};

Ripples.defaultProps = {
  color: "white",
  className: null,
  outer: false,
  disabled: false,
};

export default Ripples;
